(function() {

    'use strict';

    var AtlasApp = angular.module('AtlasApp', [  'ui.bootstrap','ui.router', 'satellizer', 'ngSanitize', 'ngAnimate', 'angular.vertilize', 'ngMap', 'ui.calendar', 'ngFileUpload', 'LocalStorageModule', 'ngImgCrop', 'bootstrapLightbox', 'ui.bootstrap.datetimepicker', 'angucomplete-alt', 'datatables', 'ngclipboard', 'angulartics', 'angulartics.google.analytics', 'jtt_vimeo' ]);


	/* ==================================================================================== */
	// -   Application Configuration   ----------------------------------------------------
	/* ==================================================================================== */

    function config( $stateProvider, $urlRouterProvider, $authProvider, $locationProvider, localStorageServiceProvider, LightboxProvider, $animateProvider ){

			// Used w/ $stateProvider.state.resolve to determine 
			// if user attempting to access private resources
			// has been authenticated.
			function _redirectIfNotAuthenticated($q, $state, $auth, $timeout) {

				var defer = $q.defer();

				if( $auth.isAuthenticated() )
				{
					defer.resolve();
			  	}
			  	else
			  	{
			    	$timeout(function(){$state.go( 'home' );});
					defer.reject();
			  	}

			  	return defer.promise;

			}

			function _redirectIfAuthenticated($q, $state, $auth, $timeout) {

				var defer = $q.defer();

				if( !$auth.isAuthenticated() )
				{
					defer.resolve();
			  	}
			  	else
			  	{
			    	$timeout(function(){$state.go( 'wishlist' );});
					defer.reject();
			  	}

			  	return defer.promise;

			}

			// Set a local storage variable prefix to ensure 
			// there are no naming collisions.
			localStorageServiceProvider.setPrefix( "AtlasApp_" )
			
				// set the default path & expiration of storage cookies.
				.setStorageCookie( 30,'/' )

				// set the cookie domain
				.setStorageCookieDomain( 'maxedoutmarine.com' );

            // Satellizer configuration that specifies which API
            // route the JWT should be retrieved from
            $authProvider.loginUrl = '/api/authenticate';

			// If invalid route is requested, redirect to home page.
            $urlRouterProvider.otherwise('/');

            $stateProvider


				/* --- HOME ------------------------------------------ */
	            .state('/', {
		            url: '/',
		            views: {
			            '': {
								templateUrl : '/partials/home',
								controller  : 'PageController'
			            	},
			            'showcase': {
								templateUrl : '/partials/carousel',
								controller  : 'CarouselController'
			            	}
		            }
	            })
	            .state('home', {
		            url: '/home',
		            views: {
			            '': {
								templateUrl : '/partials/home',
								controller  : 'PageController'
			            	},
			            'showcase': {
								templateUrl : '/partials/carousel',
								controller  : 'CarouselController'
			            	}
		            }
	            })



				/* --- COMPANY ----------------------------------------- */
	            .state('company', {
		            url: '/company',
		            views: {
			            '': {
	                			controller  : 'PageController',
								templateUrl: '/partials/company'
			            	},
			            'showcase': {
								template : '<div class="row-fluid company-title" style=""><div>&nbsp;</div></div>',
								controller  : 'PageController'
			            	}
		            }
	            })
	            .state('company.faq', {
		            url: '/faq',
	                controller  : 'PageController',
	                templateUrl: '/partials/company/faq'
	            })
	            .state('company.team', {
		            url: '/team',
	                controller  : 'PageController',
	                templateUrl: '/partials/company/team'
	            })
	            .state('company.events', {
		            url: '/events',
	                controller  : 'EventsController',
	                templateUrl: '/partials/company/events'
	            })



				/* --- INVENTORY ------------------------------------- */
	            .state('inventory', {
		            abstract: true,
		            url: '/inventory',
		            views: {
			            '': {
				                templateUrl: '/partials/inventory',
			            	},
			            'showcase': {
								template : '<vehicle-title-bar title="titleBar" vehicle="Vehicle"></vehicle-title-bar>',
			            	}
		            }

	            })
	            .state('inventory.type', {
		            url: '/{type:(?:performance|recreation|onroad|offroad)}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/inventory/type'
	            })
	            .state('inventory.detail', {
		            url: '/{type:(?:performance|recreation|onroad|offroad)}/{vehicle_id:[0-9]{1,6}}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/inventory/vehicle'
	            })
	            .state('inventory.search', {
		            url: '/search{:type,:make,:model,:search}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/inventory/search'
	            })
	            .state('inventory.listmyvehicle', {
		            url: '/listmyvehicle',
	                controller  : 'ListMyVehicleController',
	                templateUrl: '/partials/inventory/listmyvehicle'
	            })



				/* --- SOLD ------------------------------------------ 
	            .state('sold', {
		            abstract: true,
		            url: '/sold',
		            views: {
			            '': {
				                templateUrl: '/partials/sold',
			            	},
			            'showcase': {
								template : '<vehicle-title-bar title="titleBar" vehicle="Vehicle"></vehicle-title-bar>',
			            	}
		            }

	            })
	            .state('sold.type', {
		            url: '/{type:(?:performance|recreation|onroad|offroad)}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/sold/type'
	            })
	            .state('sold.detail', {
		            url: '/{type:(?:performance|recreation|onroad|offroad)}/{vehicle_id:[0-9]{1,6}}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/sold/vehicle'
	            })
	            .state('sold.search', {
		            url: '/search{:type,:make,:model,:search}',
	                controller  : 'InventoryController',
	                templateUrl: '/partials/sold/search'
	            })
	            .state('sold.listmyvehicle', {
		            url: '/listmyvehicle',
	                controller  : 'ListMyVehicleController',
	                templateUrl: '/partials/sold/listmyvehicle'
	            })
				*/


				/* --- MEDIA ----------------------------------------- */
	            .state('media', {
		            url: '/media',
		            views: {
			            '': {
								templateUrl: '/partials/media'
			            	},
			            'showcase': {
								template : '<div class="row-fluid media-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
	            })
	            .state('media.videos', {
		            url: '/videos',
	                controller  : 'MediaController',
	                templateUrl: '/partials/media/videos'
	            })



				/* --- CONTACT US ------------------------------------ */
	            .state('contact', {
					url: '/contact',
		            views: {
			            '': {
	                			controller  : 'PageController',
								templateUrl: '/partials/contact'
			            	},
			            'showcase': {
								template : '<div class="row-fluid contact-title" style=""><div>&nbsp;</div></div>'
			            	}
		            }
	            })



				/* --- SEARCH ------------------------------------------ */
	            .state('search', {
		            url: '/search',
		            views: {
			            '': {
								templateUrl : '/partials/search',
								controller  : 'SearchController'
			            	},
			            'showcase': {
								template : '<div style="margin-top:115px" class="row-fluid bg-lt-gray"><div class="col-lg-10 col-lg-offset-1 font-white font-right" style="margin:5px;"><span class="font-40px font-face-sonic font-dk-gray">Search</span></div><div class="clearfix"></div></div>'
			            	}
		            }
	            })



				/* --- PRIVACY --------------------------------------- */
	            .state('privacy', {
					url: '/privacy',
		            views: {
			            '': {
	                			controller  : 'PageController',
								templateUrl: '/partials/contact'
			            	},
			            'showcase': {
								template : '<div class="row-fluid contact-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
	            })



				/* --- AUTHENTICATION -------------------------------- */
                .state('auth', {
		            resolve: { redirectIfAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfAuthenticated ] },
                    url: '/auth',
		            views: {
			            '': {
								templateUrl: '/partials/login',
								controller: 'AuthController as auth'
			            	},
			            'showcase': {
								template : '<div class="row-fluid register-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
                    
                })
                .state('forgot', {
                    url: '/forgot',
		            views: {
			            '': {
								templateUrl: '/partials/forgot',
								controller: 'AuthController as auth'
			            	},
			            'showcase': {
								template : '<div class="row-fluid register-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
                })
                .state('reset', {
                    url: '/forgot/:token',
		            views: {
			            '': {
			                    templateUrl: '/partials/reset',
			                    controller: 'AuthController as auth'
			            	},
			            'showcase': {
								template : '<div class="row-fluid register-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
                })
	            .state('register', {
		            resolve: { redirectIfAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfAuthenticated ] },
		            url: '/register',
		            views: {
			            '': {
								templateUrl: '/partials/register',
								controller: 'AuthController as auth'
			            	},
			            'showcase': {
								template : '<div class="row-fluid register-title" style=""><div>&nbsp;</div></div>',
			            	}
		            }
	            })
	            .state('register.wishlist', {
		            url: '/wishlist/{vehicle_id:[0-9]{1,6}}',
	                controller  : 'AuthController as auth',
	                templateUrl: '/partials/register'
	            })
                .state('logout', {
                    url: '/logout',
					onEnter:  [ '$state', '$auth', function( $state, $auth ){ $auth.logout(); $state.go( "home" ); } ]
                })



				/* --- AUTHENTICATED :: DASHBOARD -------------------- */
                .state('dashboard', {
		            url: '/dashboard',
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            views: {
			            '': {
								templateUrl: '/partials/dashboard',
								controller: 'DashboardController',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
								controller  : 'PageController'
			            	}
		            }
                })

	            .state('listings', {
		            abstract: true,
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            url: '/listings',
		            views: {
			            '': {
				                templateUrl: '/partials/listings',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
			            	}
		            }

	            })
	            .state('listings.list_all', {
		            url: '/all',
	                controller  : 'ListingsController',
	                templateUrl: '/partials/listings/all'
	            })
	            .state('listings.create', {
		            url: '/create',
	                controller  : 'CreateListingsController',
	                templateUrl: '/partials/listings/create'
	            })
	            .state('listings.detail', {
		            url: '/{vehicle_id:[0-9]{1,6}}',
	                controller  : 'ModifyListingsController',
	                templateUrl: '/partials/listings/vehicle'
	            })

	            .state('events', {
		            abstract: true,
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            url: '/events',
		            views: {
			            '': {
				                templateUrl: '/partials/events',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
			            	}
		            }

	            })
	            .state('events.list_all', {
		            url: '/all',
	                controller  : 'EventsController',
	                templateUrl: '/partials/events/all'
	            })
	            .state('events.create', {
		            url: '/create',
	                controller  : 'EventsController',
	                templateUrl: '/partials/events/create'
	            })



	            .state('users', {
		            abstract: true,
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            url: '/users',
		            views: {
			            '': {
				                templateUrl: '/partials/users',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
			            	}
		            }

	            })
	            .state('users.list_all', {
		            url: '/all',
	                controller  : 'UsersController',
	                templateUrl: '/partials/users/all'
	            })
	            .state('users.create', {
		            url: '/create',
	                controller  : 'UsersController',
	                templateUrl: '/partials/users/create'
	            })



                .state('wishlists', {
		            url: '/wishlists',
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            views: {
			            '': {
								templateUrl: '/partials/wishlists',
								controller: 'WishlistsController',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
								controller  : 'PageController'
			            	}
		            }
                })
	            .state('wishlists.detail', {
		            url: '/{vehicle_id:[0-9]{1,6}}',
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            views: {
			            '': {
								templateUrl: '/partials/wishlists',
								controller: 'WishlistsController',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
								controller  : 'PageController'
			            	}
		            }
	            })

	            .state('wishlist', {
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            url: '/wishlist',
		            views: {
			            '': {
				                controller  : 'PageController',
				                templateUrl: '/partials/wishlist',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
								controller  : 'PageController'
			            	}
		            }

	            })
	            .state('wishlist.list_all', {
		            url: '/all',
	                controller  : 'ListingsController',
	                templateUrl: '/partials/wishlist/all'
	            })
	            .state('wishlist.create', {
		            url: '/create',
	                controller  : 'CreateListingsController',
	                templateUrl: '/partials/wishlist/create'
	            })
	            .state('wishlist.detail', {
		            url: '/{vehicle_id:[0-9]{1,6}}',
	                controller  : 'ModifyListingsController',
	                templateUrl: '/partials/wishlist/vehicle'
	            })

	            .state('newsletters', {
		            abstract: true,
		            resolve: { redirectIfNotAuthenticated: [ '$q', '$state', '$auth', '$timeout', _redirectIfNotAuthenticated ] },
		            url: '/newsletters',
		            views: {
			            '': {
				                templateUrl: '/partials/newsletters',
			            	},
			            'showcase': {
								template : '<dashboard-title-bar></dashboard-title-bar>',
			            	}
		            }

	            })
	            .state('newsletters.list_all', {
		            url: '/all',
	                controller  : 'NewsletterController',
	                templateUrl: '/partials/newsletters/all'
	            })
	            .state('newsletters.export', {
		            url: '/export',
	                controller  : 'NewsletterController',
	                templateUrl: '/partials/newsletters/export'
	            });


			// Turn on animations selectively
			// Only animate elements that include
			// the `ng-animate-enabled` class.
			$animateProvider.classNameFilter(/ng-animate-enabled/);

			// use the HTML5 History API
        	$locationProvider.html5Mode(true);

        	//lightox config
        	LightboxProvider.fullScreenMode = true;
        	LightboxProvider.templateUrl = '/views/inventoryLightbox.html';

        	// increase the maximum display height of the image
			LightboxProvider.calculateImageDimensionLimits = function (dimensions) {
			    return {
			      		'maxWidth': dimensions.windowWidth >= 768 ? // default
			        				dimensions.windowWidth - 92 :
			        				dimensions.windowWidth - 52,
			      		'maxHeight': 750                           // custom, we need to fit photo tray under the image
			    };
			}

        }
    AtlasApp.config( [ '$stateProvider', '$urlRouterProvider', '$authProvider', '$locationProvider', 'localStorageServiceProvider', 'LightboxProvider', '$animateProvider', config ] );

})();
(function() {

	'use strict';

	var AtlasApp = angular.module('AtlasApp');


	/* ==================================================================================== */
	// -   Base Model Class   -------------------------------------------------------------
	/* ==================================================================================== */

	function ModelFactory($http,$q,DateFactory){

		// ________________________________________________________________________________________
		// ----- Parent Model (Class) Obj ---------------------------------------------------------
		var ModelFactory = function ( model_config ){
			
			
			// ___________________________________________________
			// ----- PRIVATE ATTRIBS -----
			
			var _id = "new-" + (new Date()).getTime()+(new Date()).getMilliseconds()+Math.floor(Math.random()*100000);
			
			var _new = true;
			
			var _name = model_config.name;
			
			var _fields = model_config.fields;
			
			var _permitted = model_config.permitted;
			
			var _required = model_config.required;
			
			var _labels = model_config.labels;
			
			var _patterns = model_config.patterns;
			
			var _parent_id_field = model_config.parent_id_field;
			
			var _resource = model_config.resource;
			
			var _api = model_config.api;
			
			var _children = {};
			
			var _children_types = model_config.children_types;
			
			var _origs = {};
			
			var _active = true;
			
			var _convert_ints = false || model_config.convert_integers;
			
			// ___________________________________________________
			// ----- PUBLIC ATTRIBS -----
			
			this.attribs = {};
			
			
			// ___________________________________________________
			// ----- PRIVATE METHODS -----
			
			// Fill model attribs via passed in obj.
			// Private method, called by public-facing load() method
			var _loadByObject = function( obj,self ){

				// before loading the model's dataset,
				// we clear out any previously set data
				// first & let the following action be
				// the only data set.  For example, 
				// when an object is new, and it's saved,
				// it will get a returned dataset from the
				// webservice.  We don't re-instantiate the
				// object, so when we receive the model
				// data back from the webservice & attempt 
				// to load it (where we are now), we clear
				// any previously set data first.
				_origs = {};
				self.attribs = {};
				_children = {};
				
				// define date pattern.
				var ISO_8601 = /(\d{4})-(\d{2})-(\d{2})/;
				
				// loop through the object & populate the model's
				// datasets.
				for( var o in obj ){
					
					switch( o ){
						
						// set the objects id. if the 
						// id is numeric, update the
						// `new` flag accordingly.
						case 'id':
						_id = obj[ o ];
						if( !isNaN( _id ) ){
							_new = false;
						}
						break;
						
						// has the object been soft-
						// deleted? we store the active
						// flag outside of the attribs
						// object.
						case 'active':
						_active = obj[ o ];
						_origs._active = obj[ o ];
						break;
						
						
						// set object's attributes & populate children.
						default:
						
						if( _children_types.hasOwnProperty( String(o).toLowerCase() ) ){
							
							for( var c in obj[ o ] ){
								
								if( !_children.hasOwnProperty( String(o).toLowerCase() ) ){
									_children[ String(o).toLowerCase() ] = {};
								}
								
								self.addChild( String(o).toLowerCase(),obj[ o ][ c ] );
								
							}
							
							break;
						}

						if( Date.parse( obj[ o ] ) && ISO_8601.test( obj[ o ] )  ){
							self.set( o,new Date(obj[ o ]) );
							_origs[ o ] = new Date(obj[ o ]);
							break;
						}

						self.set( o,( _convert_ints && !isNaN( obj[ o ] ) ? parseInt( obj[ o ] ) : obj[ o ] ) );
						_origs[ o ] = ( _convert_ints && !isNaN( obj[ o ] ) ? parseInt( obj[ o ] ) : obj[ o ] );
						
						break;
						
					}
				}
			}
			
			
			// ___________________________________________________
			// ----- PUBLIC METHODS -----
			
			this.id = function(){ return _id; },
			this.isNew = function(){ return _new; },
			
			this.get = function( key ){ return this.attribs[ key ]; },
			this.set = function( key,val ){ this.attribs[ key ] = val; },
			
			this.keep = function(){ _active = typeof _active === "boolean" ? true : 1; },
			this.remove = function(){ _active = typeof _active === "boolean" ? false : 0; },
			this.isActive = function(){ return _active; },
			
			this.getChild = function( child_type,child_id ){
				
				if( !_children.hasOwnProperty( child_type ) ){
					return 	false;
				}
				
				if( _children[ child_type ].hasOwnProperty( child_id ) && child_id ){
					return 	_children[ child_type ][ child_id ];
				}
				
				return false;
				
			},
			this.setChild = function( child_type,child_obj ){
				
				if( typeof child_obj !== 'object' ){ return false; }
				
				if( !_children.hasOwnProperty( child_type ) ){
					_children[ child_type ] = {};
				}
				
				_children[ child_type ][ child_obj.id() ] = child_obj;
				
			},
			this.addChild = function( child_type,child_obj ){
				
				var self = this;
				
				var new_obj = _children_types[ child_type ].create();
				
				var deferred = $q.defer();
				
				new_obj
				.load( child_obj )
				.then(function(){

						// Set parent id to new obj.
						if( !isNaN( _id ) ){
							new_obj.setParentId( _id ); 
						}
						
						self.setChild( child_type,new_obj );
						
						deferred.resolve();
					},function(){
						deferred.reject( "Could not add record." );
					});
				
				return deferred.promise;
				
			},
			this.removeChild = function( child_type,child_id ){
				
				if( !_children.hasOwnProperty( child_type ) ){
					return 	false;
				}
				
				if( !_children[ child_type ].hasOwnProperty( child_id ) ){
					return false;
				}
				
				delete _children[ child_type ][ child_id ];
				
			},
			this.getChildren = function( child_type ){
				
				if( !child_type ){
					return _children;
				}
				
				if( !_children.hasOwnProperty( child_type ) ){
					return 	false;
				}
				
				return _children[ child_type ];
				
			},
			this.setParentId = function( parent_id ){
				if( _parent_id_field ){
					this.attribs[ _parent_id_field ] = parent_id;
				}
			},

			this.isModified = function(){
				
				var m = false;
				
				// compare attribs w/ origs.
				for( var o in _origs ){
					
					// if we already know we're modified, stop loopin'
					if( m ){ break; }
					
					if( o.indexOf( "_" ) === 0 ){ continue; }
					
					// do values match? 
					if( _origs[o] !== this.attribs[o] ){
						
						// ---- Values DO NOT (appear) to match.  Let's test a little more ... -----
						
						// are we comparing date objects?
						if( 
							_origs[o] instanceof Date ||
							this.attribs[o] instanceof Date
							){

							// create date object from original date
							// var original = new DateFactory;
							// original.setDate( _origs[o] );
							var original = _origs[o] instanceof Date ? _origs[o] : new Date( _origs[o] ); 

							// create date object from (user-supplied) updated date
							// var updated = new DateFactory;
							// updated.setDate( this.attribs[o] );
							var updated = this.attribs[o] instanceof Date ? this.attribs[o] : new Date( this.attribs[o] );

							// have the update date changed from original?
							if( original.getTime() !== updated.getTime() ){ m = true; }
							
							// complete loop iteration.
							continue;
						}
						
						m = true;
						
					}
					
				}
				
				// compare active flag
				if( _active !== _origs._active ){ m = true; }
				
				return m;
				
			},
			
			this.load = function( obj ){

				var self = this;
				var deferred = $q.defer();
				
				// Qualify arguments.
				if( typeof obj !== 'object' && isNaN( obj ) ){
					deferred.reject( "You must load the object with either a valid data model or a valid ID." );
					return deferred.promise;
				}
				
				// If argument is an obj, attempt to load data from there.
				if( typeof obj === 'object' ){
					_loadByObject( obj,self );
					deferred.resolve();
					return deferred.promise;
				}
				
				// If argument in an int, make call to web service & fetch object record.
				$http
				.get( _api + "/" + obj )
				.success(function( payload ){

					if( typeof payload.data !== 'object' ){
						var msg = "An unknown error occured attempting to complete your request.  Please try again.  Contact support if issues persist.";
						msg = payload.hasOwnProperty('message') ? payload.message : msg ;
						deferred.reject(msg);
						return;
					}
					
					_loadByObject(payload.data,self);
					deferred.resolve();
					
				})
				.error(function( payload ){
					
					var msg = "An unknown error occured attempting to complete your request.  Please try again.  Contact support if issues persist.";
					msg = payload.hasOwnProperty('message') ? payload.message : msg ;
					deferred.reject(msg);
				})
				
				return deferred.promise;
				
			},
			this.verify = function(){
				
				var r,c;
				
				// verify model.
				var failed = { empty_attribs: [], invalid_attribs: [], empty_children: [], invalid_children: [], children: [] };
				
				// 1. are required fields completed & required children exist? 
				for( r in _required ){
					
					// If the required key starts with an underscore, 
					// we're looking to test a private var
					if( _required[ r ].indexOf( "_" ) === 0 ){
						
						switch( _required[ r ].split(".")[0] ){
							
							// Make sure children exists & are of type `object`
							// Future update: verify instance of object.
							case '_children':
							
							if( _children.hasOwnProperty( _required[ r ].split(".")[1] ) ){
								
								var child_pass = false;
								for( c in _children[ _required[ r ].split(".")[1] ] ){
									if( child_pass ){ continue; }
									if( typeof _children[ _required[ r ].split(".")[1] ][ c ] === 'object' ){
										child_pass = true; continue;
									}
									
									failed.children.push( _labels[ _required[ r ].split(".")[0] ][ _required[ r ].split(".")[1] ] );
								}
								
							}else{
								
								failed.children.push( _labels[ _required[ r ].split(".")[0] ][ _required[ r ].split(".")[1] ] );
							}
							
							break;
							
						}
						
						continue;
					}
					
					// We don't typically store objects, except the native js Date obj,
					// which evals to an ISO string.
					if( typeof this.attribs[ _required[ r ] ] === 'object' ){ continue; }
					
					if( !String( this.attribs[ _required[ r ] ] ).length || this.attribs[ _required[ r ] ] === null || typeof this.attribs[ _required[ r ] ] === 'undefined' ){
						failed.empty_attribs.push( _labels[ _required[ r ] ] );
						
					}
				}
				
				// 2. do attribs match regex patterns? 
				// (future feature)
				
				// 3. verify children obj attribs.
				for( var child_type in _children ){
					for( c in _children[ child_type ] ){
						r = _children[ child_type ][ c ].verify();
						if( r !== true ){
							if( r.empty.length ){
								angular.extend( failed.empty_attribs,r.empty_attribs );
							}
							
							if( r.invalid.length ){
								angular.extend( failed.invalid_attribs,r.invalid_attribs );
							}
						}
					}
				}
				
				// ------------------------------------------------------
				// Check if errors exist.
				// Return true if no errors exist.
				var errors = 0;
				for( var f in failed ){
					if( failed[ f ].length ){
						errors += failed[ f ].length;
					}
				}
				
				if( !errors ){ return true; }
				
				// Return errored fields / section.
				errors = {empty:[],invalid:[],children:[]}
				
				// Empty Attributes.
				if( failed.empty_attribs.length ){
					errors.empty = failed.empty_attribs;
				}
				
				// Invalid Attributes.
				if( failed.invalid_attribs.length ){
					errors.invalid = failed.invalid_attribs;
				}
				
				// Honey, I lost the kids!
				if( failed.children.length ){
					errors.children = failed.children;
				}
				
				return errors;
				
			},
			this.save = function(){
				
				var deferred = $q.defer();
				
				// Has anything been modified?
				if( !this.isModified() ){
					
					// just because this model hasn't changed doesn't mean it's children havent.
					// lets go ahead & check on those guys now ....
					var promises = [];
					for( var c in _children ){
						
						// loop children type (item, user, customer, etc... )
						for( var t in _children[ c ] ){
							promises.push( _children[ c ][ t ].save() );
						}
						
					}
					
					// if there are no kiddos, and nothing was modified locally, resolve the promise.
					if( !promises.length ){
						deferred.resolve();
						return deferred.promise;
					}
					
					$q.all( promises )
					.then(function(){
						deferred.resolve();
					},function(msg){
						deferred.reject(msg);
					})
					
					return deferred.promise;
					
				}
				
				var data = {};
				angular.extend( data,this.attribs );
				angular.extend( data,{ 'active':_active } );
				
				// convert dates objects into string
				for( var d in data ){
					
					if( !d.indexOf( "_date" ) || !( data[ d ] instanceof Date ) ){ continue; }
					
					// use DateFactory service to convert the date.
					// the web service accepts format 'YYYY-MM-DD' only.
					
					// var df = new DateFactory;
					// df.setDate( data[ d ] );
					// data[ d ] = df.getDate( 'Y-M-D' );
					
				}
				
				// contents have been modified, complete save process.
				var url,method;
				url = _new ? _api : _api + "/" + _id ;
				
				// set the http method
				if( _new )
				{
					method = "post";
				}
				else
					if( parseInt(_origs._active) === parseInt(_active) )
					{
						method = "put";
					}
					else
					{
						method = _active ? "put" : "delete";
					}
					
				// make ajax call to backend
				var $promise = $http[method]( url,data );
				
				// ajax call follow-through
				$promise
				.success(function(payload,status){

					var c,t;

					var item_deleted = false;
					if( status === 204 ){
						item_deleted = true;
					}
					
					// store child promises.
					var promises = [];
					
					// If item was not deleted, proceed w/ saving children.
					if( !item_deleted ){
						
						// if the object we just saved was new,
						// get the object id & pass it along to
						// any children objects.
						var new_id;
						if( _new ){
							new_id = payload.data.id;
						}
						
						// we'll also save our id locally.
						// if the object was new, we want to
						// over-write the placeholder id.
						_id = payload.data.id;
						_new = false;
						
						// loop through children type groups
						// (item, user, customer, etc... )
						for( c in _children ){
							
							// loop children objects 
							for( t in _children[ c ] ){
								
								// if the new_id value was set,
								// update the child object w/ 
								// it's parent's new id.
								if( new_id ){
									_children[ c ][ t ].setParentId( new_id );
								}
								
								// save child object, store its returned promise.
								promises.push( _children[ c ][ t ].save() );
								
							}
							
						}
						
					}
					
					
					// if item was deleted, delete children records as well.
					else
					{
						
						// loop through children type groups
						// (item, user, customer, etc... )
						for( c in _children ){
							
							// loop children objects 
							for( t in _children[ c ] ){
								
								// mark child for deletion.
								_children[ c ][ t ].remove();
								
								// save child object, store its returned promise.
								promises.push( _children[ c ][ t ].save() );
								
							}
						}
					}
					
					
					// if there are no kiddos, and nothing was modified locally, resolve the promise.
					if( !promises.length ){ deferred.resolve(); return; }
					
					// resolve child promises.
					$q.all( promises )
					.then(function(){
						deferred.resolve();
					},function(msg){
						deferred.reject(msg);
					})
					
				})
.error(function(msg){	
	var default_msg = 'An unknown error occured trying to save the record.';
	deferred.reject( msg.hasOwnProperty( 'message' ) ? msg.message : default_msg );
});

				// return promise.
				return deferred.promise;	

			} // jshint ignore:line

			return this;

		}
		
		return ModelFactory;
		
	}
	AtlasApp.factory( 'ModelFactory',[ '$http', '$q', 'DateFactory', ModelFactory ] );



	/* ==================================================================================== */
	// -   MODEL FACTORY & SERVICES   -----------------------------------------------------
	/* ==================================================================================== */

	function UserFactory( $http, $q, ModelFactory, UserPermissionFactory, QueryRequest ){

		var model_config	=		{
			name: 'User',
			fields: [
			'first', 'last', 'email', 'phone', 
			'password', 'password_confirmation', 
			'role', 'created_at', 'updated_at'
			],
			permitted: [
			'first', 'last', 'email', 'phone', 
			'password', 'password_confirmation', 
			'role'
			],
			required: [
			'first', 'last', 'email', 'role'
			],
			labels: {
				first: 'First Name',
				last: 'Last Name',
				email: 'Email Address',
				phone: 'Phone',
				password: 'Password',
				password_confirmation: 'Password (Confirm)',
				role: 'User Role',
				created_at: 'User Creation Date & Time',
				updated_at: 'User Recent Update Date & Time'
			},
			patterns: {
				first: null, 
				last: null, 
				email: null, 
				phone: null, 
				password: null, 
				password_confirmation: null, 
				role: null, 
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "",
			resource: "",
			api: "/api/users",
			children_types: {
				permission: UserPermissionFactory
			}
		};
		
		var objects 		=		{};
		
		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		var fetch 			=		function( terms ){

			var deferred = $q.defer();

			var url = model_config.api;

			if( terms !== null && typeof terms === 'object' ){
				url += '?' + QueryRequest.serialize( terms );
			}

			var models = [];
			$http.get( url )
			.success(function(payload,status){
				for( var p in payload.data ){
					models.push( create( payload.data [ p ] ) );
				}
				deferred.resolve( models );
			})
			.error(function(msg){
				deferred.reject( msg );
			});
			
			return deferred.promise;

		}

		return						{
			create: create,
			fetch: fetch
		}

		
	}
	AtlasApp.factory( 'UserFactory',[ '$http', '$q', 'ModelFactory', 'UserPermissionFactory', 'QueryRequest', UserFactory ] );

	function UserPermissionFactory( $http, $q, ModelFactory ){
		
		var model_config	=		{
			name: 'User',
			fields: [
			'permissions'
			],
			permitted: [
			'permissions'
			],
			required: [
			'permissions'
			],
			labels: {
				permissions: 'User Access'
			},
			patterns: {
				permissions: null
			},
			parent_id_field: "user_id",
			resource: "",
			api: "/ajax/users",
			children_types: {}
		};
		
		var objects 		=		{};
		
		var update_model	=		function(m){
			return m;
		}
		
		var create 			=		function( obj ){
			var m = update_model( new ModelFactory( model_config ) );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};
		
		return						{
			create: create
		}
		
	}
	AtlasApp.factory( 'UserPermissionFactory',[ '$http', '$q', 'ModelFactory', UserPermissionFactory ] );

	function UserService( $http, $q, UserFactory ){

		var model 			= 		UserFactory.create();

		var models 			= 		[];

		var get 			= 		function(){
			return model;
		};

		var all 			=		function(){

			return models;

		};

		var search 			=		function( search_terms_obj ){

			var deferred = $q.defer();

			if( typeof search_terms_obj !== 'object' ){
				search_terms_obj = {}
			}

			UserFactory
			.fetch( search_terms_obj )
			.then(function(payload){
				models = payload;
				deferred.resolve(models);
			},function(msg){
				deferred.reject(msg);
			});

			return deferred.promise;

		};

		return						{
			get: get,
			all: all,
			search: search
		}
		
	}
	AtlasApp.factory( 'UserService',[ '$http', '$q', 'UserFactory', UserService ] );

	function ProfileFactory( $http, $q, ModelFactory ){

		var model_config	=		{
			name: 'User Profile',
			fields: [
			'first', 'last', 'email', 'phone', 'role', 
			'created_at', 'updated_at'
			],
			permitted: [
			'first', 'last', 'email', 'phone', 
			'created_at', 'updated_at'
			],
			required: [
			'first', 'last', 'email', 'role'
			],
			labels: {
				first: 'First Name', 
				last: 'Last Name', 
				email: 'Email Address', 
				phone: 'Phone', 
				role: 'User Type',
				created_at: 'User Creation Date & Time',
				updated_at: 'User Recent Update Date & Time'
			},
			patterns: {
				first: null,
				last: null,
				email: null,
				phone: null,
				role: null,
				created_at: null,
				updated_at: null
			},
			parent_id_field: "",
			resource: "",
			api: "/api/profiles",
			children_types: {}
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		return						{
			create: create
		}
		
	}
	AtlasApp.factory( 'ProfileFactory',[ '$http', '$q', 'ModelFactory', ProfileFactory ] );

	function ProfileService( $http, $q, ProfileFactory ){

		var model 			= 		ProfileFactory.create();

		var get 			= 		function(){
			return model;
		};

		return						{
			get: get
		}

	}
	AtlasApp.factory( 'ProfileService',[ '$http', '$q', 'ProfileFactory', ProfileService ] );

	function SubscriberFactory( $http, $q, ModelFactory, QueryRequest ){

		var model_config	=		{
			name: 'Subscriber',
			fields: [
			'subscribed', 'email', 'created_at', 'updated_at'
			],
			permitted: [
			'subscribed', 'email'
			],
			required: [
			'email'
			],
			labels: {
				subscribed: 'Subscribed',
				email: 'Email Address',
				created_at: 'Subscriber Creation Date & Time',
				updated_at: 'Subscriber Recent Update Date & Time'
			},
			patterns: {
				email: null, 
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "",
			resource: "",
			api: "/api/subscribers",
			children_types: {}
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		var fetch 			=		function( terms ){

			var deferred = $q.defer();

			var url = model_config.api;

			if( terms !== null && typeof terms === 'object' ){
				url += '?' + QueryRequest.serialize( terms );
			}

			var models = [];
			$http.get( url )
			.success(function(payload,status){
				for( var p in payload.data ){
					models.push( create( payload.data [ p ] ) );
				}
				deferred.resolve( models );
			})
			.error(function(msg){
				deferred.reject( msg );
			});
			
			return deferred.promise;

		}

		return						{
			create: create,
			fetch: fetch
		}
		
	}
	AtlasApp.factory( 'SubscriberFactory',[ '$http', '$q', 'ModelFactory', 'QueryRequest', SubscriberFactory ] );

	function SubscriberService( $http, $q, SubscriberFactory ){

		var model 			= 		SubscriberFactory.create();

		var models 			= 		[];

		var get 			= 		function(){
			return model;
		};

		var all 			=		function(){

			return models;

		};

		var search 			=		function( search_terms_obj ){

			var deferred = $q.defer();

			if( typeof search_terms_obj !== 'object' ){
				search_terms_obj = {}
			}

			SubscriberFactory
			.fetch( search_terms_obj )
			.then(function(payload){
				models = payload;
				deferred.resolve(models);
			},function(msg){
				deferred.reject(msg);
			});

			return deferred.promise;

		};

		return						{
			get: get,
			all: all,
			search: search
		}

	}
	AtlasApp.factory( 'SubscriberService',[ '$http', '$q', 'SubscriberFactory', SubscriberService ] );

	function VehicleFactory( $http, $q, ModelFactory, VehicleMetaFactory, VehicleImageFactory, QueryRequest ){

		var model_config	=		{
			name: 'Vehicle',
			fields: [
			'user_id', 'label', 'status', 'created_at', 'updated_at'
			],
			permitted: [
			'user_id', 'label', 'status'
			],
			required: [
			'label', 'status'
			],
			labels: {
				user_id: 'Vehicle Owner',
				label: 'Vehicle Title',
				created_at: 'Vehicle Creation Date & Time',
				updated_at: 'Vehicle Recent Update Date & Time'
			},
			patterns: {
				user_id: null,
				label: null,
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "user_id",
			resource: "",
			api: "/api/vehicles",
			children_types: {
				meta: VehicleMetaFactory,
				image: VehicleImageFactory
			}
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		var fetch 			=		function( terms ){

			var deferred = $q.defer();

			var url = model_config.api;

			if( terms !== null && typeof terms === 'object' ){
				url += '?' + QueryRequest.serialize( terms );
			}

			var models = [];
			$http.get( url )
			.success(function(payload,status){
				for( var p in payload.data ){
					models.push( create( payload.data [ p ] ) );
				}
				deferred.resolve( models );
			})
			.error(function(msg){
				deferred.reject( msg );
			});
			
			return deferred.promise;

		}

		return						{
			create: create,
			fetch: fetch
		}
		
	}
	AtlasApp.factory( 'VehicleFactory',[ '$http', '$q', 'ModelFactory', 'VehicleMetaFactory', 'VehicleImageFactory', 'QueryRequest', VehicleFactory ] );

	function VehicleService( $http, $q, VehicleFactory ){

		var model 			= 		VehicleFactory.create();

		var models 			= 		[];

		var get 			= 		function(){
			return model;
		};

		var all 			=		function(){

			return models;

		};

		var search 			=		function( search_terms_obj ){

			var deferred = $q.defer();

			if( typeof search_terms_obj !== 'object' ){
				search_terms_obj = {}
			}

			VehicleFactory
			.fetch( search_terms_obj )
			.then(function(payload){
				models = payload;
				deferred.resolve(models);
			},function(msg){
				deferred.reject(msg);
			});

			return deferred.promise;

		};

		return						{
			get: get,
			all: all,
			search: search
		}
		
	}
	AtlasApp.factory( 'VehicleService',[ '$http', '$q', 'VehicleFactory', VehicleService ] );

	function VehicleMetaFactory( $http, $q, ModelFactory ){

		var model_config	=		{
			name: 'Vehicle Setting',
			fields: [
			'vehicle_id', 'meta_key', 'meta_value', 'created_at', 'updated_at'
			],
			permitted: [
			'vehicle_id', 'meta_key', 'meta_value'
			],
			required: [
			'vehicle_id', 'meta_key', 'meta_value'
			],
			labels: {
				vehicle_id: 'Vehicle',
				meta_key: 'Vehicle Setting Type',
				meta_value: 'Vehicle Setting Value',
				created_at: 'Vehicle Setting Creation Date & Time',
				updated_at: 'Vehicle Setting Recent Update Date & Time'
			},
			patterns: {
				vehicle_id: null,
				meta_key: null,
				meta_value: null,
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "vehicle_id",
			resource: "",
			api: "/api/vehiclesmetas",
			children_types: { },
			convert_integers: true
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};
		
		return						{
			create: create
		}
		
	}
	AtlasApp.factory( 'VehicleMetaFactory',[ '$http', '$q', 'ModelFactory', VehicleMetaFactory ] );

	function VehicleImageFactory( $http, $q, ModelFactory ){

		var model_config	=		{
			name: 'Vehicle Image',
			fields: [
			'vehicle_id', 'caption', 'filename', 'filemime', 
			'filesize', 'primary', 'created_at', 'updated_at'
			],
			permitted: [
			'vehicle_id', 'caption', 'primary'
			],
			required: [],
			labels: {
				vehicle_id: 'Vehicle', 
				caption: 'Caption',
				filename: 'Filename',
				filemime: 'Filemime',
				filesize: 'Filesize',
				primary: 'Primary Photo',
				created_at: 'Vehicle Image Creation Date & Time',
				updated_at: 'Vehicle Image Recent Update Date & Time'
			},
			patterns: {
				vehicle_id: null, 
				caption: null, 
				filename: null, 
				filemime: null, 
				filesize: null, 
				primary: null,
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "vehicle_id",
			resource: "",
			api: "/api/vehiclesimages",
			children_types: { }
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};
		
		return						{
			create: create
		}
		
	}
	AtlasApp.factory( 'VehicleImageFactory',[ '$http', '$q', 'ModelFactory', VehicleImageFactory ] );

	function EventFactory( $http, $q, ModelFactory, QueryRequest ){

		var model_config	=		{
			name: 'Event',
			fields: [
			'user_id', 'type', 'label', 'description',
			'all_day','street','city','state','zip','appt'
			],
			permitted: [
			'user_id', 'type', 'label', 'description',
			'all_day','street','city','state','zip','appt'
			],
			required: [
			'type', 'appt'
			],
			labels: {
				user_id: 'Event Owner',
				type: 'Event Type',
				appt: 'Appt Date & Time',
				label: 'Event Title',
				description: 'Event Description',
				all_day: 'All Day Event', 
				street: 'Street Address',
				city: 'City', 
				state: 'State',
				zip: 'Zip Code',
				created_at: 'Event Creation Date & Time',
				updated_at: 'Event Recent Update Date & Time'
			},
			patterns: {
				user_id: null, 
				type: null, 
				appt: null, 
				label: null, 
				description: null, 
				all_day: null, 
				street: null, 
				city: null, 
				state: null, 
				zip: null, 
				created_at: null, 
				updated_at: null
			},
			parent_id_field: "",
			resource: "",
			api: "/api/events",
			children_types: {}
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		var fetch 			=		function( terms ){

			var deferred = $q.defer();

			var url = model_config.api;

			if( terms !== null && typeof terms === 'object' ){
				url += '?' + QueryRequest.serialize( terms );
			}

			var models = [];
			$http.get( url )
			.success(function(payload,status){
				for( var p in payload.data ){
					models.push( create( payload.data [ p ] ) );
				}
				deferred.resolve( models );
			})
			.error(function(msg){
				deferred.reject( msg );
			});
			
			return deferred.promise;

		}

		return						{
			create: create,
			fetch: fetch
		}
		
	}
	AtlasApp.factory( 'EventFactory',[ '$http', '$q', 'ModelFactory', 'QueryRequest', EventFactory ] );

	function EventService( $http, $q, EventFactory ){

		var model 			= 		EventFactory.create();

		var models 			= 		[];

		var get 			= 		function(){
			return model;
		};

		var all 			=		function(){

			return models;

		};

		var search 			=		function( search_terms_obj ){

			var deferred = $q.defer();

			if( typeof search_terms_obj !== 'object' ){
				search_terms_obj = {}
			}

			EventFactory
			.fetch( search_terms_obj )
			.then(function(payload){
				models = payload;
				deferred.resolve(models);
			},function(msg){
				deferred.reject(msg);
			});

			return deferred.promise;

		};

		return						{
			get: get,
			all: all,
			search: search
		}
		
	}
	AtlasApp.factory( 'EventService',[ '$http', '$q', 'EventFactory', EventService ] );

	function FAQFactory( $http, $q, ModelFactory, QueryRequest ){

		var model_config	=		{
			name: 'Frequently Asked Questions',
			fields: [
			'question', 'answer', 'order', 'created_at', 'updated_at'
			],
			permitted: [
			'question', 'answer', 'order'
			],
			required: [
			'question', 'answer', 'order'
			],
			labels: {
				question: 'FAQ Question',
				answer: 'FAQ Answer',
				order: 'FAQ Ordering',
				created_at: 'Frequently Asked Question Creation Date & Time',
				updated_at: 'Frequently Asked Question Recent Update Date & Time'
			},
			patterns: {
				question: null,
				answer: null,
				order: null,
				created_at: null,
				updated_at: null
			},
			parent_id_field: "",
			resource: "",
			api: "/api/faqs",
			children_types: {}
		};
		
		var objects 		=		{};

		var create 			=		function( obj ){
			var m = new ModelFactory( model_config );
			if( !obj ){ return m; }
			m.load( obj ); return m;
		};

		var fetch 			=		function( terms ){

			var deferred = $q.defer();

			var url = model_config.api;

			if( terms !== null && typeof terms === 'object' ){
				url += '?' + QueryRequest.serialize( terms );
			}

			var models = [];
			$http.get( url )
			.success(function(payload,status){
				for( var p in payload.data ){
					models.push( create( payload.data [ p ] ) );
				}
				deferred.resolve( models );
			})
			.error(function(msg){
				deferred.reject( msg );
			});
			
			return deferred.promise;

		}

		return						{
			create: create,
			fetch: fetch
		}
		
	}
	AtlasApp.factory( 'FAQFactory',[ '$http', '$q', 'ModelFactory', 'QueryRequest', FAQFactory ] );

	function FAQService( $http, $q, FAQFactory ){

		var model 			= 		FAQFactory.create();

		var models 			= 		[];

		var get 			= 		function(){
			return model;
		};

		var all 			=		function(){

			return models;

		};

		var search 			=		function( search_terms_obj ){

			var deferred = $q.defer();

			if( typeof search_terms_obj !== 'object' ){
				search_terms_obj = {}
			}

			FAQFactory
			.fetch( search_terms_obj )
			.then(function(payload){
				models = payload;
				deferred.resolve(models);
			},function(msg){
				deferred.reject(msg);
			});

			return deferred.promise;

		};

		return						{
			get: get,
			all: all,
			search: search
		}
		
	}
	AtlasApp.factory( 'FAQService',[ '$http', '$q', 'FAQFactory', FAQService ] );



	/* ==================================================================================== */
	// -   UTILITY SERVICES   -------------------------------------------------------------
	/* ==================================================================================== */

	function PreLoader( $q, $rootScope ){

        /**
	    * Image Preloader Function
	    * imageLocation array of image urls
	    */
	    function PreLoader( imageLocations ) {

            // img sources
            this.imageLocations = imageLocations;

            // track images, & those loaded vs. failed.
            this.imageCount = this.imageLocations.length;
            this.loadCount = 0;
            this.errorCount = 0;

            // img preload status
            this.states = {
            	PENDING: 1,
            	LOADING: 2,
            	RESOLVED: 3,
            	REJECTED: 4
            };

            // current preloader status
            this.state = this.states.PENDING;

            // return promise.
            this.deferred = $q.defer();
            this.promise = this.deferred.promise;

        }



        // ---------------------------------------
        // STATIC METHODS.

        // reload the imgs array and return a promise. 
        // the promise will be resolved with the array of imgs locations.
        PreLoader.preloadImages = function( imageLocations ) {
        	var preloader = new PreLoader( imageLocations );
        	return( preloader.load() );
        };



        // ---------------------------------------
        // INSTANCE METHODS.

        PreLoader.prototype = {

            // Best practice for "instnceof" operator.
            constructor: PreLoader,


        	// -----------------------------------
            // PUBLIC METHODS.

	        /**
		    * Image PreLoader isInitiated
		    * determines if the PreLoader has started loading images yet.
		    * @return Promise
		    */
		    isInitiated: function isInitiated() {
		    	return( this.state !== this.states.PENDING );
		    },
		    
	        /**
		    * Image PreLoader isRejected
		    * determines if the PreLoader has failed to load all of the images.
		    * @return Promise
		    */
		    isRejected: function isRejected() {
		    	return( this.state === this.states.REJECTED );
		    },

	        /**
		    * Image PreLoader isResolved
		    * determines if the PreLoader has successfully loaded all of the images.
		    * @return Promise
		    */
		    isResolved: function isResolved() {
		    	return( this.state === this.states.RESOLVED );
		    },

	        /**
		    * Image PreLoader load initialization
		    * initiates the preload of the images.
		    * @return Promise
		    */
		    load: function load() {

                // If the images are already loading, return the existing promise.
                if ( this.isInitiated() ){
                	return( this.promise );
                }

                this.state = this.states.LOADING;

                for ( var i = 0 ; i < this.imageCount ; i++ ){
                	this.loadImageLocation( this.imageLocations[ i ] );
                }

                // Return the deferred promise for the load event.
                return( this.promise );
            },


        	// -----------------------------------
            // PRIVATE METHODS.

	        /**
		    * Image PreLoader error handler
		    * handles the load-failure of the given image location.
		    */
		    handleImageError: function handleImageError( imageLocation ) {
		    	this.errorCount++;
                // If the preload action has already failed, ignore further action.
                if ( this.isRejected() ) {
                	return;
                }
                this.state = this.states.REJECTED;
                this.deferred.reject( imageLocation );
            },

	        /**
		    * Image PreLoader success handler
		    * handles the load-success of the given image location.
		    */
		    handleImageLoad: function handleImageLoad( imageLocation ) {
		    	this.loadCount++;
                // If the preload action has already failed, ignore further action.
                if ( this.isRejected() ) {
                	return;
                }
                // Notify the progress of the overall deferred. This is different
                // than Resolving the deferred - you can call notify many times
                // before the ultimate resolution (or rejection) of the deferred.
                this.deferred.notify({
                	percent: Math.ceil( this.loadCount / this.imageCount * 100 ),
                	imageLocation: imageLocation
                });
                // If all of the images have loaded, we can resolve the deferred
                // value that we returned to the calling context.
                if ( this.loadCount === this.imageCount ) {
                	this.state = this.states.RESOLVED;
                	this.deferred.resolve( this.imageLocations );
                }
            },
            
	        /**
		    * Image PreLoader image fetch
		    * loads the given image location and then wire the load / error
		    * events back into the PreLoader instance.
		    * note: The load/error events trigger a $digest.
		    */
		    loadImageLocation: function loadImageLocation( imageLocation ) {
		    	var preloader = this;
                // When it comes to creating the image object, it is critical that
                // we bind the event handlers BEFORE we actually set the image
                // source. Failure to do so will prevent the events from proper
                // triggering in some browsers.
                var image = $( new Image() )
                .load(
                	function( event ) {
                            // Since the load event is asynchronous, we have to
                            // tell AngularJS that something changed.
                            $rootScope.$apply(
                            	function() {
                            		preloader.handleImageLoad( event.target.src );
                                    // Clean up object reference to help with the
                                    // garbage collection in the closure.
                                    preloader = image = event = null;
                                }
                                );
                        }
                        )
                .error(
                	function( event ) {
                            // Since the load event is asynchronous, we have to
                            // tell AngularJS that something changed.
                            $rootScope.$apply(
                            	function() {
                            		preloader.handleImageError( event.target.src );
                                    // Clean up object reference to help with the
                                    // garbage collection in the closure.
                                    preloader = image = event = null;
                                }
                                );
                        }
                        )
                .prop( "src", imageLocation )
                ;
            }
        };

        // Return the factory instance.
        return( PreLoader );

    }
    AtlasApp.factory( 'PreLoader', [ '$q', '$rootScope', PreLoader ] );

    function QueryRequest(){

    	var resource = {
    		
    		serialize: function( obj, prefix, reference ) {
    			var $self = this;
    			var str = [];
    			for( var p in obj ) {
    				if (obj.hasOwnProperty(p)) {
    					var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
    					str.push(typeof v === "object" ?
    						resource.serialize(v, k, $self) :
    						encodeURIComponent(k) + "=" + encodeURIComponent(v));
    				}
    			}
    			return str.join("&");
    		},
    		
    		get: function( key ){
    			switch( key ){
    				case 'url':
    				return window.location.href;
    				case 'uri':
    				return window.location.pathname.split( "/" ).slice(1);
    				default:
    				var url = window.location.href;
    				url = url.toLowerCase();
    				key = key.replace(/[\[\]]/g, "\\$&").toLowerCase();
    				var regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
    				results = regex.exec(url);
    				if (!results){ return null; }
    				if (!results[2]){ return ''; }
    				return decodeURIComponent(results[2].replace(/\+/g, " "));
    			}
    		}
    		
    	};
    	
		// Add URL array to resource obj
		// resource.get = { url: l }
		return resource;

	}
	AtlasApp.factory( 'QueryRequest', [ QueryRequest ] );
	
	function DateFactory(){


		function DateFactory(){
			
			var date = new Date();
			var valid = true;
			
			this.setDate = function( dateString ){
				
				var d = new Date( dateString );
				if( !(new DateFactory()).isValid( d ) ){
					valid = false;
				}else{
					date.setMonth( d.getMonth() );
					date.setDate( d.getDate() );
					date.setFullYear( d.getFullYear() );
				}
				
				return this;
			}
			
			this.setTime = function( dateTimeString ){
				
				var d = new Date( dateTimeString );
				if( !(new DateFactory()).isValid( d ) ){
					valid = false;
				}else{
					date.setHours( d.getHours() );
					date.setMinutes( d.getMinutes() );
					date.setSeconds( 0 );
					date.setMilliseconds( 0 );
				}
				
				return this;
			}
			
			this.getDate = function( format ){
				
				var temp = {
					M: date.getMonth() + 1,
					D: date.getDate(),
					Y: date.getFullYear()
				}
				
				if( !format ){
					return temp.Y + '-' + temp.M + '-' + temp.D;
				}
				
				var date_string;
				if( format.indexOf( "-" ) > -1 ){
					format = format.split( "-" );
					
					date_string = temp[ format[0] ] + '-' + temp[ format[1] ];
					if( temp[ format[2] ] ){
						return date_string + '-' + temp[ format[2] ];
					}
					return date_string;
					
				}
				
				if( format.indexOf( "/" ) > -1 ){
					format = format.split( "/" );
					date_string = temp[ format[0] ] + '/' + temp[ format[1] ];
					if( temp[ format[2] ] ){
						return date_string + '/' + temp[ format[2] ];
					}
					return date_string;
					
				}
				
				if( String(format).toLowerCase() === 'obj' ){
					return date;
				}
				
			}

			this.getTime = function( format ){

				var temp = {
					H: date.getHours(),
					M: date.getMinutes(),
					S: date.getSeconds(),
					A: (date.getHours() > 12 ? "PM" : "AM")
				}

				temp.M = String(temp.M).length > 1 ? temp.M : '0' + temp.M;
				temp.S = String(temp.S).length > 1 ? temp.S : '0' + temp.S;

				if( !format ){
					return temp.H + ':' + temp.M + ' ' + temp.A;
				}
				
				var date_string;
				if( format.indexOf( "-" ) > -1 ){
					format = format.split( "-" );
					
					date_string = temp[ format[0] ] + '-' + temp[ format[1] ];
					if( temp[ format[2] ] ){
						return date_string + '-' + temp[ format[2] ];
					}
					return date_string;
					
				}
				
				if( format.indexOf( "/" ) > -1 ){
					format = format.split( "/" );
					date_string = temp[ format[0] ] + '/' + temp[ format[1] ];
					if( temp[ format[2] ] ){
						return date_string + '/' + temp[ format[2] ];
					}
					return date_string;
					
				}
				
				if( String(format).toLowerCase() === 'obj' ){
					return date;
				}
				
			}

			this.isValid = function( dateObj ){
				if( typeof dateObj === 'object' ){
					return 	Object.prototype.toString.call(dateObj) === "[object Date]"
					? !isNaN( dateObj.getTime() ) : false ;				
				}else{
					return 	Object.prototype.toString.call(date) === "[object Date]" && valid 
					? !isNaN( date.getTime() ) : false ;
				}
			}
			
			// Is this date greater than the passed in date?
			this.isGreater = function( dateCompare ){
				
				if( typeof dateCompare === 'string' ){
					dateCompare = new Date( dateCompare );
				}
				
				return date > dateCompare;
				
			}
			
			this.getOrdinal = function( date_obj ){

				if(!( date_obj instanceof Date )){ date_obj = date; }

				if(date_obj>3 && date_obj<21){ return 'th'; }

				switch (date_obj % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}

			}
			
			this.getTomorrow = function(){
				return new Date(
					date.getFullYear(),
					date.getMonth(),
					( parseInt( date.getDate() ) + 1 ),
					0, 0, 0, 0
					);
			}
			
			this.isInFuture = function(){
				return date > new Date();
			}
			
			this.isInPast = function(){
				return date < new Date();
			}
			
			this.localISO = function(){

				if( !valid ){ return false; }

				var tzoffset = date.getTimezoneOffset() * 60000;
				var localISOTime = (new Date(date.getTime() - tzoffset)).toISOString().slice(0,-1);
				return localISOTime + String( new Date() ).split( ' ' ).pop().replace(/[^A-Za-z]+/g, "");
			}
			
			this.utcISO = function(){
				if( !valid ){ return false; }
				return date.toISOString();
			}
			
			this.getTZAbbr = function(){
				return (String(String(new Date()).split("(")[1]).split(")")[0]);
			}
			
			this.diff = function( compare ){
				
				if( typeof compare !== 'object' ){
					switch( String(compare).toLowerCase() ){
						default:
						compare = new Date();
						break;
					}	
				}

				// Return difference.
				return Math.abs( compare.getTime() - date.getTime() );
				
			}

			this.sameDate = function( date_obj ){

				if(!( date_obj instanceof Date )){ return false; }
				date_obj.setHours(0); date_obj.setMinutes(0); date_obj.setSeconds(0); date_obj.setMilliseconds(0);

				var date_compare = new Date(date);
				date_compare.setHours(0); date_compare.setMinutes(0); date_compare.setSeconds(0); date_compare.setMilliseconds(0);

				return date_obj.getTime() === date_compare.getTime();
			}

			this.isLeapYear = function( year ){
				
				var d;

				if( typeof year === 'object' ){
					if( !this.isValid( year ) ){ return false; }
					d = year;
				}else if( !isNaN( String( year ) ) ){
					d = new Date(year, 1, 28);
				}else{
					d = new Date(date.getFullYear(), 1, 28);	
				}
				
				d.setDate(d.getDate() + 1);
				return d.getMonth() === 1;
				
			}
			
			this.getAge = function(){
				
				if( !this.isValid() ){ return false; }
				
				var d = new Date(date.getTime()), now = new Date();
				var years = now.getFullYear() - d.getFullYear();
				d.setFullYear(d.getFullYear() + years);
				if (d > now) {
					years--;
					d.setFullYear(d.getFullYear() - 1);
				}
				var days = (now.getTime() - d.getTime()) / (3600 * 24 * 1000);
				return Math.floor( years + days / (this.isLeapYear(now.getFullYear()) ? 366 : 365) );
				
			}
			
		}
		
		return DateFactory;

	}
	AtlasApp.factory( 'DateFactory', [ DateFactory ] );

	function Formatter( DateFactory ){

		var currency = function( number, decimal, symbol )
		{
			decimal = !isNaN( decimal ) ? decimal : 0 ;
			symbol = typeof symbol !== 'undefined' && symbol !== null ? symbol : '' ;
			var formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: decimal,
			});
			return symbol + formatter.format(number);
		}

		var ucwords = function( str )
		{
			return (str + '').toLowerCase()
			.replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function($1) {
				return $1.toUpperCase();
			});
		}

		var ucfirst = function( str )
		{
			str += "";
			var f = str.charAt(0).toUpperCase();
			return f + str.substr(1);
		}

		var wordwrap = function( str, length )
		{
			str += "";
			var trimmedString = str.substr(0, length);
			return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
		}

		var date_user = function( date_string )
		{
			var d = new DateFactory();
			d.setDate( date_string );
			return d.getDate( 'M/D/Y' );
		}

		var filesize = function( size )
		{

			// what sizes are we working w/?
			// megabytes or kilobytes.
			var large = size > 1000000;

			// set size label
			var label = large ? "Mb" : "Kb";

			if( large )
			{
				size = (size/1024/1024).toFixed(2);
			}
			else
			{
				size = (size/1024).toFixed(2);
			}

			// add commas.
			return currency( size,2 ).substr(1) + label;

		}

		var phone = function( str )
		{

			if( str === null ){ return; }

			str = String(parseInt(str));

			switch( str.length ){
				
				case 11:
				return	str.substring(0,1) + ' '
				+	'(' + str.substring(1,4) + ') '
				+	str.substring(4,7) + '-'
				+	str.substring(7,11);
				case 10:
				return	'(' + str.substring(0,3) + ') '
				+	str.substring(3,6) + '-'
				+	str.substring(6,10);
				case 7:
				return	str.substring(0,3) + '-'
				+	str.substring(3,7);
				default: return str;
			}

		}

		var userDate = function( str ){

			var date;

			switch( typeof str ){
				case "string":
				date = new Date( str );
				break;
				case "object":
				if( str instanceof Date ){
					date = new Date( str );
				}
			}

			if( !date ){ return str; }

			var d = ((new DateFactory()).setDate( date )).getDate("M/D/Y");

			return d;
		}

		var userTime = function( str ){

			var date;

			switch( typeof str ){
				case "string":
				date = new Date( str );
				break;
				case "object":
				if( str instanceof Date ){
					date = new Date( str );
				}

			}

			if( !date ){ return str; }

			var t = ((new DateFactory()).setTime( date )).getTime();

			// note: date factory returns military time.
			// lets format it better for the average user.
			t = t.split(':');
			t[0] = t[0] > 12 ? t[0] - 12 : t[0];

			return t.join(':');
		}

		var imgToTB = function( filename ){
			if( !filename ){ return filename; }
			var e,f;
			f = filename.substr(0,filename.lastIndexOf('.'));
			e = filename.substr(filename.lastIndexOf('.')+1);
			return f + '.tb.' + e;	
		}

		var imgToSM = function( filename ){
			if( !filename ){ return filename; }
			var e,f;
			f = filename.substr(0,filename.lastIndexOf('.'));
			e = filename.substr(filename.lastIndexOf('.')+1);
			return f + '.sm.' + e;	
		}

		var imgToMD = function( filename ){
			if( !filename ){ return filename; }
			var e,f;
			f = filename.substr(0,filename.lastIndexOf('.'));
			e = filename.substr(filename.lastIndexOf('.')+1);
			return f + '.md.' + e;	
		}

		var imgToLG = function( filename ){
			if( !filename ){ return filename; }
			var e,f;
			f = filename.substr(0,filename.lastIndexOf('.'));
			e = filename.substr(filename.lastIndexOf('.')+1);
			return f + '.lg.' + e;	
		}

		var imgToXLG = function( filename ){
			if( !filename ){ return filename; }
			var e,f;
			f = filename.substr(0,filename.lastIndexOf('.'));
			e = filename.substr(filename.lastIndexOf('.')+1);
			return f + '.xlg.' + e;	
		}

		return {
			currency: currency,
			ucfirst: ucfirst,
			ucwords: ucwords,
			wordwrap: wordwrap,
			date_user: date_user,
			filesize: filesize,
			phone: phone,
			userDate: userDate,
			userTime: userTime,
			imgToTB: imgToTB,
			imgToSM: imgToSM,
			imgToMD: imgToMD,
			imgToLG: imgToLG,
			imgToXLG: imgToXLG
		}

	}
	AtlasApp.service( 'Formatter',[ 'DateFactory', Formatter ] );

	function VehicleOptionsService(){

		var statuses = [
		{	type: 'for_sale',		label:	'For Sale', 		description: 'Vehicle is for sale.  Listing is visible to the public.'													},
		{	type: 'sold',			label:	'Sold',				description: 'Vehicle has been sold.  Listing is visible to the public.'												},
		{	type: 'draft',			label:	'Draft', 			description: 'Vehicle listing is on hold.  Listing is not visible to the public.'										},
		{	type: 'wishlist',		label:	'Wishlist', 		description: 'Vehicle requested is desired by a potential customer.  Listing is only visible to Maxed Out staff.'		},
		{	type: 'list_my_vehicle',label:	'List My Vehicle', 	description: 'Vehicle sales listing is requested by potential customer.  Listing is only visible to Maxed Out staff.'	},
		]

		return {
			statuses: statuses
		}

	}
	AtlasApp.service( 'VehicleOptionsService',[ VehicleOptionsService ] );

	function VehicleMetaOptionsService(){

		var types = [
		{	type: 'performance',	label:	'Performance' 		},
		{	type: 'recreation',		label:	'Leisure & Recreation'},
		{	type: 'onroad',			label:	'On-Road' 			},
		{	type: 'offroad',		label:	'Off-Road' 			},
		]

		var options = {

			'performance': [
			{	type: 'year',		label:	'Year' 				},
			{	type: 'make',		label:	'Make' 				},
			{	type: 'model',		label:	'Model' 			},
			{	type: 'price',		label:	'Price' 			},
			{	type: 'propulsion',	label:	'Propulsion' 		},
			{	type: 'hull',		label:	'Hull'	 			},
			{	type: 'length',		label:	'Length' 			},
			{	type: 'description',label:	'Description' 		},
			{	type: 'trailer',	label:	'Trailer' 			},
			{	type: 'engine',		label:	'Engine(s)' 		},
			{	type: 'horsepower',	label:	'Horsepower' 		},
			{	type: 'fuel',		label:	'Fuel Type' 		},
			{	type: 'weight',		label:	'Weight' 			},
			{	type: 'draft',		label:	'Draft'		 		},
			{	type: 'beam',		label:	'Beam'		 		}
			],

			'recreation': [
			{	type: 'year',		label:	'Year' 				},
			{	type: 'make',		label:	'Make' 				},
			{	type: 'model',		label:	'Model' 			},
			{	type: 'price',		label:	'Price' 			},
			{	type: 'propulsion',	label:	'Propulsion' 		},
			{	type: 'hull',		label:	'Hull'	 			},
			{	type: 'length',		label:	'Length' 			},
			{	type: 'description',label:	'Description' 		},
			{	type: 'trailer',	label:	'Trailer' 			},
			{	type: 'engine',		label:	'Engine(s)' 		},
			{	type: 'horsepower',	label:	'Horsepower' 		},
			{	type: 'fuel',		label:	'Fuel Type' 		},
			{	type: 'weight',		label:	'Weight' 			},
			{	type: 'draft',		label:	'Draft'		 		},
			{	type: 'beam',		label:	'Beam'		 		}
			],

			'onroad': [
			{	type: 'year',		label:	'Year' 				},
			{	type: 'make',		label:	'Make' 				},
			{	type: 'model',		label:	'Model' 			},
			{	type: 'price',		label:	'Price' 			},
			{	type: 'description',label:	'Description' 		},
			{	type: 'drivetrain',	label:	'Drive Train' 		},
			{	type: 'transmission',label:	'Transmission' 		},
			{	type: 'wheels',		label:	'Wheels' 			},
			{	type: 'tires',		label:	'Tires' 			},
			{	type: 'suspension',	label:	'Suspension' 		},
			{	type: 'engine',		label:	'Engine'	 		},
			{	type: 'fuel',		label:	'Fuel Type' 		},
			{	type: 'horsepower',	label:	'Horsepower' 		},
			{	type: 'wheelbase',	label:	'Wheelbase' 		},
			{	type: 'weight',		label:	'Weight' 			}
			],

			'offroad': [
			{	type: 'year',		label:	'Year' 				},
			{	type: 'make',		label:	'Make' 				},
			{	type: 'model',		label:	'Model' 			},
			{	type: 'price',		label:	'Price' 			},
			{	type: 'description',label:	'Description' 		},
			{	type: 'drivetrain',	label:	'Drive Train' 		},
			{	type: 'transmission',label:	'Transmission' 		},
			{	type: 'wheels',		label:	'Wheels' 			},
			{	type: 'tires',		label:	'Tires' 			},
			{	type: 'suspension',	label:	'Suspension' 		},
			{	type: 'engine',		label:	'Engine'	 		},
			{	type: 'fuel',		label:	'Fuel Type' 		},
			{	type: 'horsepower',	label:	'Horsepower' 		},
			{	type: 'wheelbase',	label:	'Wheelbase' 		},
			{	type: 'weight',		label:	'Weight' 			}
			]
		}

		var master = { type: 'Type' };
		for( var t in types ){
			for( var o in options[types[ t ].type] ){
				if( !master.hasOwnProperty( options[types[ t ].type][o].type ) ){
					master[ options[types[ t ].type][o].type ] = options[types[ t ].type][o].label;
				}
			}
		}

		return {
			types: types,
			options: options,
			master: master
		}

	}
	AtlasApp.service( 'VehicleMetaOptionsService',[ VehicleMetaOptionsService ] );

	function SearchService( $http, $q, QueryRequest, VehicleFactory, EventFactory ){

		var results_storage = { vehicles: [], events: [] };

		var clear = function(){ results_storage = {}; }

		var get = function( search_term ){

			var groups = [ 'vehicles','events' ];

			var deferred = $q.defer();

			if( typeof search_term === undefined || search_term === null ){
				deferred.reject('Please enter a search term.');
				return deferred.promise;
			}

			if( !String(search_term).length ){
				deferred.reject('Please enter a search term.');
				return deferred.promise;
			}

			$http.post( "/search?" + QueryRequest.serialize( { q: search_term } ) )
			.then(
				function( payload ){ 

					for( var g in groups ){

						results_storage[ groups[ g ] ] = [];

						if( typeof payload.data.data.vehicles !== 'object' ){ continue; }

						var ModelFactory;

						switch( groups[ g ] ){
							case "events":		ModelFactory = EventFactory;		break;
							case "vehicles":	ModelFactory = VehicleFactory;		break;
							default: continue;
						}

						for( var v in payload.data.data[ groups[ g ] ] ){
							results_storage[ groups[ g ] ].push( ModelFactory.create( payload.data.data[ groups[ g ] ][ v ] ) );
						}

					}

					deferred.resolve( results_storage );
				},
				function( payload ){ deferred.reject( payload.message ); }
				);

			return deferred.promise;
		}

		return {
			results: results_storage,
			clear: clear,
			get: get
		};

	}
	AtlasApp.factory( 'SearchService',[ '$http', '$q', 'QueryRequest', 'VehicleFactory', 'EventFactory', SearchService ] );

	function CalendarService( $http, $q, EventService ){

		var CalendarService = {}

		var current_date;

		var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

		var storage_events = {}

		var storage_models = {}

		var build,get,getEventsKey,isInStorage,getFromStorage,nextMonth,currentMonth,prevMonth,setTitle;

		var set_date = function( dt ){

			CalendarService.current_date = dt;

			get().then(function(){build();setTitle();});

		}

		var show = function( event ){

			// get event date.  this will drastically reduce the events we 
			// have to search through to find the related Event object.
			event.date = typeof event.date === 'object' ? event.date : new Date( event.date );
			var d = event.date.getDate();

			// bool flag for found obj.
			var found = false;

			for( var m in storage_models[ getEventsKey() ][ d ] ){
				if( found ){ break; }
				found = ( event.id === storage_models[ getEventsKey() ][ d ][ m ].id() );
				CalendarService.event = storage_models[ getEventsKey() ][ d ][ m ];
			}

		}

		// build obj to populate calendar
		// display based on `storage_events`
		// and the current_date.
		build = function(){

			// get events / appts
			var events = storage_events[ getEventsKey() ];

			// store dates.
			var dates = {};

			// date
			dates.d = CalendarService.current_date.getDate();
			dates.m = CalendarService.current_date.getMonth();
			dates.y = CalendarService.current_date.getFullYear();

			// get first day of the month
			var localOffset = -(new Date().getTimezoneOffset()/60);
			dates.s = new Date( dates.y, dates.m, 1 );
			dates.s.setUTCHours(dates.s.getUTCHours() + localOffset);
			dates.s = dates.s.getUTCDay();
			
			// day (number) of the week. (0-6, Sun-Sat)
			dates.a = CalendarService.current_date.getUTCDay();

			// how many days in month?
			nextMonth(function( nM ){ nM.setDate(0); dates.i = nM.getDate(); });

			// get number of total calendar weeks 
			dates.w = Math.ceil( (dates.s + dates.i) / 7 );

			// get remainder days
			dates.r = ( dates.w * 7 ) - ( dates.s + dates.i );

			// build model.
			var rows = [];
			var count = 0;
			for( var r = 1; r <= dates.w; r++ ){

				var row = {
					id: r,
					classes: ['week'],
					cells: []
				}

				for( var c = 1; c <= 7; c++ ){

					// increase cell counters
					count++;

					// get todays date - is it an actual day?
					var day = ( ( dates.s <= count ) && ( ( count - dates.s ) <= dates.i ) && ( count - dates.s ) > 0 ? count - dates.s : null );

					// --- set cell classes -----------
					var classes = ['cell'];
					if( dates.s >= count ){ classes.push( 'prev' ); }
					if( day ){ classes.push( 'day' ); }
					if( day === 1 ){ classes.push( 'start' ); }
					if( day === dates.d && dates.m === (new Date()).getMonth() ){ classes.push( 'today' ); }
					if( day === dates.i ){ classes.push( 'end' ); }
					if( count > ( dates.i + dates.s ) ){ classes.push( 'next' ); }

					// build obj.
					var cell = {
						id: (r*c),
						date: ( day ? new Date( dates.y, dates.m, day ) : null ),
						day: day,
						classes: classes,
						events: ( events.hasOwnProperty( day ) ? events[ day ] : [] )
					}
					row.cells.push( cell );

				}

				rows.push( row );

			}

			CalendarService.calendar = rows;
		}

		// fetches the event data from the
		// api or from storage if already
		// loaded the selected month.
		// force = true will ensure a new http call is made fetch new models.
		get = function( dt, force ){

			var deferred = $q.defer();

			// get events_key (YYYY-MM, how we track the month's events.)
			var ek = getEventsKey( CalendarService.current_date )

			// are the events already in storage?
			if( isInStorage( getEventsKey( dt ) ) && !force ){
				CalendarService.events = getFromStorage( ek, 'events' );
				CalendarService.models = getFromStorage( ek, 'models' );
				deferred.resolve();
				return deferred.promise;
			}

			// turn on loading
			EventService
			.search( { type: 'month', date: CalendarService.current_date.getTime() } )
			.then(function(payload){

					// put all events in chronological order.
					payload.sort(function(a,b){ return new Date(a.attribs.appt) - new Date(b.attribs.appt); });

					storage_events[ ek ] = {}
					storage_models[ ek ] = {}

					// organize by date.
					for( var p in payload ){

						// get date of month of event iteration.
						var d = ( new Date( payload[ p ].attribs.appt ) ).getDate();

						// add date property to storage objects.  set date to empty array.
						if( !storage_events[ ek ].hasOwnProperty( d ) ){ storage_events[ ek ][ d ] = []; }
						if( !storage_models[ ek ].hasOwnProperty( d ) ){ storage_models[ ek ][ d ] = []; }

						// create event object.
						var date = typeof payload[ p ].attribs.appt === 'object' ? payload[ p ].attribs.appt : new Date( payload[ p ].attribs.appt );
						var time = ( date.getHours() > 12 ? date.getHours() - 12 : date.getHours() ) + ":"
						+ ( String(date.getMinutes()).length === 1 ? "0" + date.getMinutes() 
							: date.getMinutes() ) + " " 
						+ ( date.getHours() > 12 ? "PM" : "AM" );
						var event = {
							id: payload[ p ].id(),
							date: date,
							time: time,
							show: show,
							label: payload[ p ].attribs.label,
							all_day: payload[ p ].attribs.all_day,
							type: String(payload[ p ].attribs.type).toLowerCase()
						}

						// add event & model to respective object arrays.
						storage_events[ ek ][ d ].push( event );
						storage_models[ ek ][ d ].push( payload[ p ] );

					}

					CalendarService.events = getFromStorage( ek, 'events' );
					CalendarService.models = getFromStorage( ek, 'models' );

					deferred.resolve();

					// turn off loading
				},function(msg){
					// turn off loading
					deferred.reject(msg);
				});

return deferred.promise;

}

		// reloads the events & models
		// current storage, with option
		// to force http call to replace
		// corresponding contents.
		var refresh = function( force ){
			get(current_date,force = force ? true : false).then(function(){build();setTitle();});

		}

		getEventsKey = function( dt ){
			dt = dt ? dt : CalendarService.current_date;
			return dt.getFullYear() + '-' + ( String( dt.getMonth() + 1 ).length === 1 ? '0' + ( dt.getMonth() + 1 ) : ( dt.getMonth() + 1 ) );
		}

		isInStorage = function( events_key ){
			return storage_models.hasOwnProperty( events_key );
		}

		getFromStorage = function( events_key, type ){
			switch( type ){
				case "events":
				return storage_events[ events_key ];
				case "models":
				return storage_models[ events_key ];					
			}
		}

		nextMonth = function( callback ){

			var dt = CalendarService.current_date;
			var y = dt.getFullYear();
			var m = dt.getMonth();
			var d = dt.getDate();

			if( m === 11 ){
				m = 1;y++;
			}else{
				m++;m++;
			}

			m = m <= 9 ? "0" + m : m ;
			d = d <= 9 ? "0" + d : d ;

			if( typeof callback === 'function' ){
				return callback( new Date( y+'-'+m+'-'+d ) );
			}

			CalendarService.current_date = new Date( y+'-'+m+'-'+d );

			get().then(function(){build();setTitle();});

		}

		currentMonth = function(){
			set_date( new Date() );
		}

		prevMonth = function( callback ){

			var dt = CalendarService.current_date;
			var y = dt.getFullYear();
			var m = dt.getMonth();
			var d = dt.getDate();

			if( m === 0 ){
				m = 12;y--;
			}

			m = m <= 9 ? "0" + m : m ;
			d = d <= 9 ? "0" + d : d ;

			if( typeof callback === 'function' ){
				return callback( new Date( y+'-'+m+'-'+dt.getDate() ) );
			}

			CalendarService.current_date = new Date( y+'-'+m+'-'+dt.getDate() );

			get().then(function(){build();setTitle();});

		}

		setTitle = function(){
			CalendarService.title = monthNames[ CalendarService.current_date.getMonth() ] + ' ' + CalendarService.current_date.getFullYear();
		}


		// Store Events Models & event objects.
		// Organized by date of month.
		CalendarService.events = {};
		CalendarService.models = {};
		
		// stores the active Event model
		// triggered when user clicks on an event.
		CalendarService.event = null;
		
		// Current date (js Date) object.
		CalendarService.current_date = new Date();
		
		// Update CalendarService Model Active/Current Date.
		CalendarService.set_date = set_date;
		
		// Trigger month change.
		CalendarService.next = nextMonth;
		CalendarService.current = currentMonth;
		CalendarService.prev = prevMonth;
		
		// Store the calendar view object.
		CalendarService.calendar = {};
		
		// Reloads the models & events.
		CalendarService.refresh = refresh;

		return CalendarService;

	}
	AtlasApp.service( 'CalendarService',[ '$http', '$q', 'EventService', CalendarService ] );

	function FormEntryFactory( $http, $q, $rootScope ){

		// store form model values.
		var model = {}

		var form = {}

		var rules = {
			integer: "^\\d$",
			name: "^[a-zA-Z]+(([\\'\\,\\.\\- ][a-zA-Z ])?[a-zA-Z]*)*$",
			email: "^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$",
			phone: "^1?[-\\. ]?(\\(\\d{3} \\)?[-\\. ]?|\\d{3}?[-\\. ]?)?\\d{3}?[-\\. ]?\\d{4}$"
		};

		var response = {
			status: null,
			message: null
		};

		var status = false;

		// submit form.
		var send = function( callback ){
			model.form = form.name;
			$http.
			post( "/api/formentries",model )
			.success(function(payload){
				response.status = true;
				response.message = payload.message.join(' ');
				if( typeof callback === 'function' ){ callback( payload ); }
			})
			.error(function(payload){
				response.status = false;
				response.message = payload.message.join(' ');
				if( typeof callback === 'function' ){ callback( payload ); }
			});

		}

		return {
			model: model,
			form: form,
			send: send,
			response: response,
			rules: rules
		}

	}
	AtlasApp.service( 'FormEntryFactory', [ '$http', '$q', '$rootScope', FormEntryFactory ] );

	function FormEntryService( $http, $q, $rootScope ){

		// store form model values.
		this.model = {}

		this.form = {}

		this.rules = {
			integer: "^\\d$",
			name: "^[a-zA-Z]+(([\\'\\,\\.\\- ][a-zA-Z ])?[a-zA-Z]*)*$",
			email: "^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$",
			phone: "^1?[-\\. ]?(\\(\\d{3} \\)?[-\\. ]?|\\d{3}?[-\\. ]?)?\\d{3}?[-\\. ]?\\d{4}$"
		};

		this.response = {
			status: null,
			message: null
		};

		this.status = false;

		this.refresh = function(){
			this.model = {};
			this.form = {};
			this.response = {
				status: null,
				message: null
			};
			this.status = false;
		}

		// submit form.
		this.send = function( callback ){
			this.model.form = this.form.name;
			var self = this;
			$http.
			post( "/api/formentries",this.model )
			.success(function(payload){
				self.response.status = true;
				self.response.message = payload.message.join(' ');
				if( typeof callback === 'function' ){ callback( payload ); }
			})
			.error(function(payload){
				self.response.status = false;
				self.response.message = payload.message.join(' ');
				if( typeof callback === 'function' ){ callback( payload ); }
			});

		}

	}
	AtlasApp.service( 'FormEntryService', [ '$http', '$q', '$rootScope', FormEntryService ] );



})();
(function() {

	'use strict';

	var AtlasApp = angular.module('AtlasApp');


	/* ==================================================================================== */
	// -   GLOBAL DIRECTIVES   ------------------------------------------------------------
	/* ==================================================================================== */

	function frontNavbar(){

		function controller( $scope, $timeout, $state, $stateParams, $auth, SearchService ){

			var map = {};

			$scope.parent = null;

			$scope.child = null;

			$scope.menus = [
				{
					title: 'Login',
					state: 'auth',
					show: false,
					children: []
				},
				{
					title: 'Logout',
					state: 'logout',
					show: false,
					children: []
				},
				{
					title: 'My Account',
					state: 'dashboard',
					show: false,
					children: []
				},
				{
					title: 'HOME',
					state: '/',
					show: false,
					children: []
				},
				{
					title: 'COMPANY',
					state: 'company',
					show: true,
					children: [
						{
							title: 'TEAM',
							state: 'team'
						},
						{
							title: 'EVENTS',
							state: 'events'
						},
						{
							title: 'FAQ',
							state: 'faq'
						}
					]
				},
				{
					title: 'SHOP',
					state: 'inventory',
					show: true,
					children: [
						{
							title: 'PERFORMANCE',
							state: 'performance',
							param_string: '.type',
							params: {type:'performance'}
						},
						{
							title: 'LEISURE & RECREATION',
							state: 'recreation',
							param_string: '.type',
							params: {type:'recreation'}
						},
						{
							title: 'ON-ROAD',
							state: 'onroad',
							param_string: '.type',
							params: {type:'onroad'}
						},
						{
							title: 'OFF-ROAD',
							state: 'offroad',
							param_string: '.type',
							params: {type:'offroad'}
						},
						{
							title: 'LIST MY VEHICLE',
							state: 'listmyvehicle'
						}
					]
				},
				/*
				 {
				 title: 'SOLD',
				 state: 'sold',
				 show: true,
				 children: [
				 {
				 title: 'PERFORMANCE',
				 state: 'performance',
				 param_string: '.type',
				 params: {type:'performance'}
				 },
				 {
				 title: 'LEISURE & RECREATION',
				 state: 'recreation',
				 param_string: '.type',
				 params: {type:'recreation'}
				 },
				 {
				 title: 'ON-ROAD',
				 state: 'onroad',
				 param_string: '.type',
				 params: {type:'onroad'}
				 },
				 {
				 title: 'OFF-ROAD',
				 state: 'offroad',
				 param_string: '.type',
				 params: {type:'offroad'}
				 },
				 {
				 title: 'LIST MY VEHICLE',
				 state: 'listmyvehicle'
				 }
				 ]
				 },
				 */
				{
					title: 'MEDIA',
					state: 'media',
					show: true,
					children: [
						{
							title: 'VIDEOS',
							state: 'videos'
						}
					]
				},
				{
					title: 'CONTACT',
					state: 'contact',
					show: true,
					children: []
				}
			];

			$scope.is_logged_in = function(){
				return $auth.isAuthenticated()
			}

			$scope.go = function( state, params )
			{

				$scope.navCollapsed = true;

				state = state.split( '.' );

				$scope.parent = state[0];
				$scope.child = state[1];


				// Does the selected menu option have children?
				// For example: login, logout & register do not have children.
				// `Inventory` has multiple children (performance, recreation, on-road, etc).

				if( $scope.menus[ map[ $scope.parent ] ].children.length )
				{

					// STOP HERE!
					// children exist, and no child was selected.
					// do not proceed.
					if( typeof $scope.child === 'undefined' || $scope.child === null ){ return; }

					// check for child state params.
					for( var c in $scope.menus[ map[ $scope.parent ] ].children ){
						if( $scope.menus[ map[ $scope.parent ] ].children[ c ].state === $scope.child ){

							if( typeof $scope.menus[ map[ $scope.parent ] ].children[ c ].params !== 'object' ){ continue; }

							$state.go(
								( $scope.parent + $scope.menus[ map[ $scope.parent ] ].children[ c ].param_string ),
								$scope.menus[ map[ $scope.parent ] ].children[ c ].params
							);

							return;

						}
					}

					$state.go( [$scope.parent,$scope.child].join('.') );

				}
				else
				{

					// no children exist.
					$state.go( $scope.parent );

				}

			}

			$scope.close = function()
			{
				$scope.showSearch = false;
			}

			$scope.search = function()
			{
				// if mobile menu is open, close it when you perform search.
				if( !$scope.navCollapsed ){ $scope.navCollapsed = true; }

				SearchService.get( $scope.search_term )
					.then(function(){ $state.go( "search" ); $scope.close(); });
			}

			for( var m in $scope.menus ){
				map[ $scope.menus[ m ].state ] = m;
			}

			$scope.parent = $state.current.name.split('.')[0];

		}

		return {
			restrict : 'AE',
			scope : { brand : '=', menus : '=', affixed : '=', search : '=', searchfn : '&', navfn : '&', inverse : '=' },
			templateUrl : '/partials/frontnav',
			controller : [ '$scope', '$timeout', '$state', '$stateParams', '$auth', 'SearchService', controller ]
		};

	}
	AtlasApp.directive( 'frontNavbar',[ frontNavbar ]);

	function breadcrumbs(){

		function controller( $scope, $element, $attrs, QueryRequest ){

			function set()
			{
				var uri = QueryRequest.get( 'uri' );

				switch( String(uri[0]).toLowerCase() ){
					case "": case "home":
					$scope.main = "HOME";
					break;
					case "company":
						$scope.main = "COMPANY";
						break;
					case "inventory":
						$scope.main = "SHOP";
						break;
					case "sold":
						$scope.main = "SOLD";
						break;
					case "media":
						$scope.main = "MEDIA";
						break;
					case "contact":
						$scope.main = "CONTACT";
						break;
					case "search":
						$scope.main = "SEARCH";
						break;
					case "dashboard":
						$scope.main = "DASHBOARD";
						break;
					case "listings":
						$scope.main = "LISTINGS";
						break;
					case "events":
						$scope.main = "EVENTS";
						break;
					case "users":
						$scope.main = "USERS";
						break;
					case "wishlists":
						$scope.main = "WISHLISTS";
						break;
					case "newsletters":
						$scope.main = "NEWSLETTERS";
						break;
					case "wishlist":
						$scope.main = "MY WISHLIST";
						break;
					default:
						break;
				}
				switch( String(uri[1]).toLowerCase() ){

					case "team":
						$scope.sub = "TEAM";
						break;
					case "events":
						$scope.sub = "EVENTS";
						break;
					case "faq":
						$scope.sub = "FAQ";
						break;

					case "performance":
						$scope.sub = "PERFORMANCE";
						break;
					case "recreation":
						$scope.sub = "LEISURE & RECREATION";
						break;
					case "onroad":
						$scope.sub = "ON-ROAD";
						break;
					case "offroad":
						$scope.sub = "OFF-ROAD";
						break;
					case "listmyvehicle":
						$scope.sub = "LIST MY VEHICLE";
						break;

					case "videos":
						$scope.sub = "VIDEOS";
						break;

					case "all":
						switch( String(uri[0]).toLowerCase() ){
							case "listings":
								$scope.sub = "ALL LISTINGS";
								break;
							case "events":
								$scope.sub = "CALENDAR";
								break;
							case "users":
								$scope.sub = "ALL USERS";
								break;
							case "newsletters":
								$scope.sub = "ALL SUBSCRIBERS";
								break;
						}
						break;

					case "create":
						switch( String(uri[0]).toLowerCase() ){
							case "listings":
								$scope.sub = "CREATE NEW LISTING";
								break;
							case "events":
								$scope.sub = "CREATE NEW EVENT";
								break;
							case "users":
								$scope.sub = "CREATE NEW USER";
								break;
						}
						break;

					case "export":
						$scope.sub = "EXPORT SUBSCRIBERS";
						break;

					default:
						$scope.sub = "";
						break;
				}

			}

			$scope.$watch(function(){ return QueryRequest.get("uri"); },set,true);

		}

		return {
			restrict : 'E',
			scope : {},
			template : 	'<div class="container-fluid breadcrumbs-container"><div class="row-fluid"><div class="col-lg-10 col-lg-offset-1"><table width="100%"><tr><td nowrap class="font-face-oswald" ng-bind="main"></td><td><div class="slant-container"><div class="slant"></div></div></td><td class="font-face-oswald" ng-bind="sub"></td></tr></table></div></div></div>',
			controller : [ '$scope', '$element', '$attrs', 'QueryRequest', controller ]
		};
	}
	AtlasApp.directive( 'breadcrumbs',[ breadcrumbs ] );

	function carousel(){
		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/carousel'
		};
	}
	AtlasApp.directive( 'carousel',[ carousel ] );

	function vehicleTitleBar( VehicleService ){

		function controller( $scope, $element, $attrs, $state ){

			var set_title = function(){

				// Vehicle Title.
				if( $state.current.name.indexOf( "detail" ) > -1 ){

					if( !VehicleService.get().getChildren( 'meta' ) ){ return; }

					$scope.title = "";

					var type;

					var title = {
						year: '',
						make: '',
						model: ''
					}

					var meta = VehicleService.get().getChildren( 'meta' );

					for( var m in meta ){
						if( meta[ m ].attribs.meta_key === 'type' ){
							type = meta[ m ].attribs.meta_value;
						}
						if( title.hasOwnProperty( meta[ m ].attribs.meta_key ) ){
							title[ meta[ m ].attribs.meta_key ] = meta[ m ].attribs.meta_value;
						}
					}

					if( !isNaN( title.year ) && title.year > 1 ){
						$scope.title = title.year
					}

					$scope.title += " " + title.make;
					$scope.title += " " + title.model;


				}
				else

				// Vehicle Search Results.
				if( $state.current.name.indexOf( "search" ) > -1 ){

					$scope.title = "SEARCH RESULTS";
				}
				else

				// Vehicle Category Results
				if( $state.current.name.indexOf( "type" ) > -1 ){

					switch( $state.params.type.toLowerCase() ){
						case 'performance':
							$scope.title = "PERFORMANCE";
							break;
						case 'recreation':
							$scope.title = "LEISURE & RECREATION";
							break;
						case 'onroad':
							$scope.title = "ON-ROAD";
							break;
						case 'offroad':
							$scope.title = "OFF-ROAD";
							break;
						default:
							$scope.title = "";
							break;
					}

				}

				// List My Vehicle
				if( $state.current.name.indexOf( "list" ) > -1 ){

					$scope.title = "LIST MY VEHICLE";

				}

			}

			$scope.$watch(
				function(scope){ return { state: $state.current.name, vehicle: VehicleService.get(), params: $state.params } },
				function( nv,ov ){ set_title(); },
				true
			);

		}

		return {
			restrict : 'E',
			scope : { title: "=" },
			template : '<div class="vehicle-title-bar row-fluid bg-lt-gray"><div class="col-lg-10 col-lg-offset-1 font-white font-right" style="margin:5px;"><span class="font-40px font-face-sonic font-dk-gray" ng-bind="title"></span></div><div class="clearfix"></div></div>',
			controller : [ '$scope', '$element', '$attrs', '$state', controller ]

		};
	}
	AtlasApp.directive( 'vehicleTitleBar',[ 'VehicleService', vehicleTitleBar ] );

	function dashboardTitleBar( Formatter, localStorageService ){

		function controller( $scope, $element, $attrs, $state ){

			$scope.$watch(
				function(scope){ return $state.current.name; },
				function(nv,ov){
					if( typeof nv !== 'string' ){ return; }
					$scope.title = Formatter.ucwords( $state.current.name.replace( /\./g," - " ).replace( /\_/g," " ) );
					$scope.title = $scope.title.toLowerCase() === "wishlist" ? "My " + $scope.title : $scope.title ;
				},
				true
			);

		}

		return {
			restrict : 'E',
			scope : {},
			template : '<div class="dashboard-title-bar row-fluid bg-lt-gray"><div class="col-lg-10 col-lg-offset-1 font-white font-right" style="margin:5px;"><span class="font-40px font-face-sonic font-dk-gray" ng-bind="title"></span></div><div class="clearfix"></div></div>',
			controller : [ '$scope', '$element', '$attrs', '$state', controller ]
		};
	}
	AtlasApp.directive( 'dashboardTitleBar',[ 'Formatter', 'localStorageService', dashboardTitleBar ] );

	function homePocketContainer( SubscriberFactory, FormEntryService, $http, $timeout, Lightbox ){

		function controller( $scope, $element, $attrs ){

			// NEWSLETTER ------------------------- 

			$scope.FormEntryService = FormEntryService;

			// status of newsletter subscription
			// true = subscribed, false = failed attempt to subscribe.
			$scope.status = null;

			// server response message
			$scope.response = null;

			// Subscriber model.
			$scope.Subscriber = SubscriberFactory.create();

			// form was submitted.  Process request.
			$scope.submit = function(){

				$scope.$broadcast('validate-form',{
					callback:function( formControl ){

						if( formControl.$valid ){

							if( $scope.Subscriber.verify() !== true ){
								$scope.status = false;
								$scope.response = "Please enter your email address.";
								return;
							}

							$scope.Subscriber
								.save()
								.then(
									function(){

										$scope.status = true;
										$scope.response = "Thanks. You've been added to the Maxed Out Newsletter.";

										$scope.FormEntryService.form.name = 'newsletter';
										$scope.FormEntryService.model.email = $scope.Subscriber.attribs.email;
										$scope.FormEntryService.send();

									},
									function( msg ){
										$scope.status = false;
										$scope.response = msg;
									}
								);

						}

					},
					name: 'newsletter'
				});
			}



			// INSTAGRAM -------------------------- 
			$scope.imgObjs = [];

			var launchInstagram = function()
			{

				var feed = new Instafeed({
					get: 'user',
					userId: '21076243',
					accessToken: '21076243.1677ed0.866090c7ad7f4bc49792f13b7d4f74ee',
					resolution: 'thumbnail',
					sortBy: 'most-recent',
					target: '_data'
				});

				feed.run();

				$timeout(function(){ $scope.imgObjs = feed.get(); },500)

			}

			if( !$scope.imgObjs.length ){ launchInstagram(); }



			// INSTAGRAM LIGHTBOX ----------------- 

			// store images srcs
			$scope.imageLocations = [];

			// store active image index (used to instruct lightbox which image to view.)
			$scope.active_img_src = null;

			//close the modal window
			$scope.close = function(){
				Lightbox.closeModal();
			}

			// opens the lightbox modal
			$scope.openLightboxModal = function( index ) {
				$scope.active_img_src = index;
				if( !$scope.imageLocations.length ){
					try{
						for( var i in $scope.imgObjs ){
							if( !$scope.imgObjs[i].hasOwnProperty( 'images' ) ){ throw "Did Instagram change it up on us? ...   Again?"; }
							if( !$scope.imgObjs[i].images.hasOwnProperty( 'standard_resolution' ) ){ throw "Did Instagram change it up on us? ...   Again?"; }
							if( !$scope.imgObjs[i].images.standard_resolution.hasOwnProperty( 'url' ) ){ throw "Did Instagram change it up on us? ...   Again?"; }
							$scope.imageLocations.push( $scope.imgObjs[i].images.standard_resolution.url );
						}
					}
					catch(err){
						// uhhh, houston ... ? 
					}

					return;
				}
				Lightbox.openModal($scope.imageLocations, index, {scope: $scope});
			};

			// callback function for the lightbox
			// to chagne the active image.
			$scope.setImage = function( src ){
				$scope.active_img_src = src;
			}

			//push imgObjs to imageLocations on load so that openLightboxModal works on first click
			var init = function(){
				for( var i in $scope.imgObjs ){
					$scope.imageLocations.push( $scope.imgObjs[i].images.standard_resolution.url );
				}
			}

			$timeout(function(){ init(); }, 1000 );

		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/home/pocket',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'homePocketContainer',[ 'SubscriberFactory', 'FormEntryService', '$http', '$timeout', 'Lightbox', homePocketContainer ] );

	function homeInventorySearch( QueryRequest, VehicleMetaOptionsService ){

		function controller( $scope, $element, $attrs, $state ){

			$scope.VehicleMetaOptionsService = VehicleMetaOptionsService;

			$scope.search = {};

			// get all types, makes & models.
			$scope.find = function(){
				$scope.search.search = 1;
				$state.go( 'inventory.search', $scope.search );
			}

		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/home/search',
			controller : [ '$scope', '$element', '$attrs', '$state', controller ]
		};
	}
	AtlasApp.directive( 'homeInventorySearch',[ 'QueryRequest', 'VehicleMetaOptionsService', homeInventorySearch ] );

	function companyPocketContainer( FormEntryService ){

		function controller( $scope, $element, $attrs ){

			// Validate form & send.
			$scope.submit = function(){
				$scope.$broadcast('validate-form',{ callback:function( formControl ){ if( formControl.$valid ){ $scope.FormEntryService.send(); } }, name: 'faq' });
			}

			// Make form service available to pocket
			$scope.FormEntryService = FormEntryService;

		}

		return {
			restrict : 'E',
			scope : { page: "=" },
			templateUrl : '/partials/company/pocket',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'companyPocketContainer',[ 'FormEntryService', companyPocketContainer ] );

	function inventorySearchResult( Formatter, $timeout, $state, Preloader ){

		function controller( $scope, $element, $attrs, $state ){

			$scope.Formatter = Formatter;
			$scope.showImgs = false;
			$scope.meta = { year: '', type: '', make: '', model: '', price: '', description: '' }
			$scope.Image = null;
			$scope.filename = "placeholder.jpg";

			for( var child in $scope.vehicle.getChildren( 'meta' ) ){
				if( $scope.meta.hasOwnProperty( $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ) ){
					$scope.meta[ $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ] =
						$scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_value;
				}
			}

			for( child in $scope.vehicle.getChildren( 'image' ) ){
				if( parseInt( $scope.vehicle.getChildren( 'image' )[ child ].attribs.primary ) === 1 ){
					$scope.Image = $scope.vehicle.getChildren( 'image' )[ child ];
				}
			}

			var sizes;
			if( typeof $scope.Image !== 'undefined' && $scope.Image !== null ){
				if( $scope.Image.attribs.hasOwnProperty( 'attribs' ) ){
					sizes = $scope.Image.attribs.sizes ? JSON.parse($scope.Image.attribs.sizes) : null;
				}
			}

			var e,f;
			for( var s in sizes ){
				f = $scope.Image.attribs.filename.substr(0,$scope.Image.attribs.filename.lastIndexOf('.'));
				e = $scope.Image.attribs.filename.substr($scope.Image.attribs.filename.lastIndexOf('.')+1);
				$scope.Image.attribs[sizes[s]] = f + '.' + sizes[s] + '.' + e;
			}

			if( $scope.Image ){

				if( $scope.Image.attribs.hasOwnProperty('sm') ){
					$scope.filename = $scope.Image.attribs.sm;
				}else if( $scope.Image.attribs.hasOwnProperty('filename') ){
					$scope.filename = $scope.Image.attribs.filename;
				}else{
					console.log( 'no image found for thumbnail' );
				}

			}

			$scope.showImgs = true;

			$scope.go = function(){
				var state 	= 	$scope.listing + ".detail";
				var params 	= 	{
					type: $scope.meta.type.split( ' ' )[0].toLowerCase(),
					vehicle_id: $scope.vehicle.id()
				};
				$state.go( state, params );
			}

		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", listing: "@" },
			template : 		'<div class="col-xs-12 font-black bg-gray inner-shadow-well padding-0 search-result-set" ng-click="go()">'
			+		'<div class="search-result">'
			+			'<table width="100%" height="100%" class="hidden-xs">'
			+				'<tr>'
			+					'<td><div class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')"></div></td>'
			+					'<td valign="top">'
			+						'<div class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="meta.year"></span>&nbsp;'
			+								'<span ng-bind="meta.make"></span>&nbsp;'
			+								'<span ng-bind="meta.model"></span>'
			+							'</span>'
			+							'<div class="font-dk-gray font-face-play font-bold font-18px">'
			+								'<span>Price: <span ng-bind="Formatter.currency(meta.price,0)"></span></span>'
			+							'</div>'
			+							'<div class="font-black font-12px" style="margin-top:3px;">'
			+								'<span ng-bind="Formatter.wordwrap(meta.description,200) + \'...  \'"></span><span><strong>Read More</strong></span>'
			+							'</div>'
			+						'</div>'
			+					'</td>'
			+				'</tr>'
			+			'</table>'
			+			'<table width="100%" height="100%" class="hidden-sm hidden-md hidden-lg">'
			+				'<tr>'
			+					'<td><div class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')">&nbsp;</div></td>'
			+				'</tr>'
			+				'<tr>'
			+					'<td valign="top">'
			+						'<div class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="meta.year"></span>&nbsp;'
			+								'<span ng-bind="meta.make"></span>&nbsp;'
			+								'<span ng-bind="meta.model"></span>'
			+							'</span>'
			+							'<div class="font-dk-gray font-face-play font-bold font-18px">'
			+								'<span>Price: <span ng-bind="Formatter.currency(meta.price,0)"></span></span>'
			+							'</div>'
			+							'<div class="font-black font-12px" style="margin-top:3px;">'
			+								'<span ng-bind="Formatter.wordwrap(meta.description,200) + \'...  \'"></span><span><strong>Read More</strong></span>'
			+							'</div>'
			+						'</div>'
			+					'</td>'
			+				'</tr>'
			+			'</table>'
			+			'<div class="clearfix"></div>'
			+		'</div>'
			+		'<div class="clearfix"></div>'
			+	'</div>',
			controller : [ '$scope', '$element', '$attrs', '$state', controller ]
		}
	}
	AtlasApp.directive( 'inventorySearchResult',[ 'Formatter', '$timeout', '$state', inventorySearchResult ] );

	function inventoryPocketContainer( Formatter, FormEntryService, $timeout, $stateParams, $auth, $http, $state ){

		function controller( $scope, $element, $attrs ){

			$scope.response = {};

			$scope.Formatter = Formatter;

			$scope.FormEntryService = FormEntryService;

			$scope.$watch(function(){ return $scope.vehicle.id(); },function(nv,ov){ $scope.FormEntryService.model.vehicle_id = $scope.vehicle.id(); })

			$scope.submit = function(){
				$scope.$broadcast('validate-form',{ callback:function( formControl ){ if( formControl.$valid ){ $scope.FormEntryService.send(); } }, name: 'viewing' } );
			}

			var respond = function( msg,status ){

				$scope.response.status = !!status;
				$scope.response.message = msg;
				$scope.response.show = true;

				$timeout(function(){ $scope.response.show = false; }, 3000 );

				return status;
			}

			$scope.create = function(){

				// make sure there is a vehicle id. 
				// quit not if there isnt.
				var vehicle_id = $stateParams.hasOwnProperty( "vehicle_id" ) ? $stateParams.vehicle_id : false ;
				if( !vehicle_id ){ return; }

				// if user is not authenticated, 
				// we'll need them to log in 
				// so we have a user_id to attach 
				// the wishlist vehicle to.
				var is_auth = $auth.isAuthenticated();

				if( is_auth ){
					$http.post( "/api/wishlists",{ vehicle_id: vehicle_id } )
						.success(function(){
							return respond( "This vehicle has been added to your wishlist.",true );
						})
						.error(function(){
							return respond( "There was an error attempting to save this vehicle to your wishlist.",false );
						})
				}else{
					// redirect the user to the registration page.
					// somehow remember the vehicle id to create a 
					// wishlist item of.
					$state.go( "register.wishlist", { vehicle_id: vehicle_id } );

				}

			}

		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", vehicles: "=", view: "@" },
			templateUrl : '/partials/inventory/pocket',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'inventoryPocketContainer',[ 'Formatter', 'FormEntryService', '$timeout', '$stateParams', '$auth', '$http', '$state', inventoryPocketContainer ] );

	function soldPocketContainer( Formatter, FormEntryService, $timeout ){

		function controller( $scope, $element, $attrs ){
			$scope.Formatter = Formatter;
		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", vehicles: "=", view: "@" },
			templateUrl : '/partials/sold/pocket',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'soldPocketContainer',[ 'Formatter', 'FormEntryService', '$timeout', soldPocketContainer ] );

	function mediaPocketContainer(){
		return {
			restrict : 'E',
			scope : { selected: "=" },
			templateUrl : '/partials/media/pocket'
		};
	}
	AtlasApp.directive( 'mediaPocketContainer',[ mediaPocketContainer ] );

	function contactPocketContainer(){
		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/contact/pocket'
		};
	}
	AtlasApp.directive( 'contactPocketContainer',[ contactPocketContainer ] );

	function backendNavPocketContainer( localStorageService ){

		function controller( $state, $scope, $element, $attrs ){

			// object storing all menu options (labels, states & children)
			$scope.menus = {
				'staff':{
					'listings':{
						label: 'Listings',
						children: {
							'list_all':{ label: 'All Listings' },
							'create':{ label: 'Create' }
						}
					},
					'events': {
						label: 'Events',
						children: {
							'list_all':{ label: 'All Subscribers' },
							'create':{ label: 'Create' }
						}
					},
					'users': {
						label: 'Users',
						children: {
							'list_all':{ label: 'All Listings' },
							'create':{ label: 'Create' }
						}
					},
					'wishlists': {
						label: 'Wishlists'
					},
					'newsletters': {
						label: 'Newsletters',
						children: {
							'list_all':{ label: 'All Subscribers' },
							'export':{ label: 'Export' }
						}
					}
				},
				'user':{
					'wishlist': {
						label: 'My Wishlist',
						children: {
							'list_all':{ label: 'All Vehicles' },
							'create':{ label: 'Create' }
						}
					},
				}
			}

			// grab the user's role so we dispaly the correct menu.
			$scope.role = localStorageService.get( 'Profile' ).role;

			// track the parent & child state.
			$scope.parent = null;
			$scope.child = null;

			var is_wishlist = null;
			$scope.go = function( state ){

				// we handle ALL requests the same way, EXCEPT 
				// for `My Wishlist`
				if( state === "wishlist" ){

					if( is_wishlist ){
						is_wishlist = null;
						return;
					}

					$scope.parent = "wishlist";
					$scope.child = null;
					$state.go( "wishlist" );
					return;
				}

				is_wishlist = null;

				if( state === $scope.parent ){ return; }
				if( state === $state.current.name ){ return; }

				$scope.parent = state.split('.')[0];
				$scope.child = state.split('.')[1];

				var children_exist = false;
				for( var m in $scope.menus ){
					if( $scope.menus[ m ].hasOwnProperty( $scope.parent ) ){
						if( $scope.menus[ m ][ $scope.parent ].hasOwnProperty( 'children' ) ){
							children_exist = true;
						}
					}
				}

				if( children_exist && $scope.parent && $scope.child ){
					if( $scope.parent === "wishlist" ){ is_wishlist = true; }
					$state.go( [$scope.parent,$scope.child].join('.') );
				}else
				if( !children_exist ){
					$state.go( state );
				}

			}

			// set the parent & child based on the 
			// passed in state.
			$scope.parent = $state.current.name.split(".")[0];
			$scope.child = $state.current.name.split(".")[1];

		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/backend/nav',
			controller : [ '$state', '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'backendNavPocketContainer',[ 'localStorageService', backendNavPocketContainer ] );

	function gallery( PreLoader, Formatter ){

		function controller( $scope, $element, $attrs, Lightbox ){

			$scope.Formatter = Formatter;
			$scope.placeholder = "placeholder.jpg";

			// set the active / primary gallery image.
			$scope.active_img_src = "";
			$scope.active_img_index = 0;
			$scope.setImage = function( src ){
				$scope.active_img_src = src;
			}

			// track of the state of the loading images.
			$scope.isLoading = true;
			$scope.isSuccessful = false;
			$scope.percentLoaded = 0;

			// img src values to preload
			$scope.imageLocations = [];

			$scope.close = function(){
				Lightbox.closeModal();
			}

			var init = function(){

				$scope.imageLocations = [];
				$scope.active_img_src = "";
				$scope.active_img_index = 0;

				if( typeof $scope.vehicle !== 'object' ){ return; }
				if( typeof $scope.vehicle.getChildren !== 'function' ){ return; }

				for( var i in $scope.vehicle.getChildren('image') ){
					if( parseInt( $scope.vehicle.getChildren('image')[i].attribs.primary ) === 1 ){
						$scope.imageLocations.unshift( $scope.vehicle.getChildren('image')[i].attribs.filename );
					}else{
						$scope.imageLocations.push( $scope.vehicle.getChildren('image')[i].attribs.filename );
					}
				}

				if( $scope.imageLocations.length > 0 ){
					$scope.openLightboxModal = function (imageLocations, active_img_src) {
						Lightbox.openModal($.map($scope.imageLocations,function(a){ return "/assets/vehicles/" + a; }), imageLocations.indexOf(active_img_src), {scope: $scope});
					};
				}else{
					$scope.imageLocations.push( "placeholder.jpg" );
				}

				// Preload the images; then, update display when returned.
				PreLoader
					.preloadImages( $.map($scope.imageLocations,function(a){ return "/assets/vehicles/" + a; }) )
					.then(
						function handleResolve( imageLocations ) {

							// Loading was successful.
							$scope.isLoading = false;
							$scope.isSuccessful = true;
							$scope.setImage( $scope.imageLocations[$scope.active_img_index] );

						},
						function handleReject( imageLocation ) {

							// Loading failed on at least one image.
							$scope.isLoading = false;
							$scope.isSuccessful = false;

						},
						function handleNotify( event ) {

							$scope.percentLoaded = event.percent;

						}
					);

			}

			$scope.$watch( function( scope ){ return scope.vehicle; },init,true);
		}

		return {
			restrict : 'E',
			scope : { vehicle: "=" },
			templateUrl : '/partials/inventory/gallery',
			controller : [ '$scope', '$element', '$attrs', 'Lightbox', controller ]
		};

	}
	AtlasApp.directive( 'gallery',[ 'PreLoader', 'Formatter', gallery ] );

	function galleryThumbnailTray( Formatter ){

		function controller( $scope, $element, $attrs, $timeout, $interval ){

			$scope.Formatter = Formatter;

			// store required dom elements
			// so you dont have to find them repeatedly.
			var tray;
			var table;

			// store the table & tray's original css values.
			var orig_margin;
			var tray_width;
			var table_width;

			// store the slider's action variables (current modified margin, direction of movement & $interval promise)
			$scope.current_margin = null;
			var direction;
			var timer;

			// set a boolean flag for when the tray slider is loaded & ready for use
			var loaded = false;

			// set the distance (in pixels) the slide should move on each interval iteration.
			var distance = 4;

			// start moving the slider tray
			var start = function(){

				if( direction === 'right' ){

					$interval.cancel( timer );

					timer = $interval(function(){
						if( $scope.current_margin > ( '-' + ( table.width()-tray_width ) ) ){
							$scope.current_margin = $scope.current_margin - distance;
							tray.css( 'margin-left',$scope.current_margin );
						}
					},0);

				}else{

					$interval.cancel( timer );

					timer = $interval(function(){
						if( $scope.current_margin >= orig_margin ){ return; }
						$scope.current_margin = $scope.current_margin + distance;
						tray.css( 'margin-left',$scope.current_margin );
					},0);

				}

			}

			// stop moving the slider tray
			var stop = function(){
				$interval.cancel( timer );
			}

			// load a selected image.
			$scope.load = function( img_src ){
				$scope.updater( img_src );
			}

			var init = function(){
				// grab tray slider obj
				tray = $element.find( '.gallery-tray-slide' );

				// grab thumbnails table 
				table = tray.find( 'table' );

				// grab orig / default margin for tb tray slider.
				orig_margin = parseInt( tray.css('margin-left') );
				$scope.current_margin = orig_margin;

				// grab tray slider width
				tray_width = $element.find( '.gallery-tray-slide' ).width();

				// watch the action variable for changes.
				// this will instruct us what the user wants from the
				// slider.
				$scope.$watch(
					function( scope ){ return scope.action; },
					function( nv,ov ){
						if( !loaded ){ return; }
						if( nv === ov ){ return; }
						direction = nv[0];
						if( nv[1] === 'on' ){ start(); }else{ stop(); }
					},
					true);

				loaded = true;

				$scope.updater = $scope.$eval( $scope.updater );

			}

			// init slider.
			$timeout(function(){ init(); },1000);

		}

		return {
			scope: { imgs: "&", updater: "&" },
			restrict: 'E',
			template: 	'<div class="col-xs-12 gallery-tray">'
			+		'<div class="gallery-tray-left-arrow" ng-mouseover="action = [\'left\',\'on\']" ng-mouseleave="action = [\'left\',\'off\']">'
			+			'<span class="glyphicon glyphicon-chevron-left"></span>'
			+		'</div>'
			+		'<div class="gallery-tray-window">'
			+			'<div class="gallery-tray-slide">'
			+				'<table>'
			+					'<tr>'
			+						'<td ng-repeat="src in imgs()">'
			+							'<div class="tb" ng-click="load(src)" style="width:125px;" >'
			+								'<img src="/assets/vehicles/{{Formatter.imgToTB(src)}}" />'
			+							'</div>'
			+						'</td>'
			+					'</td>'
			+				'</table>'
			+			'</div>'
			+		'</div>'
			+		'<div class="gallery-tray-right-arrow" ng-mouseover="action = [\'right\',\'on\']" ng-mouseleave="action = [\'right\',\'off\']">'
			+			'<span class="glyphicon glyphicon-chevron-right"></span>'
			+		'</div>'
			+		'<div class="clearfix"></div>'
			+	'</div>',
			controller: [ '$scope', '$element', '$attrs', '$timeout', '$interval', controller ]
		}

	}
	AtlasApp.directive( 'galleryThumbnailTray',[ 'Formatter', galleryThumbnailTray ] );

	function galleryModalThumbnailTray( $timeout,$interval ){

		function controller( $scope, $element, $attrs, Lightbox ){

			// store required dom elements
			// so you dont have to find them repeatedly.
			var tray;
			var table;

			// store the table & tray's original css values.
			var orig_margin;
			var tray_width;
			var table_width;

			// store the slider's action variables (current modified margin, direction of movement & $interval promise)
			$scope.current_margin = null;
			var direction;
			var timer;

			// set a boolean flag for when the tray slider is loaded & ready for use
			var loaded = false;

			// set the distance (in pixels) the slide should move on each interval iteration.
			var distance = 4;

			// set the default folder for thumbnail images.
			$scope.tb_folder = "/assets/vehicles/";

			// start moving the slider tray
			var start = function(){
				if( direction === 'right' ){
					$interval.cancel( timer );

					timer = $interval(function(){
						if( $scope.current_margin > ( '-' + ( table.width()-tray_width ) ) ){
							$scope.current_margin = $scope.current_margin - distance;
							tray.css( 'margin-left',$scope.current_margin );
						}
					},0);

				}else{

					$interval.cancel( timer );

					timer = $interval(function(){
						if( $scope.current_margin >= orig_margin ){ return; }
						$scope.current_margin = $scope.current_margin + distance;
						tray.css( 'margin-left',$scope.current_margin );
					},0);

				}

			}

			// stop moving the slider tray
			var stop = function(){
				$interval.cancel( timer );
			}

			// load a selected image.
			$scope.load = function( img_src ){
				$scope.updater( img_src );
				Lightbox.setImage($scope.imgs().indexOf(img_src));
			}

			// setup watcher method for controller desctruction
			var watcher;

			var init = function(){
				// grab tray slider obj
				tray = $element.find( '.gallery-tray-slide' );

				// grab thumbnails table 
				table = tray.find( 'table' );

				// grab orig / default margin for tb tray slider.
				orig_margin = parseInt( tray.css('margin-left') );
				$scope.current_margin = orig_margin;

				// grab tray slider width
				tray_width = $element.find( '.gallery-tray-slide' ).width();

				//images from instagram have different aspect ratio, we need to change size of thumbnail windows, their AR is 1, and local images are 1.5
				//if AR is around 1.3 or lower we change the size of thumnail window
				if($element.find( 'img' ).width()/$element.find( 'img' ).height() < 1.3){
					$element.find( '.tb' ).css({
						'width': '89px',
						'max-height': '100px'
					});
				}

				// watch the action variable for changes.
				// this will instruct us what the user wants from the
				// slider.
				watcher = $scope.$watch(
					function( scope ){ return scope.action; },
					function( nv,ov ){
						if( !loaded ){ return; }
						if( nv === ov ){ return; }
						direction = nv[0];
						if( nv[1] === 'on' ){ start(); }else{ stop(); }
					},
					true);

				loaded = true;

				$scope.updater = $scope.$eval( $scope.updater );

			}

			// init slider.
			$timeout(function(){ init(); },200);

			// destroy watcher on controller destruction
			$scope.$on( "$destroy",watcher );

		}

		return {
			scope: { imgs: "&", updater: "&", localFolder: "@" },
			restrict: 'E',
			template:	'<div class="col-xs-12 gallery-modal-tray">'
			+		'<div class="gallery-tray-left-arrow" ng-mouseover="action = [\'left\',\'on\']" ng-mouseleave="action = [\'left\',\'off\']">'
			+			'<span class="glyphicon glyphicon-chevron-left"></span>'
			+		'</div>'
			+		'<div class="gallery-tray-window">'
			+			'<div class="gallery-tray-slide">'
			+				'<table>'
			+					'<tr>'
			+						'<td ng-repeat="src in imgs()">'
			+							'<div class="tb" ng-click="load(src)" style="width:125px;" >'
			+								'<img ng-src="{{ ( ( ( src.indexOf(\'www\') > -1 && src.indexOf(\'www\') < 2 ) || ( src.indexOf(\'http\') > -1 && src.indexOf(\'http\') < 2 ) || ( src.indexOf(\'//\') > -1 && src.indexOf(\'//\') < 2 ) ) ? src : tb_folder  + src )}}" />'
			+							'</div>'
			+						'</td>'
			+					'</td>'
			+				'</table>'
			+			'</div>'
			+		'</div>'
			+		'<div class="gallery-tray-right-arrow" ng-mouseover="action = [\'right\',\'on\']" ng-mouseleave="action = [\'right\',\'off\']">'
			+			'<span class="glyphicon glyphicon-chevron-right"></span>'
			+		'</div>'
			+		'<div class="clearfix"></div>'
			+	'</div>',
			controller: [ '$scope', '$element', '$attrs', 'Lightbox', controller ]

		}
	}
	AtlasApp.directive( 'galleryModalThumbnailTray',[ '$timeout', '$interval', galleryModalThumbnailTray ] );

	function listingsFiltersForm( Formatter ){

		function controller ( $scope, $element, $attrs ){

			// List all available filters.
			$scope.options = {
				active: {
					title: "Active Vehicles",
					field: "active",
					options: [
						{
							value: "all",
							title: "All Vehicles"
						},
						{
							value: "1",
							title: "All Active Vehicles"
						},
						{
							value: "0",
							title: "All Deleted Vehicles"
						}
					]
				},
				type: {
					title: "Vehicle Types",
					field: "type",
					options: [
						{
							value: "performance",
							title: "Performance"
						},
						{
							value: "recreation",
							title: "Leisure & Recreation"
						},
						{
							value: "onroad",
							title: "On-Road"
						},
						{
							value: "offroad",
							title: "Off-Road"
						}
					]
				},
				status: {
					title: "Vehicle Status",
					field: "status",
					options: [
						{
							value: "for_sale",
							title: "For Sale"
						},
						{
							value: "sold",
							title: "Sold"
						},
						{
							value: "draft",
							title: "Draft"
						},
						{
							value: "wishlist",
							title: "Wishlist"
						},
						{
							value: "list_my_vehicle",
							title: "List My Vehicle"
						}
					]
				},
				id: {
					title: "Vehicle ID",
					field: "id",
					options: false
				},
				year: {
					title: "Year",
					field: "year",
					options: false
				},
				make: {
					title: "Make",
					field: "make",
					options: false
				},
				model: {
					title: "Model",
					field: "model",
					options: false
				}
			};

			// Store listings search filters
			// this will be used to narrow the result
			// set of all listings 
			$scope.filters = {
				active: null,
				status: [],
				type: []
			}

			// Store the readable filters here.
			// These will be used to display active
			// filters on screen. 
			$scope.display = {}

			// Toggle a selected filter 
			// value on or off.
			$scope.toggle = function( field, value ){

				if( typeof $scope.filters[ field ] === 'object' && $scope.filters[ field ] !== null ){

					if( $scope.filters[ field ].indexOf( value ) > -1 ){

						var opts = []
						for( var f in $scope.filters[ field ] ){
							if( $scope.filters[ field ][ f ] !== value ){
								opts.push( $scope.filters[ field ][ f ] );
							}
						}

						$scope.filters[ field ] = opts;
					}else{
						$scope.filters[ field ].push( value );
					}

				}else{
					$scope.filters[ field ] = value;
				}

			}

			// Boolean flag, true if filters exist.
			// false if no filters exist.
			$scope.filters_active = true;

			//set default for_sale and active vehicles
			$scope.toggle('status', 'for_sale');
			$scope.toggle('active', '1');

			// Function
			// updates the on-screen display
			// of all active filter in filter model.
			var update_filter_display = function(){

				$scope.filters_active = false;

				$scope.display = {};

				for( var f in $scope.filters ){

					// if filter is empty, skip iteration.
					if( typeof $scope.filters[ f ] === 'undefined' || $scope.filters[ f ] === null ){ continue; }

					// are we displaying an obj / array?
					if( typeof $scope.filters[ f ] === 'object' ){

						var opts = [];

						for( var s in $scope.filters[ f ] ){

							for( var o in $scope.options[ f ].options ){
								if( $scope.options[ f ].options[ o ].value === $scope.filters[ f ][ s ] ){
									$scope.filters_active = true;
									opts.push(  {
										field: f,
										value: $scope.options[ f ].options[ o ].value,
										title: Formatter.ucwords( $scope.options[ f ].options[ o ].title )
									} );
								}
							}
						}

						if( opts.length ){
							$scope.display[ $scope.options[ f ].title ] = opts;
						}

						continue;
					}

					// are we displaying the vehicle 'Active' state?
					if( f === 'active' ){
						for( var i in $scope.options.active.options ){
							if( $scope.options.active.options[ i ].value === $scope.filters[ f ] ){
								$scope.filters_active = true;
								$scope.display[ Formatter.ucwords( $scope.options[ f ].title ) ] = [{
									field: f,
									value: $scope.filters[ f ],
									title: $scope.options.active.options[ i ].title
								}];
								continue;
							}
						}

						continue;
					}

					// format remaining filters & add to display array.
					$scope.display[ Formatter.ucwords( $scope.options[ f ].title ) ] = [{
						field: f,
						value: $scope.filters[ f ],
						title: $scope.filters[ f ]
					}];

					if( $scope.filters[ f ] !== null && typeof $scope.filters[ f ] !== 'undefined' && String( $scope.filters[ f ] ).length ){
						$scope.filters_active = true;
					}

				}

			}

			update_filter_display();

			// Remove selected filter from active 
			// filters / filter model ($scope.filters)
			$scope.remove = function( remove ){

				if( !$scope.filters.hasOwnProperty( remove.field ) ){ return; }

				if( typeof $scope.filters[ remove.field ] === 'object' ){

					var opts = [];

					for( var f in $scope.filters[ remove.field ] ){

						if( remove.value !== $scope.filters[ remove.field ][ f ] ){
							opts.push( $scope.filters[ remove.field ][ f ] );
						}

					}

					$scope.filters[ remove.field ] = opts;

					return;
				}

				$scope.filters[ remove.field ] = null;

			}

			// Resets all selected filters
			// back to default (None selected)
			$scope.reset = function(){
				$scope.filters = {
					active: null,
					status: [],
					type: []
				}
			}

			// Watch the filters model for changes.
			// Whenever the filters model changes,
			// we update the filter display.
			$scope.$watch(
				function( scope ){ return scope.filters; },
				function( nv,ov ){ if( nv === ov ){ return; } update_filter_display(); },
				true
			);

		}

		return {
			restrict : 'E',
			scope : { filters: "=" },
			template : 		'<form role="form" class="form-horizontal col-xs-12 bg-white filter-menu">'
			+			'<div class="col-xs-12">'
			+				'<h5 class="font-bold font-face-play pull-left title">Listing Filters:</h5>'
			+				'<div class="btn-group pull-right" uib-dropdown>'
			+					'<button type="button" class="btn btn-sm btn-default" uib-dropdown-toggle>'
			+						'Active Vehicles: <span class="caret"></span>'
			+					'</button>'
			+					'<ul uib-dropdown-menu role="menu">'
			+						'<li ng-repeat="option in options[ \'active\' ].options" role="menuitem" ng-click="toggle( \'active\',option.value )">'
			+							'<a href="#">'
			+								'<i ng-class="[ \'fa\', { \'fa-check\': filters.active.indexOf( option.value ) > -1 }]"></i>&nbsp;'
			+								'{{option.title}}'
			+							'</a>'
			+						'</li>'
			+					'</ul>'
			+				'</div>'
			+				'<div class="btn-group pull-right" uib-dropdown>'
			+					'<button type="button" class="btn btn-sm btn-default" uib-dropdown-toggle>'
			+						'Vehicle Type: <span class="caret"></span>'
			+					'</button>'
			+					'<ul uib-dropdown-menu role="menu">'
			+						'<li ng-repeat="option in options[ \'type\' ].options" role="menuitem" ng-click="toggle( \'type\',option.value )">'
			+							'<a href="#">'
			+								'<i ng-class="[ \'fa\', { \'fa-check\': filters.type.indexOf( option.value ) > -1 }]"></i>&nbsp;'
			+								'{{option.title}}'
			+							'</a>'
			+						'</li>'
			+					'</ul>'
			+				'</div>'
			+				'<div class="btn-group pull-right" uib-dropdown>'
			+					'<button type="button" class="btn btn-sm btn-default" uib-dropdown-toggle>'
			+						'Vehicle Status: <span class="caret"></span>'
			+					'</button>'
			+					'<ul uib-dropdown-menu role="menu">'
			+						'<li ng-repeat="option in options[ \'status\' ].options" role="menuitem" ng-click="toggle( \'status\',option.value )">'
			+							'<a href="#">'
			+								'<i ng-class="[ \'fa\', { \'fa-check\': filters.status.indexOf( option.value ) > -1 }]"></i>&nbsp;'
			+								'{{option.title}}'
			+							'</a>'
			+						'</li>'
			+					'</ul>'
			+				'</div>'
			+				'<div class="btn-group pull-right" uib-dropdown>'
			+					'<button ng-click="isCollapsed = !isCollapsed" type="button" class="btn btn-sm btn-default" uib-dropdown-toggle>'
			+						'<span ng-if="isCollapsed">Show Filter Details</span>'
			+						'<span ng-if="!isCollapsed">Hide Filter Details</span>'
			+					'</button>'
			+				'</div>'
			+				'<div class="clearfix"></div>'
			+				'<hr class="rule-dk-gray margin-0" uib-collapse="isCollapsed">'
			+			'</div>'
			+			'<div class="col-sm-5" uib-collapse="isCollapsed">'
			+				'<h5 class="font-bold font-face-play pull-left title">Active Filters:</h5><br />'
			+				'<table class="table table-condensed table-striped">'
			+					'<tbody>'
			+						'<tr ng-repeat="(row,filter) in display">'
			+							'<td class="font-left font-face-play">{{row}}:</td>'
			+							'<td class="font-right">'
			+								'<span class="filter-option cursor-pointer" ng-click="remove(filter[$index])" ng-repeat="f in filter">{{f.title}}<span ng-if="$index < (filter.length - 1) && filter.length > 1">,&nbsp;</span></span>'
			+							'</td>'
			+						'</tr>'
			+					'</tbody>'
			+				'</table>'
			+				'<button type="button" class="btn btn-sm btn-block btn-maxed font-12px font-bold font-face-play" ng-click="reset()" ng-if="filters_active">Reset Filters</button>'
			+				'<br />'
			+			'</div>'
			+			'<div class="col-sm-7" uib-collapse="isCollapsed">'
			+				'<div class="form-group form-group-sm font-face-play">'
			+					'<label class="col-sm-3 control-label">Vehicle ID:</label>'
			+					'<div class="col-sm-9">'
			+						'<input type="number" ng-model="filters.id" class="form-control" placeholder="Vehicle ID">'
			+					'</div>'
			+				'</div>'
			+				'<div class="form-group form-group-sm font-face-play">'
			+					'<label class="col-sm-3 control-label">Year:</label>'
			+					'<div class="col-sm-9">'
			+						'<input type="number" ng-model="filters.year" class="form-control" placeholder="Year">'
			+					'</div>'
			+				'</div>'
			+				'<div class="form-group form-group-sm font-face-play">'
			+					'<label class="col-sm-3 control-label">Make:</label>'
			+					'<div class="col-sm-9">'
			+						'<input type="text" ng-model="filters.make" class="form-control" placeholder="Make">'
			+					'</div>'
			+				'</div>'
			+				'<div class="form-group form-group-sm font-face-play">'
			+					'<label class="col-sm-3 control-label">Model:</label>'
			+					'<div class="col-sm-9">'
			+						'<input type="text" ng-model="filters.model" class="form-control" placeholder="Model">'
			+					'</div>'
			+				'</div>'
			+			'</div>'
			+		'</form>',
			controller: [ '$scope', '$element', '$attrs', controller ]
		};

	}
	AtlasApp.directive( 'listingsFiltersForm',[ 'Formatter', listingsFiltersForm ] );

	function listingsImageManager( Formatter, $timeout, $http, Upload ){

		function controller( $scope, $element, $attrs ){

			// Make Formatter available to the scope.
			$scope.Formatter = Formatter;

			$scope.crop = null;

			$scope.croppedDataUrl = {};

			$scope.croppedDataDimensions = {};

			$scope.primary = 0;

			$scope.images = [];

			$scope.dimensions = {};

			$scope.cropped = [];

			// ------------------------------------------------------------------------------------------

			// Private Methods ------------------------------------

			// remove image from array to be uploaded.
			var remove_image = function(index){

				var imgs = [];

				delete $scope.images[ index ];

				for( var i in $scope.images ){
					imgs.push( $scope.images[ i ] );
				}

				$scope.images = imgs;

			}


			// ------------------------------------------------------------------------------------------

			// event manager.
			$scope.image = function(action,index){

				switch( action ){

					case "update":

						// update dimension data on the form model.
						$scope.dform._images = $scope.images;

						break;

					case "crop":

						$scope.crop = index;

						break;

					case "remove":

						remove_image(index);

						break;

					case "primary":

						$scope.dform._primary_image_index = $scope.primary = index;

						break;

					case "accept":

						if( !isNaN( $scope.crop ) ){

							// store cropped image data (old & new dimensions)
							$scope.dimensions[ $scope.crop ] = $scope.croppedDataDimensions[ $scope.crop ];

							// add the image $index to the cropped array.
							if( $scope.cropped.indexOf( $scope.crop ) < 0 ){ $scope.cropped.push( $scope.crop ); }

							// delete the active image $index when we're done.
							$scope.crop = null;

							// update dimension data on the form model.
							$scope.dform._dimensions = $.map($scope.dimensions, function(value, index) { return [value]; });

							// set the hidden form field validity 
							// after checking images.
							$scope.dform.images_are_valid.$setValidity( 'required',$scope.cropped.length === $scope.images.length ); // false = ng-invalid-required
							$scope.dform.images_are_valid.$setTouched();

						}

						break;

				}

			}

		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", dform: "=" },
			templateUrl : 	'/partials/image_uploader',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'listingsImageManager',[ 'Formatter', '$timeout', '$http', 'Upload', listingsImageManager ] );

	function listingsImageCropper( Formatter, $timeout, $compile ){

		function link( $scope, $element, $attrs ){

			var resize = function(){
				$scope.cHeight = "100%";
				$scope.cWidth = "100%";
			}

			// watch the active crop id (images index)
			// resize the element whever the crop id
			// changes.
			$scope.$watch(
				function( scope ){ return scope.crop; 		},
				function( nv,ov ){
					if( nv === ov ){ return; }
					if( !isNaN( nv ) ){ resize(); }
				},
				true
			);

			// compile the template, required for proper functionality of img-crop directive
			$compile($element.contents())($scope);
		}

		return {
			restrict : 'E',
			template : 	'<div style="width:100%;height:200px;" ng-repeat="im in images" ng-show="crop === $index">'
			+		'<div class="img-border-10" style="height:{{cHeight}};width:{{cWidth}};">'
			+			'<img-crop result-image-format="image/jpeg" area-type="rectangle" image="im  | ngfDataUrl" result-image="croppedDataUrl[$index]" result-dimensions="croppedDataDimensions[$index]" aspect-ratio="1.777" area-init-size="80" ng-init="croppedDataUrl[$index]=\'\'"></img-crop>'
			+		'</div>'
			+	'</div>',
			link : link
		};
	}
	AtlasApp.directive( 'listingsImageCropper',[ 'Formatter', '$timeout', '$compile', listingsImageCropper ] );


	function listingsImageUpdateCropper( Formatter, $timeout, $compile ){

		function controller( $scope, $element, $attrs ){

			$scope.resizeCropBox = function(){
				$scope.filepath = "/assets/vehicles/" + $scope.Vehicle.getChild('image',$scope.photo.edit_photo_id).attribs.filename;
				$scope.cHeight = "100%";
				$scope.cWidth = "100%";
				$timeout(function(){
					$scope.cHeight = ( parseInt( $element.find( "canvas" ).height() ) + 20 ) + "px";
				},200);
			}

			$scope.accept = function(){
				$scope.photo.new_dimensions = $scope.croppedDimensions[ $scope.photo.edit_photo_id ];
				$scope.photo.preview_new_crop = true;
				$scope.photo.new_image = $scope.croppedImage[ $scope.photo.edit_photo_id ];
			}

			// compile the template, required for proper functionality of img-crop directive
			$compile($element.contents())($scope);
		}

		return {
			restrict : 'E',
			template : 	'<div style="width:100%;height:500px;">'
			+		'<div class="img-border-10" style="height:{{cHeight}};width:{{cWidth}};">'
			+			'<img-crop result-image-format="image/jpeg" area-type="rectangle" image="filepath" result-image="croppedImage[photo.edit_photo_id]" result-dimensions="croppedDimensions[photo.edit_photo_id]" aspect-ratio="1.777" area-init-size="80" ></img-crop>'
			+		'</div>'
			+	'</div>',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'listingsImageUpdateCropper',[ 'Formatter', '$timeout', '$compile', listingsImageUpdateCropper ] );

	function calendarPocketContainer( CalendarService, DateFactory ){

		function controller( $scope, $element, $attrs ){

			CalendarService.set_date( new Date() );

			$scope.CalendarService = CalendarService;

			$scope.close = function(){
				$scope.Event = null;
			}

			$scope.$watch(
				function(){ return CalendarService.event; },
				function(nv,ov){
					if( nv === ov ){ return; }
					nv.attribs.appt = typeof nv.attribs.appt === 'object' ? nv.attribs.appt : new Date( nv.attribs.appt );
					nv.attribs.date = ( String( ( nv.attribs.appt.getMonth() + 1 ) ).length === 1 ? "0" + ( nv.attribs.appt.getMonth() + 1 ) : ( nv.attribs.appt.getMonth() + 1 ) ) + "/" + nv.attribs.appt.getDate() + "/" + nv.attribs.appt.getFullYear();
					nv.attribs.time = ( nv.attribs.appt.getHours() > 12 ? nv.attribs.appt.getHours() - 12 : nv.attribs.appt.getHours() ) + ":"
						+ ( String(nv.attribs.appt.getMinutes()).length === 1 ? "0" + nv.attribs.appt.getMinutes()
							: nv.attribs.appt.getMinutes() ) + " "
						+ ( nv.attribs.appt.getHours() > 12 ? "PM" : "AM" );
					$scope.Event = nv;

				},
				true
			);

		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/calendar/pocket',
			controller : [ '$scope', '$element', '$attrs', controller ]
		}
	}
	AtlasApp.directive( 'calendarPocketContainer',[ 'CalendarService', 'DateFactory', calendarPocketContainer ] );

	function calendarDetailControls( CalendarService, DateFactory,Formatter,$timeout ){

		function controller( $scope, $element, $attrs ){

			// start the calendar on today's date.
			CalendarService.set_date( new Date() );

			// make the CalendarService available.
			$scope.CalendarService = CalendarService;

			// make the Formatter service available
			$scope.Formatter = Formatter;

			// store the state of the form
			// ie: loading, saving, saved, error
			$scope.forms = {}

			// remember which pop-up displays are open.
			$scope.open = {
				date: false,
				time: false,
			};

			// open the selected view, date or time.
			$scope.openCalendar = function(e, view) {
				$scope.open[view] = true;
			};

			// close the event view 
			$scope.close = function(){
				CalendarService.event = null;
				$scope.Event = null;
			}

			// view event details
			$scope.view = function( event_id ){

				var models = CalendarService.models[CalendarService.current_date.getDate()];

				for( var m in models ){

					if( event_id === models[m].id() ){
						CalendarService.event = models[m];
						var dt = new DateFactory();
						dt.setDate( CalendarService.event.attribs.appt );
						dt.setTime( CalendarService.event.attribs.appt );
						CalendarService.event.attribs.date = dt.getDate("M/D/Y");
						CalendarService.event.attribs.time = dt.getTime();
					}
				}

			}

			// keep a list of month names available for the view.
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

			$scope.edit = function(){
				if(!$scope.forms.hasOwnProperty('event')){ $scope.forms.event = {}; }
				$scope.forms.event.edit = true;
			}

			$scope.save = function(){
				$scope.forms.event.edit = null;
			}

			// Save the updates to the corresponding
			// form.  Forms will only make http call
			// if changes actually occur to the model.
			$scope.save = function( form ){

				// Verify form before proceeding.
				if( $scope.create_event ){
					if( $scope.create_event.$invalid ){ return; }
				}else if( $scope.edit_event ){
					if( $scope.edit_event.$invalid ){ return; }
				}else{ return; }

				// Reset the form.
				$scope.forms.event.edit = null;

				// Turn on loading.
				$scope.forms.event.loading = true;
				$scope.forms.event.message = "Saving...";

				// Verify model.
				$timeout(function(){
					CalendarService.event.save().then(function(){
						// refresh the calendar models.
						// this makes sure the
						CalendarService.refresh(true);
						var dt = new DateFactory();
						dt.setDate( CalendarService.event.attribs.appt );
						dt.setTime( CalendarService.event.attribs.appt );
						CalendarService.event.attribs.date = dt.getDate("M/D/Y");
						CalendarService.event.attribs.time = dt.getTime();

						$scope.forms.event.loading = false;
						$scope.forms.event.status = true;
						$scope.forms.event.message = "Saved.";
						$timeout(function(){
							$scope.close();
							$scope.forms.event.status = null;
							$scope.forms.event.message = null;
						},2000);
					},function(payload){
						$scope.forms.event.loading = false;
						$scope.forms.event.status = false;
						$scope.forms.event.message = "Not Saved.";
						$timeout(function(){
							$scope.forms.event.status = null;
							$scope.forms.event.message = null;
						},2000);
					});
				},1000)
			}

			// $timeout(function(){ $scope.view( 1017 ); },1000)

			$scope.show_clock = function(e) {
				e.preventDefault();
				e.stopPropagation();
				console.log( !$scope.clockOpen );
				$scope.clockOpen = !$scope.clockOpen;
			};

			// sets up the Events detail list view
			// for the currently selected date.
			var load_evt_detail_list = function( current_date ){

				// is the currently selected date today?
				var is_today = (new DateFactory()).sameDate( new Date( current_date ) );

				// set label for events detail list.
				$scope.evt_list_title = is_today
					? "Today's Events"
					: months[current_date.getMonth()]+' '+current_date.getDate()+(new DateFactory()).getOrdinal( current_date )+'\'s Events';

			}

			// load the event when user select a calendar Event 
			var load_evt = function(nv,ov){

				if( nv === ov ){ return; }
				if( typeof nv === 'undefined' || nv === null ){ return; }

				// get appt date / time as object.
				nv.attribs.appt = typeof nv.attribs.appt === 'object' ? nv.attribs.appt : new Date( nv.attribs.appt );

				// pull user-readable date from object.
				nv.attribs.date = ( String( ( nv.attribs.appt.getMonth() + 1 ) ).length === 1
						? "0" + ( nv.attribs.appt.getMonth() + 1 ) : ( nv.attribs.appt.getMonth() + 1 ) )
					+ "/" + nv.attribs.appt.getDate() + "/" + nv.attribs.appt.getFullYear();

				// pull user-readable time from object.
				nv.attribs.time = ( nv.attribs.appt.getHours() > 12 ? nv.attribs.appt.getHours() - 12 : nv.attribs.appt.getHours() ) + ":"
					+ ( String(nv.attribs.appt.getMinutes()).length === 1 ? "0" + nv.attribs.appt.getMinutes()
						: nv.attribs.appt.getMinutes() ) + " "
					+ ( nv.attribs.appt.getHours() > 12 ? "PM" : "AM" );

				$scope.Event = nv;

			}

			// load the events for the current date to
			// be displayed in the day-view event list.
			$scope.$watch("CalendarService.current_date",load_evt_detail_list)

			// was an event selected?
			$scope.$watch("CalendarService.event",load_evt);

		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/events/control',
			controller: [ '$scope', '$element', '$attrs', controller ]
		}
	}
	AtlasApp.directive( 'calendarDetailControls',[ 'CalendarService', 'DateFactory', 'Formatter', '$timeout', calendarDetailControls ] );

	function calendarView( CalendarService, localStorageService, ProfileFactory ){

		function controller( $scope, $element, $attrs, $auth ){
			$scope.CalendarService = CalendarService;
		}

		return {
			restrict : 'E',
			scope : {},
			templateUrl : '/partials/company/calendar',
			controller : [ '$scope', '$element', '$attrs', '$auth', controller ]
		};
	}
	AtlasApp.directive( 'calendarView',[ 'CalendarService', 'localStorageService', 'ProfileFactory', calendarView ] );

	function wishlistSearchResult( Formatter, $timeout, $state ){

		function controller( $scope, $element, $attrs ){

			$scope.Formatter = Formatter;
			$scope.showImgs = false;
			$scope.meta = { year: '', type: '', make: '', model: '', price: '', description: '' }
			$scope.Image = null;
			$scope.filename = "placeholder.jpg";

			$scope.placeholder = "placeholder.jpg";

			for( var child in $scope.vehicle.getChildren( 'meta' ) ){
				if( $scope.meta.hasOwnProperty( $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ) ){
					$scope.meta[ $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ] =
						$scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_value;
				}
			}

			for( child in $scope.vehicle.getChildren( 'image' ) ){
				if( !!$scope.vehicle.getChildren( 'image' )[ child ].attribs.primary ){ $scope.Image = $scope.vehicle.getChildren( 'image' )[ child ]; }
			}

			var sizes;
			if( typeof $scope.Image !== 'undefined' && $scope.Image !== null ){
				if( $scope.Image.hasOwnProperty( 'attribs' ) ){
					sizes = $scope.Image.attribs.sizes ? JSON.parse($scope.Image.attribs.sizes) : null;
				}
			}

			var e,f;
			for( var s in sizes ){
				f = $scope.Image.attribs.filename.substr(0,$scope.Image.attribs.filename.lastIndexOf('.'));
				e = $scope.Image.attribs.filename.substr($scope.Image.attribs.filename.lastIndexOf('.')+1);
				$scope.Image.attribs[sizes[s]] = f + '.' + sizes[s] + '.' + e;
			}

			if( $scope.Image ){

				if( $scope.Image.attribs.hasOwnProperty('sm') ){
					$scope.filename = $scope.Image.attribs.sm;
				}else if( $scope.Image.attribs.hasOwnProperty('filename') ){
					$scope.filename = $scope.Image.attribs.filename;
				}else{
					console.log( 'no image found for thumbnail' );
				}

			}

			$scope.showImgs = true;

			$scope.go = function(){
				var state 	= 	$scope.listing + ".detail";
				var params 	= 	{
					type: $scope.meta.type.split( ' ' )[0].toLowerCase(),
					vehicle_id: $scope.vehicle.id()
				};
				$state.go( state, params );
			}

		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", listing: "@" },
			template: 		'<div class="col-xs-12 font-black bg-white inner-shadow-well padding-0 search-result-set" ng-click="go()">'
			+			'<div class="search-result">'
			+				'<table width="100%" height="100%" class="hidden-xs">'
			+					'<tr>'
			+						'<td><div class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')"></div></td>'
			+						'<td valign="top">'
			+							'<div class="padding-10">'
			+								'<span class="font-black font-face-play font-bold font-24px">'
			+									'<span ng-bind="meta.year"></span>&nbsp;'
			+									'<span ng-bind="meta.make"></span>&nbsp;'
			+									'<span ng-bind="meta.model"></span>'
			+								'</span>'
			+								'<div class="font-black font-12px" style="margin-top:3px;">'
			+									'<span><strong>Added On: </strong><span ng-bind="Formatter.date_user( vehicle.attribs.created_at )"></span></span>'
			+								'</div>'
			+								'<div class="font-black font-12px" style="margin-top:3px;">'
			+									'<span><strong>Desired Price: </strong><span ng-bind="Formatter.currency( meta.price )"></span></span>'
			+								'</div>'
			+							'</div>'
			+						'</td>'
			+					'</tr>'
			+				'</table>'
			+				'<table width="100%" height="100%" class="hidden-sm hidden-md hidden-lg">'
			+					'<tr>'
			+						'<td><div class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')">&nbsp;</div></td>'
			+					'</tr>'
			+					'<tr>'
			+						'<td valign="top">'
			+							'<div class="padding-10">'
			+								'<span class="font-black font-face-play font-bold font-24px">'
			+									'<span ng-bind="meta.year"></span>&nbsp;'
			+									'<span ng-bind="meta.make"></span>&nbsp;'
			+									'<span ng-bind="meta.model"></span>'
			+								'</span>'
			+								'<div class="font-black font-12px" style="margin-top:3px;">'
			+									'<span>Added On: <span ng-bind="Formatter.date_user( vehicle.attribs.created_at )"></span></span>'
			+								'</div>'
			+								'<div class="font-black font-12px" style="margin-top:3px;">'
			+									'<span><strong>Desired Price: </strong><span ng-bind="Formatter.currency( meta.price )"></span></span>'
			+								'</div>'
			+							'</div>'
			+						'</td>'
			+					'</tr>'
			+				'</table>'
			+				'<div class="clearfix"></div>'
			+			'</div>'
			+			'<div class="clearfix"></div>'
			+		'</div>',
			controller : [ '$scope', '$element', '$attrs', controller ]
		};
	}
	AtlasApp.directive( 'wishlistSearchResult',[ 'Formatter', '$timeout', '$state', wishlistSearchResult ] );

	function contactMap( $http, QueryRequest ){
		return {
			scope 			:	{},
			restrict		: 	'E',
			replace			: 	true,
			template		: 	'<ng-map center="[ 34.506845, -114.3565837 ]"><marker position="[ 34.506845,-114.3565837 ]"></marker></ng-map>'

		}
	}
	AtlasApp.directive( 'contactMap',[ '$http', 'QueryRequest', contactMap ] );

	function vimeoVideoPlayer(){
		return {
			restrict: 'EA',
			replace: true,
			scope: {
				videoId: '=',
				videoUrl: '=',
				playerOpts: '=',
				playerHeight: '=',
				playerWidth: '=',
				playerId: '='
			},
			templateUrl: '/partials/media/video',
			link: function (scope, element, attrs, ctrl) {
				var options = {
					id: scope.videoId,
					width: scope.playerWidth,
					loop: false
				};

				scope.isMobile = jQuery.browser.mobile;

				// instantiate vimeo players
				var player1 = new Vimeo.Player(element[0].querySelector('.vimeoPlayer'), options);

				scope.playVideo = function(){
					player1.play();
				};

				scope.pauseVideo = function(){
					player1.pause();
				};

				scope.stopVideo = function(){
					player1.loadVideo(options.id);
				};

				// watch for videoId change, if it changes, that means user clicked on different video, which should be loaded in player
				scope.$watch("videoId",function(newValue,oldValue) {
					//This gets called when data changes
					options.id = scope.videoId;

					player1.loadVideo(options.id).then(function(id) {
						// the video successfully loaded, play the mobile or desktop video
						if(!scope.isMobile){
							// load the player from desktop iframe
							var loadIframePlayer1 = new Vimeo.Player(document.querySelector('#video1 iframe'));
							loadIframePlayer1.play();
						}else{
							// load the player from mobile iframe
							var loadIframePlayer2 = new Vimeo.Player(document.querySelector('#video2 iframe'));
							loadIframePlayer2.play();
						}

					}).catch(function(error) {
						// what happens if video can not be loaded
					});
				});
			}
		};
	}
	AtlasApp.directive( 'vimeoVideoPlayer', [ vimeoVideoPlayer ] );

	function vimeoVideoList( $q, vimeoFactory ){

		function link( $scope, elem, attrs ){

			$scope.videos = [];
			$scope.next = null;
			$scope.prev = null;

			// tb_size options = 0-6
			$scope.tb_size = 1;

			$scope.settings = {
				user: "maxedoutmarine",
				per_page: "5", // (optional) valid values: 1-50 | default: 25
				//page: pageNum, // (optional)
				//query:"<QUERY>", // (optional)
				//filter:"<FILTER>", // (optional)
				//filter_embeddable:"<FILTER_EMBEDDABLE>", // (optional)
				//sort:"<SORT>", // (optional)
				//direction:"<DIRECTION>", // (optional)
				access_token: "520aee2773b76c4a77cfc3df5246aeaf"
			};

			var fetch = function()
			{

				var deferred = $q.defer();

				vimeoFactory.getVideosFromUser( $scope.settings )
					.success(function(payload){
						// do videos exist in payload?
						if( payload.total < 1 ){
							deferred.reject( 'Incomplete video list.' );
							return deferred.promise;
						}

						// clear out any previously set videos.
						$scope.videos = [];

						// store video snippet info in payload
						for( var i in payload.data ){
							var item = {
								video_id: payload.data[i].link.split(/video\/|https?:\/\/vimeo\.com\//)[1].split(/[?&]/)[0],
								title: payload.data[i].name,
								thumbnails: payload.data[i].pictures
							};
							$scope.videos.push(item);
						}

						$scope.next = payload.paging.next;
						$scope.prev = payload.paging.previous;

						// has an initial video been selected?
						// if not, load the first available video.
						if( $scope.vid === null ){ $scope.load( 0, true ); }

						deferred.resolve();

					})
					.error(function(){
						deferred.reject( 'Failed to retrieve video list.' );
					});

				return deferred.promise;

			}

			function getJsonFromUrl( url ) {
				var query = url;
				var result = {};
				query.split("&").forEach(function(part) {
					var item = part.split("=");
					result[item[0]] = decodeURIComponent(item[1]);
				});
				return result;
			}

			// load the prev or next page of videos.
			$scope.get = function( url ){
				// get per_page and pageNum
				var get_params = getJsonFromUrl( url );

				$scope.settings.page = get_params.page;
				$scope.settings.per_page = get_params.per_page;

				fetch();
			};

			// load the selected video
			$scope.load = function( video_index ){
				$scope.vid = $scope.videos[ video_index ];
			};

			fetch();

		}

		return {
			scope 			:	{ vid: "=" },
			restrict		: 	'E',
			replace			: 	true,
			templateUrl		: 	'/partials/media/videoslist',
			link			: 	link
		}

	}
	AtlasApp.directive( 'vimeoVideoList',[ '$q', 'vimeoFactory', vimeoVideoList ] );

	function formFieldValidation(){

		function link( scope, elem, attrs, ctrl ){

			scope.$on('validate-form-field', function() {

				var invalid;
				var pattern;

				// is the field required?
				if( attrs.hasOwnProperty( 'required' ) ){
					invalid = ( ctrl.$modelValue === null || typeof ctrl.$modelValue === 'undefined' || String(ctrl.$modelValue).length === 0 );
					ctrl.$setValidity( 'required',!invalid ); // false = ng-invalid-required
					ctrl.$setTouched();

					// If the field is required, and it failed, 
					// stop here.  No need to validate other tests.
					if( invalid ){ return; }

				}


				// does the field have a pattern regex?
				// we only test it if it has a value.
				if( attrs.hasOwnProperty( 'pattern' ) && ( ctrl.$modelValue !== null && typeof ctrl.$modelValue !== 'undefined' ) ){
					pattern = new RegExp( attrs.pattern );
					invalid = !pattern.test(ctrl.$modelValue);
					ctrl.$setValidity( 'pattern',!invalid );
					ctrl.$setTouched();
				}else{
					ctrl.$setTouched();
				}

				// should we run any custom validation measures?
				if( attrs.hasOwnProperty( "formFieldValidation" ) ){

					switch( attrs.formFieldValidation.toLowerCase() ){

						// make sure the model is a JS date object
						case "date_obj":
							ctrl.$setValidity( 'pattern',attrs.hasOwnProperty( 'required' ) ? ctrl.$modelValue instanceof Date : ( typeof ctrl.$modelValue === 'undefined' || ctrl.$modelValue instanceof Date ) );
							ctrl.$setTouched();
							break;


					}

				}

			});

		}

		return {
			require: 'ngModel',
			link: [ 'scope', 'elem', 'attrs', 'ctrl', link ]
		}
	}
	AtlasApp.directive( 'formFieldValidation',[ formFieldValidation ] );

	function formValidation( $timeout ){

		function link( scope, element, attr, formControl ){

			// when the 'validate-form' event is called, 
			// check any individual inputs w/ the form-field-validation 
			// attr for their validity.

			// We take this extra step because it ensures
			// that the user is prompted w/ any invalid
			// fields that they never focused on, or 'touched'
			scope.$on('validate-form', function( evt, args ) {

				// are we checking a specific form? 
				// see if a form name was passed in.
				if( args.hasOwnProperty( 'name' ) ){

					// if the validting form name doesnt match the 
					// name passed in, it's not the form we're 
					// trying to validate.
					if( args.name !== attr.name ){ return; }
				}

				// broadcast event to check individual 
				// form fields.
				scope.$broadcast('validate-form-field');

				// return response if callback exists.
				if( args.hasOwnProperty( 'callback' ) ){
					args.callback( formControl );
				}

			})

		}

		return {
			restrict: 'A',
			require: '^form',
			link: link
		}

	}
	AtlasApp.directive( 'formValidation', [ '$timeout', formValidation ] );

	function frequentlyAskedQuestions( FAQService ){

		function controller( $scope, $element, $attrs ){

			// Get all frequently asked questions.
			$scope.FAQs = [];
			FAQService.search().then(function(){$scope.FAQs = FAQService.all();})
		}

		return {
			scope: { imgs: "&", updater: "&" },
			restrict: 'E',
			template: 		'<uib-accordion close-others="\'true\'">'
			+			'<uib-accordion-group is-open="open" ng-repeat="faq in FAQs">'
			+				'<uib-accordion-heading>'
			+					'<span class="font-face-play">{{faq.attribs.question}}</span><i class="pull-right glyphicon" ng-class="{\'glyphicon-chevron-down\': open[$index], \'glyphicon-chevron-right\': !open[$index]}"></i>'
			+				'</uib-accordion-heading>'
			+				'{{faq.attribs.answer}}'
			+			'</uib-accordion-group>'
			+		'</uib-accordion>',
			controller: [ '$scope', '$element', '$attrs', controller ]
		}

	}
	AtlasApp.directive( 'frequentlyAskedQuestions',[ 'FAQService', frequentlyAskedQuestions ] );

	function searchResultItem( Formatter, DateFactory, $timeout, $state ){

		function controller( $scope, $element, $attrs ){

			$scope.Formatter = Formatter;

			$scope.filename = "placeholder.jpg";

			var init = function(){

				switch( $scope.listing ){

					// prep search item for vehicle
					case "vehicle":

						$scope.details = { year: '', type: '', make: '', model: '', price: '', description: '' }

						for( var child in $scope.vehicle.getChildren( 'meta' ) ){
							if( $scope.details.hasOwnProperty( $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ) ){
								$scope.details[ $scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_key ] =
									$scope.vehicle.getChildren( 'meta' )[ child ].attribs.meta_value;
							}
						}

						$scope.details.label = $scope.vehicle.attribs.label;

						for( child in $scope.vehicle.getChildren( 'image' ) ){
							if( !!$scope.vehicle.getChildren( 'image' )[ child ].attribs.primary ){
								$scope.Image = $scope.vehicle.getChildren( 'image' )[ child ];
							}
						}

						if( $scope.Image ){

							if( $scope.Image.attribs.hasOwnProperty('sm') ){
								$scope.filename = $scope.Image.attribs.sm;
							}else if( $scope.Image.attribs.hasOwnProperty('filename') ){
								$scope.filename = $scope.Image.attribs.filename;
							}else{
								console.log( 'no image found for thumbnail' );
							}

						}


						break;

					// prep search item for event
					case "event":

						$scope.details = { date: '', time: '', label: '', all_day: '' }

						$scope.details.label = $scope.event.attribs.label;
						$scope.details.all_day = $scope.event.attribs.all_day === 1;

						// set date & time.
						var appt = new Date( $scope.event.attribs.appt );
						$scope.details.date = ( (new DateFactory()).setDate( appt ) ).getDate( "M/D/Y" );
						$scope.details.time = ( (new DateFactory()).setTime( appt ) ).getTime();

						var months = [ 'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec' ];

						$scope.display_date = months[ (appt).getMonth() ] + ", " + (appt).getDate() + (new DateFactory()).getOrdinal(appt);

						break;

					default: return;

				}

			}

			init();

			$scope.go = function(){

				if( !$scope.listing ){ return; }

				switch( $scope.vehicle.attribs.status ){
					case "for_sale":
						$scope.listing = "inventory";
						break;
					case "sold":
						$scope.listing = "sold";
						break;
					default: return;

				}

				var state 	= 	$scope.listing + ".detail";
				var params 	= 	{
					type: $scope.details.type,
					vehicle_id: $scope.vehicle.id()
				};
				$state.go( state, params );

			}

		}

		return {
			restrict : 'E',
			scope : { vehicle: "=", event: "=", listing: "@" },
			template : '<div class="col-xs-12 font-black bg-white padding-0 search-result-set" ng-click="go()">'
			+		'<div class="search-result">'
			+			'<table width="100%" height="100%" class="hidden-xs">'
			+				'<tr>'
			+					'<td class="preview-background">'
			+						'<div ng-if="listing == \'vehicle\'" class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')"></div>'
			+						'<div ng-if="listing == \'event\'" class="font-center font-face-play font-28px font-bold font-white">{{display_date}}</div>'
			+					'</td>'
			+					'<td valign="top">'
			+						'<div ng-if="listing == \'vehicle\'" class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="details.label"></span>'
			+							'</span>'
			+							'<br />'
			+							'<span class="font-black font-face-play font-16px">'
			+								'<span ng-bind="details.year"></span>&nbsp;'
			+								'<span ng-bind="details.make"></span>&nbsp;'
			+								'<span ng-bind="details.model"></span>'
			+							'</span>'
			+						'</div>'
			+						'<div ng-if="listing == \'event\'" class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="details.label"></span>'
			+							'</span>'
			+							'<br />'
			+							'<span class="font-black font-face-play font-16px">'
			+								'<span ng-bind="details.date"></span>&nbsp;'
			+								'<span ng-bind="details.all_day ? \'All Day\' : details.time"></span>&nbsp;'
			+							'</span>'
			+						'</div>'
			+					'</td>'
			+				'</tr>'
			+			'</table>'
			+			'<table ng-if="listing == \'vehicle\'" width="100%" height="100%" class="hidden-sm hidden-md hidden-lg">'
			+				'<tr>'
			+					'<td><div class="img-cell" style="background-image: url(\'/assets/vehicles/{{filename}}\')">&nbsp;</div></td>'
			+				'</tr>'
			+				'<tr>'
			+					'<td valign="top">'
			+						'<div class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="details.label"></span>'
			+							'</span>'
			+							'<br />'
			+							'<span class="font-black font-face-play font-16px">'
			+								'<span ng-bind="details.year"></span>&nbsp;'
			+								'<span ng-bind="details.make"></span>&nbsp;'
			+								'<span ng-bind="details.model"></span>'
			+							'</span>'
			+						'</div>'
			+					'</td>'
			+				'</tr>'
			+			'</table>'
			+			'<table ng-if="listing == \'event\'" width="100%" height="100%" class="hidden-sm hidden-md hidden-lg">'
			+				'<tr>'
			+					'<td class="preview-background"><div class="font-center font-face-play font-28px font-bold font-white">{{display_date}}</div></td>'
			+				'</tr>'
			+				'<tr>'
			+					'<td valign="top">'
			+						'<div class="padding-10">'
			+							'<span class="font-black font-face-play font-bold font-24px">'
			+								'<span ng-bind="details.label"></span>'
			+							'</span>'
			+							'<br />'
			+							'<span class="font-black font-face-play font-16px">'
			+								'<span ng-bind="details.date"></span>&nbsp;'
			+								'<span ng-bind="details.all_day ? \'All Day\' : details.time"></span>&nbsp;'
			+							'</span>'
			+						'</div>'
			+					'</td>'
			+				'</tr>'
			+			'</table>'
			+			'<div class="clearfix"></div>'
			+		'</div>'
			+		'<div class="clearfix"></div>'
			+	'</div>',
			controller : [ '$scope', '$element', '$attrs', controller ]

		};

	}
	AtlasApp.directive( 'searchResultItem',[ 'Formatter', 'DateFactory', '$timeout', '$state', searchResultItem ] );

})();
(function() {

    'use strict';

    var AtlasApp = angular.module('AtlasApp');


	/* ==================================================================================== */
	// -   CONTROLLERS   ------------------------------------------------------------------
	/* ==================================================================================== */	

	function CarouselController( $scope ){

		$scope.myInterval = 4000;
		$scope.noWrapSlides = false;
		$scope.active = 0;
		var slides = $scope.slides = [],
		    currIndex = 0,
		    homeSlides = [
			{id: 7, link: "https://cognitomotorsports.com"},
                        {id: 12, link: "https://www.americanforcewheels.com"},
                        {id: 13, link: "https://www.tommygunimages.com"},
                        {id: 9, link: "https://www.riverdavesplace.com"},
                        {id: 14, link: "http://www.genepricemotorsports.com"}
		], newWidth, h;

		for(h in homeSlides)
		{
			newWidth = 600 + slides.length + 1;
                        slides.push({
                                image: '/assets/imgs/home.slider.' + homeSlides[h].id + '.jpg',
                                text: ['','','','','',''][slides.length % 6],
                                id: currIndex++,
				link: homeSlides[h].link,
				goTo: function(link){ var win = window.open(link, '_blank'); win.focus(); }
                        });

		}
	}
	AtlasApp.controller( 'CarouselController',[ '$scope', CarouselController ] );

    function PageController( $scope, FormEntryService ){

		$scope.FormEntryService = FormEntryService;

		$scope.submit = function(){
			$scope.$broadcast('validate-form',{ callback:function( formControl ){ if( formControl.$valid ){ $scope.FormEntryService.send(); } }, name: 'contact' });
		}

    }
	AtlasApp.controller( 'PageController',[ '$scope', 'FormEntryService', PageController ] );

	function AuthController( $scope, $auth, $state, $http, $stateParams, $timeout, ProfileService, localStorageService, FormEntryService ) {

        var vm = this;

		var vehicle_id = $state.params.hasOwnProperty( "vehicle_id" ) ? $state.params.vehicle_id : false ;

		vm.failed = false;

		vm.FormEntryService = FormEntryService;

		vm.response = {};

		var respond = function( msg,status,timer,forward ){

			vm.response.status = !!status;
			vm.response.message = msg;
			vm.response.show = true;

			$timeout(function(){ vm.response.show = false; if( forward ){ $state.go( forward ); } }, ( timer || 3000 ) );

			return status;

		}

		vm.submit = function(){

			if( typeof vm.FormEntryService.form.name !== 'string' ){
				return respond( "There was an error trying to submit the form.  Please check for errors and try again.",false );
			}

			var form_name = vm.FormEntryService.form.name;

			if( !vm.hasOwnProperty( form_name ) ){
				return respond( "There was an error trying to submit the form.  Please check for errors and try again.",false );
			}

			$scope.$broadcast('validate-form',{ 
				callback:function( formControl ){

					if( formControl.$valid ){

						vm.FormEntryService.send(function(payload){
							return respond( payload.message[0],String(payload.code).indexOf( "2" ) === 0,( $stateParams.hasOwnProperty( 'token' ) ? 4000 : 0 ),( $stateParams.hasOwnProperty( 'token' ) ? "auth" : false ) );
						});
						return;

					}

					return respond( "There was an error trying to submit the form.  Please check for errors and try again.",false );

				}, 
				name: "auth." + form_name
			});
			
		}

        vm.login = function( callback ) {

            var credentials = {
                email: vm.email,
                password: vm.password
            }
            
            // Use Satellizer's $auth service to login
            $auth.login(credentials).then(function(payload) {

				// fetch user profile & store.
				ProfileService.get()
					.load( payload.data.profile )
					.then( function(){
						var Profile = ProfileService.get();
						Profile.attribs.id = Profile.id();
						localStorageService.set( 'Profile',Profile.attribs );
						if( typeof callback === 'function' ){ callback(); }
					});

                // If login is successful, redirect to the users state
                switch( payload.data.profile.role ){
	                case "admin": case "staff":
	                	$state.go('dashboard', {});
	                	break;
	                default:
	                	$state.go('wishlist.list_all', {});
	                	break;
                }
				

            },function(){
	            
	            // If not successful, notify the user.
	            vm.failed = true;

            });
        }

		vm.loginNewUser = function(){
			vm.email = vm.FormEntryService.model.email;
			vm.password = vm.FormEntryService.model.password;
			vm.login(function(){

				if( vehicle_id === false ){ return; }
				if( isNaN( vehicle_id ) ){ return; }

				// create wishlist item for new user,
				// IF they decided to register by way 
				// of 'Add to My Wishlist' on listed
				// vehicles.
				$http.post( "/api/wishlists",{ vehicle_id: vehicle_id } );

			});
		}

		if( $state.current.name === 'reset' ){
			vm.FormEntryService.model.token = $stateParams.token;
		}

    }
	AtlasApp.controller( 'AuthController',[ '$scope', '$auth', '$state', '$http', '$stateParams', '$timeout', 'ProfileService', 'localStorageService', 'FormEntryService', AuthController ] );

    function MediaController( $scope ){

		// active video id storage.
	    $scope.vid = null;

		// configuration for embded youtube video player
		$scope.playerVars = { controls: 0, autoplay: 1, rel: 0 }

    }
	AtlasApp.controller( 'MediaController',[ '$scope', MediaController ] );

	function InventoryController( $scope, $state, $stateParams, VehicleFactory, VehicleService, QueryRequest, Formatter ){

		// Boolean, set to true after vehicle(s) have been loaded.
		$scope.loaded = false;

		// Stores active Vehicle Model.
		$scope.Vehicle = VehicleFactory.create();

		// Stores array of Vehicle Models.
		$scope.Vehicles = [];

		// Make the Formatter Service available to the scope
		$scope.Formatter = Formatter;

		// Load Up Controller.
		var init = function(){

			// reset boolean loading flag.
			$scope.loaded = false;

			// reset vehicle model whenever controller
			// is instantiated.
			$scope.Vehicle = VehicleFactory.create();

			// Stores the type of Vehicle to seek out.
			$scope.type = $stateParams.hasOwnProperty( 'type' ) ? $stateParams.type : false ;

			// Stores the Vehicle ID to the specific Vehicle Model to be loaded.
			var vehicle_id = $stateParams.hasOwnProperty( 'vehicle_id' ) ? ( !isNaN( $stateParams.vehicle_id ) ? $stateParams.vehicle_id : false ) : false ;

			// Fetch Detail Vehicle Model.
			if( vehicle_id ){
	
				// get specific vehicle
				$scope.Vehicle.load( vehicle_id )
					.then(function(){
						$scope.loaded = true;
					},function(msg){});
	
				return;
			}

			// Perform & Load Vehicle Search.
			if( $stateParams.hasOwnProperty( 'search' ) ){

				// set vehicle search params.
				var search = { status: $state.current.name.split('.')[0], type: "", make: "", model: "" }
				search.status = search.status === 'inventory' ? 'for_sale' : search.status;

				// prep search terms
				for( var s in search ){
					if( $stateParams.hasOwnProperty( s ) ){
						search[ s ] = $stateParams[ s ];
						if( !search[ s ].length ){ delete search[ s ]; }
					}
				}

				VehicleService
					.search( search )
					.then(
						function(){	$scope.loaded = true; $scope.Vehicles = VehicleService.all(); 	},
						function(){																	}
					);

				return;

			}

			// Load Vehicle Models by `Type`
			if( $scope.type ){

				var status = $state.current.name.split('.')[0];
				status = status === 'inventory' ? 'for_sale' : status;

				// get vehicles by type.
				VehicleService
					.search( { status: status, type: $scope.type, active: '1' } )
					.then(
						function(){	$scope.loaded = true; $scope.Vehicles = VehicleService.all();	},
						function(){																	}
					);

			}

		}

		init();

	}
	AtlasApp.controller( 'InventoryController',[ '$scope', '$state', '$stateParams', 'VehicleFactory', 'VehicleService', 'QueryRequest', 'Formatter', InventoryController ] );

	function ListMyVehicleController( $scope, $timeout, Upload, FormEntryService, VehicleFactory, Formatter ){

		var Vehicle;

		$scope.FormEntryService = FormEntryService;

		$scope.Formatter = Formatter;

		var upload_images = function(){

			// Upload Photos.
			Upload.upload({
				url: '/api/vehiclesimages?crop=bypass',
				method: 'POST',
				data: { images: $scope.files, vehicle_id: Vehicle.id() }
			}).then(function (payload) {
				$scope.response.complete = true;
				$scope.response.status = true;
				$scope.response.message = "Your vehicle listing has been sent to the Maxed Out team.  Someone will reach out to you very shortly.";
			}, function (payload) {
				$scope.response.complete = true;
				$scope.response.status = true;
				$scope.response.message = "Your vehicle listing has been sent to the Maxed Out team, but not all the photos were saved.  " + payload.message + "  Someone will reach out to you very shortly.";
			}, function (evt) {
				$scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
			});

		}

		$scope.submit = function(){

			// disable the submit button.
			$scope.submit_disabled = true;

			$scope.$broadcast('validate-form',{ 
				callback:function( formControl ){

					Vehicle = VehicleFactory.create();

					if( formControl.$valid ){

						// first, we create the vehicle model & set a default label and status.
						// we'll attach the user_id from the back-end.
						Vehicle.attribs.label = "List My Vehicle.";
						Vehicle.attribs.status = "list_my_vehicle";

						// now we'll add in the vehicle meta data.
						var meta = [ 'type','model','make','year' ];
						for( var m in meta ){
							Vehicle.addChild( 'meta',{ meta_key: meta[ m ],meta_value: $scope.FormEntryService.model[ meta[ m ] ] } );
						}

						Vehicle.save()
							.then(function(payload){

								$scope.FormEntryService.model.vehicle_id = Vehicle.id();
								$scope.FormEntryService.send(function(response){

									$scope.response = $scope.FormEntryService.response;

									// if no images exist, display
									// user notification now. (pass/fail)
									$scope.response.complete = !$scope.files.length;

								});

								// Do images exist?
								// Upload them now.
								if( $scope.files.length ){ upload_images(); }

							},function(payload){
								$scope.response.status = false;
								$scope.response.complete = true;
								$scope.response.message = payload.message;
							});

					}

				}, 
				name: 'listing' 
			});

		}

		var init = function(){
			$scope.FormEntryService.refresh();
			$scope.response = {};
			$scope.files = [];
		}

		init();

	}
	AtlasApp.controller( 'ListMyVehicleController',[ '$scope', '$timeout', 'Upload', 'FormEntryService', 'VehicleFactory', 'Formatter', ListMyVehicleController ] );

	function CreateListingsController( $scope, $q, $state, $stateParams, localStorageService, VehicleFactory, VehicleService, Formatter, listingsSearchFilter, VehicleOptionsService, VehicleMetaOptionsService, Upload, $timeout, UserService ){


		// Make the Vehicle Options & Vehicle Meta Options 
		// available to the scope.
		$scope.VehicleOptions = VehicleOptionsService;
		$scope.VehicleMetaOptions = VehicleMetaOptionsService;

		// Store single Vehicle Model
		// Used in all CRUD, not used in search
		$scope.Vehicle = VehicleFactory.create();

		// We are defining an object to store
		// values from the form.
		// NOT ALL FORM VALUES APPLY DIRECTLY TO THE VEHICLE MODEL
		// Some values belong to children objects, which we'll create
		// when attempt to save the form.
		$scope.vehicle_form = { meta: {} };

		// Make the formatter service available.
		$scope.Formatter = Formatter;

		// Store users for auto-complete
		$scope.Users = [];

		// Store boolean flag indicating if
		// Vehicle models have been loaded.
		$scope.loaded = false;

		// Set the Vehicle model's user_id when the 
		// auto complete option is selected.
		$scope.userSelected = function( user ){
			$scope.Vehicle.attribs.user_id = user.description.id();
			$scope.Vehicle.attribs.customer = user.description.attribs.first + ' ' + user.description.attribs.last;
			$scope.next();
		}

		// we stage the input fields for 
		// creating a new listing.
		$scope.step = 1;

		$scope.next = function(){
			$scope.step++;
		}

		$scope.prev = function(){
			$scope.step--;
		}

		$scope.used_metas = {
			type: '',
			year: '',
			make: '',
			model: '',
			price: '',
			description: ''
		}

		$scope.additional_metas = {}

		$scope.add = function(a){
			var f = false;
			for( var m in VehicleMetaOptionsService.options[ $scope.vehicle_form.meta.type ] ){
				if( f ){ return; }
				if( VehicleMetaOptionsService.options[ $scope.vehicle_form.meta.type ][m].type === a ){
					$scope.additional_metas[a] = VehicleMetaOptionsService.options[ $scope.vehicle_form.meta.type ][m].label;
					f = !f;
				}
			}
		}

		$scope.remove = function(a){
			if( $scope.additional_metas.hasOwnProperty( a ) ){
				delete $scope.additional_metas[ a ];
			}
		}

		$scope.userSearch = function(str) {
			var matches = [];
			$scope.Users.forEach(function(user) {
				var fullName = user.attribs.first + ' ' + user.attribs.last;
				if ((user.attribs.first.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.attribs.last.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(fullName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
						matches.push(user);
				}
			});
			return matches;
		};

		$scope.response = {};

		var respond = function( msg,status ){

			$scope.response.status = !!status;
			$scope.response.message = msg;
			$scope.response.show = true;

			$timeout(function(){ $scope.response.show = false; }, 3000 );

			return status;
		}

		var prepare = function(){
		
			// we don't know if the user has updated or changed the
			// vehicle model more than once.  we're going to create 
			// a new vehicle model and transfer attribs.
			// then, we'll re-add all the children meta objs.
			//
			// Note: Images are uploaded seperately.  The 
			// VehicleImage Objects are are designed to upload 
			// encoded form data.  
			$scope.Vehicle = VehicleFactory.create( $scope.Vehicle.attribs );
		
			// add Vehicle attribs.
			for( var v in $scope.vehicle_form ){
				$scope.Vehicle.attribs[v] = $scope.vehicle_form[v];
			}
		
			// add meta options.
			var promises = [];
			for( var m in $scope.vehicle_form.meta ){
				promises.push( $scope.Vehicle.addChild( 'meta',{ meta_key:m,meta_value:$scope.vehicle_form.meta[m] } ) );
			}

			var deferred = $q.defer();
		
			$q.all( promises ).then(function(){deferred.resolve();},function(){deferred.reject();});
		
			return deferred.promise;
		
		}

		var verify = function(){

			// set the minimum required meta_key fields
			var i = null,min_req = ['type','year','make','model','description','price'];
		
			// check for the existance of min meta fields
			for( var m in $scope.Vehicle.getChildren( 'meta' ) ){
				i = min_req.indexOf( $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key );
				if( i < 0 ){ continue; }
				min_req.splice(i,1);
			}
		
			// were any minimum required fields missing?
			if( min_req.length ){
				min_req = $.map(min_req, function(m) { return Formatter.ucwords(m); });
				var l = min_req.pop();
				return respond( "Please complete the following empty or invalid fields: " + ( min_req.join( ', ') + ' and ' + l ),false );
			}
		
			return true;
		
		}

		var upload_images = function(){
			// Upload Photos.
			Upload.upload({
				url: '/api/vehiclesimages',
				method: 'POST',
				data: { images: $scope.create_vehicle._images, dimensions: $scope.create_vehicle._dimensions, vehicle_id: $scope.Vehicle.id(), primary: $scope.create_vehicle._primary_image_index }
			}).then(function (payload) {
				$scope.uploading = false;
				return respond( "The new listing has been saved.",true );
			}, function (payload) {
				$scope.uploading = false;
				return respond( "The new listing has been saved, but there was an error savings the photos.",false );
			}, function (evt) {
				$scope.percentLoaded = Math.min(100, parseInt(100.0 * evt.loaded / evt.total)); 
			});

		}

		$scope.save = function(){

			var deferred = $q.defer();

			prepare().then(function(){ $timeout(function(){ if( verify() ){ deferred.resolve(); }else{ deferred.reject(); } }) });

			$scope.uploading = $scope.saving = true;

			deferred.promise.then(function(){

				$scope.Vehicle
					.save()
					.then(function(){
						
						if( typeof $scope.create_vehicle._images === 'undefined' || $scope.create_vehicle._images === null ){
							respond( "The vehicle has been saved.",true );
							$scope.uploading = false;
						}else if( $scope.create_vehicle._images.length < 1 ){
							respond( "The vehicle has been saved.",true );
							$scope.uploading = false;
						}else{
							upload_images();
						}
					},respond);

			})

		}

		$scope.refresh = function(){
			$scope.response = {};
			$scope.additional_metas = {}
			$scope.used_metas = {
				type: '',
				year: '',
				make: '',
				model: '',
				price: '',
				description: ''
			}
			$scope.step = 1;
			$scope.vehicle_form = { meta: {} };
			$scope.Vehicle = VehicleFactory.create();
			$scope.saving = false;
		}

		switch( $state.current.name ){
			case "wishlist.create": 
				$scope.Vehicle.attribs.user_id = localStorageService.get('Profile').id;
				$scope.Vehicle.attribs.customer = Formatter.ucwords( localStorageService.get('Profile').first + ' ' + localStorageService.get('Profile').last );
				$scope.loaded = true;
			break;
			default: UserService.search().then(function(){ $scope.Users = UserService.all(); $scope.loaded = true; }); break;
		}


	}
	AtlasApp.controller( 'CreateListingsController',[ '$scope', '$q', '$state', '$stateParams', 'localStorageService', 'VehicleFactory', 'VehicleService', 'Formatter', 'listingsSearchFilter', 'VehicleOptionsService', 'VehicleMetaOptionsService', 'Upload', '$timeout', 'UserService', CreateListingsController ] );

	function ModifyListingsController( $sce, $scope, $http, $state, $stateParams, VehicleFactory, VehicleService, Formatter, VehicleOptionsService, VehicleMetaOptionsService, Upload, $timeout, UserService ){

		// Stores the Vehicle ID to the specific Vehicle Model to be loaded.
		var vehicle_id = $stateParams.hasOwnProperty( 'vehicle_id' ) ? ( !isNaN( $stateParams.vehicle_id ) ? $stateParams.vehicle_id : false ) : false ;

		// Store users for auto-complete
		$scope.User = null;
		$scope.Users = [];

		// Store vehicle to update.
		$scope.Vehicle = null;

		// make Formatter available to scope.
		$scope.VehicleOptionsService = VehicleOptionsService;
		$scope.VehicleMetaOptionsService = VehicleMetaOptionsService;
		$scope.Formatter = Formatter;

		// set default stage.
		// this tracks the accordion.
		$scope.stage = 1;

		//is user uploading new images
		$scope.uploadingNew = false;

		//show loader
		$scope.uploading = false;

		// store key:value pairs of 
		// used vehicle meta features.
		$scope.used_metas = {};

		// store meta values being 
		// modified in the UI
		// for both the vehicle being 
		// modified & the photos.
		$scope.modify = {};
		$scope.photo = {};
		$scope.dform = {};

		// track the used vehicle meta keys & values.
		var update_used_metas = function(){
			if( $scope.Vehicle ){
				if( $scope.Vehicle.getChildren( 'meta' ) !== false ){
					for( var m in $scope.Vehicle.getChildren( 'meta' ) ){
						$scope.used_metas[ $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key ] = $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_value;
					}
				}
			}		
		}

		var update_vehicle_metas = function(){
			if( $scope.Vehicle ){
				for( var m in $scope.Vehicle.getChildren( 'meta' ) ){
					if( $scope.used_metas.hasOwnProperty( $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key ) ){
						$scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_value = $scope.used_metas[ $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key ];
					}
				}
			}		
		}

		// Set the Vehicle model's user_id when the 
		// auto complete option is selected.
		$scope.userSelected = function( user ){
			if( !user || user === null ){ return; }
			var u = user.hasOwnProperty( 'description' ) ? user.description : user.originalObject;
			$scope.Vehicle.attribs.user_id = u.id();
			$scope.Vehicle.attribs.customer = u.attribs.first + ' ' + u.attribs.last;
		}

		// Set up list of user's fullnames.
		// This data will be used for the auto-complete.
		$scope.userSearch = function(str) {
			var matches = [];
			$scope.Users.forEach(function(user) {
				var fullName = user.attribs.first + ' ' + user.attribs.last;
				if ((user.attribs.first.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.attribs.last.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(fullName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
						matches.push(user);
				}
			});
			return matches;
		};

		// Respond to user's request w/ notification.
		var respond = function( msg,status ){

			if( typeof $scope.response !== 'object' ){
				$scope.response = {};
			}

			$scope.response.status = !!status;
			$scope.response.message = msg;
			$scope.response.show = true;

			$timeout(function(){ $scope.response.show = false; }, 3000 );

			return status;
		}

		// open next accordion box
		$scope.next = function(){ $scope.stage++; if( parseInt($scope.stage) === 3 ){ update_vehicle_metas(); } }

		// open previous accordion box
		$scope.prev = function(){ $scope.stage--; if( $scope.stage < 1 ){ $scope.stage = 1; } }

		// Set up display / forms / models for editing.
		$scope.edit = function( action, payload ){

			switch( action ){

				case "meta":
					$scope.modify = { edit_meta_key: payload };
					return;

				case "photo":
					$scope.photo.edit_photo_id = payload;
					break;

				case "photos":
					$scope.stage = 5;
					break;

				case "photo.primary":
					for( var i in $scope.Vehicle.getChildren('image') ){
						$scope.Vehicle.getChildren('image')[i].attribs.primary = ( parseInt( $scope.Vehicle.getChildren('image')[i].id()  ) === parseInt( payload ) ? 1 : 0  );
						$scope.Vehicle.getChildren('image')[i].save();
					}
					break;

				case "vehicle":
					$scope.stage = 1;
					break;

				case "wishlist":
					$scope.edit_listing = true;
					return;

				case "upload":
					$scope.uploadingNew = true;
					return;

				// show edit form accordian when
				// edit button is clicked & no action is supplied.
				default:

					// if User accounts have not been loaded,
					// fetch them now.
					if( !$scope.Users.length ){
						UserService.search().then(function(){
							$scope.Users = UserService.all();
							$scope.edit();
						})
						return;
					}

					// find the User model.
					for( var u in $scope.Users ){
						if( $scope.Vehicle.attribs.user_id === null ){ continue; }
						if( parseInt( $scope.Vehicle.attribs.user_id ) !== parseInt( $scope.Users[u].id() ) ){ continue; }
						$scope.userInitialValue = $scope.Users[u];
					}

					$scope.edit_listing = true;

					return;

			}

		}

		$scope.add = function( action, payload ){
			switch( action ){
				case "meta":
					$scope.modify.action = 'add';
					$scope.modify.meta_key = payload;
					$scope.modify.show = true;
					break;
				case "edit.buttons":
					$scope.modify.button = payload;
					break;
				case "photo.buttons":
					$scope.photo.view_photo_id = payload;
					break;
			}
		}

		$scope.cancel = function( action ){
			switch( action ){
				case "meta":
					$scope.modify = {};
					break;
				case "edit.buttons":
					$scope.modify.button = null;
					break;
				case "vehicle":
					$scope.edit_listing = false;
					$scope.modify = {};
					$scope.stage = 1;
					break;
				case "photo.buttons":
					$scope.photo = {};
					break;
				case "crop":
					$scope.photo.new_dimensions = null;
					$scope.photo.new_image = null;
					$scope.photo.preview_new_crop = false;
					break;
				case "upload":
					$scope.uploadingNew = false;
					break;
			}
		}

		$scope.store = function( action, payload ){
			switch( action ){
				case "meta":

					// is it a new meta to add, or was it an existing meta value?
					if( $scope.modify.new_value ){

						// as a precautionary measure to prevent any duplicate
						// vehicle features, check which meta_keys exists
						// directly on the data model.
						var f = false;
						for( var m in $scope.Vehicle.getChildren( 'meta' ) ){
							if( $scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key === $scope.modify.meta_key ){
								$scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_key = $scope.modify.meta_key;
								$scope.Vehicle.getChildren( 'meta' )[m].attribs.meta_value = $scope.modify.new_value;
								f = true;
							}
						}

						// meta_key did not exist on original data model, add it.
						if( !f ){
							$scope.Vehicle.addChild( 'meta',{ meta_key: $scope.modify.meta_key, meta_value: $scope.modify.new_value } );
						}

					}

					if( !isNaN( payload ) ){
						if( $scope.Vehicle.getChild( 'meta',payload ) ){
							$scope.Vehicle.getChild( 'meta',payload ).attribs.meta_value = $scope.used_metas[$scope.modify.edit_meta_key];
						}
					}

					break;

				case "crop":

					// store new dimensions
					$http.put( "/api/vehiclesimages/" + $scope.photo.edit_photo_id,{ dimensions: $scope.photo.new_dimensions } );

					// reset photo object for more updates.
					$scope.photo = {};

					break;

				case "vehicle":
					$scope.saving = true;
					$scope.Vehicle.save().then(function(){
						$scope.saving = false;
						return respond( "Vehicle listing updated.",true );
					},function(){
						$scope.saving = false;
						return respond( "Vehicle listing could not be updated.",false );
					});
					return;

			}

			// we may have had an async call above [addChild()], wait for next digest cycle.
			$timeout(function(){ update_used_metas();$scope.cancel( action ); });

		}

		$scope.remove = function( action ){
			switch( action ){
				case "vehicle":
					$scope.Vehicle.remove();
					$scope.Vehicle.save();
					break;
			}
		}

		$scope.restore = function( action ){
			switch( action ){
				case "vehicle":
					$scope.Vehicle.keep();
					$scope.Vehicle.save();
					break;
			}
		}

		var upload_images = function(){
			// Upload Photos.
			Upload.upload({
				url: '/api/vehiclesimages',
				method: 'POST',
				data: { images: $scope.dform.edit_photos._images, dimensions: $scope.dform.edit_photos._dimensions, vehicle_id: $scope.Vehicle.id() }
			}).then(function (payload) {
				$scope.uploading = false;
				//return respond( "The new listing has been saved.",true );
			}, function (payload) {
				$scope.uploading = false;
				//return respond( "The new listing has been saved, but there was an error savings the photos.",false );
			}, function (evt) {
				$scope.percentLoaded = Math.min(100, parseInt(100.0 * evt.loaded / evt.total)); 
			});

		}

		$scope.finishUploading = function(){
			$scope.uploadingNew = false;
			$scope.uploading = true;
			if( $scope.dform.edit_photos._images.length ){ upload_images(); }
		}

		$scope.back = function(){ $scope.photo = {}; }

		var watcher = $scope.$watch(function(scope){ return $scope.Vehicle; },update_used_metas,true);
		$scope.$on( "$destroy",watcher );

		// Init Controller ------------------------------------------
		var init = function(){

			// if this is a user viewing their wishlist item,
			// we skip the first stage (where you would normally
			// setup a customer account)
			if( $state.current.name === 'wishlist.detail' ){ $scope.stage++; }
			if( typeof $state.current.name !== 'string' ){ return; }
			if( vehicle_id < 1 ){ return; }

			$scope.Vehicle = VehicleFactory.create();
			$scope.Vehicle.load(vehicle_id).then(function(){
				$scope.User = UserService.get();
				$scope.User.load( $scope.Vehicle.attribs.user_id );
			});

		}

		init();

	}
	AtlasApp.controller( 'ModifyListingsController',[ '$sce', '$scope', '$http', '$state', '$stateParams', 'VehicleFactory', 'VehicleService', 'Formatter', 'VehicleOptionsService', 'VehicleMetaOptionsService', 'Upload', '$timeout', 'UserService', ModifyListingsController ] );

	function ListingsController( $scope, $q, $state, $stateParams, VehicleFactory, VehicleService, Formatter, listingsSearchFilter, VehicleOptionsService, VehicleMetaOptionsService, Upload, $timeout, UserService, localStorageService ){

		// Stores the Vehicle ID to the specific Vehicle Model to be loaded.
		var vehicle_id = $stateParams.hasOwnProperty( 'vehicle_id' ) ? ( !isNaN( $stateParams.vehicle_id ) ? $stateParams.vehicle_id : false ) : false ;

		$scope.forms = {};

		$scope.feature_error = null;

		$scope.Formatter = Formatter;

		// Store Vehicle Model search results here.
		// Unfiltered & Filtered result sets.
		$scope.Vehicles = [];
		$scope.FilteredVehicles = [];

		// store active search filters.
		// updated in listingsSearchForm directive.
		$scope.filters = {};

		// Store boolean flag indicating if
		// Vehicle models have been loaded.
		$scope.loaded = false;



		// Private Functions ----------------------------------------

		// runs Vehicle search results through
		// filter, clearing out vehicle models
		// that dont meet user selected criteria.
		var filter_vehicles = function(){
			$scope.FilteredVehicles = [];
			for( var v in $scope.Vehicles ){
				$scope.FilteredVehicles.push( listingsSearchFilter( $scope.Vehicles[v],$scope.filters ) );
			}
		}


		// Init Controller ------------------------------------------
		var init = function(){

			if( typeof $state.current.name !== 'string' ){ return; }

			// List All Listings
			// Show Listings Search Form.
			if( $state.current.name === 'listings.list_all' )
			{
				VehicleService
					.search()
					.then(function(){

						$scope.Vehicles = VehicleService.all();
						
						// Watch the filters object.
						// Whenever user updates the filter object, 
						// run the filter_vehicles (listingsSearchFilter) function.
						var watcher = $scope.$watch(function(scope){ return scope.filters; },function( nv,ov ){ if( nv === ov ){ return; } filter_vehicles(); },true );
						$scope.$on( "$destroy",watcher );

						filter_vehicles();

						$scope.loaded = false;

					});

				return;
			}

			// Load a specific listing
			if( $state.current.name === 'listings.detail' && vehicle_id > 0 )
			{

				//$scope.Vehicle = VehicleFactory.create(vehicle_id);
				$scope.Vehicle = VehicleFactory.create();
				$scope.Vehicle.load(vehicle_id)
								.then(function(){ $scope.loaded = false; })

				return;

			}

			// List All My Wishlist.
			if( $state.current.name === 'wishlist.list_all' )
			{

				VehicleService
					.search({status: 'wishlist',user_id: localStorageService.get('Profile').id })
						.then(
							function(){ 
								$scope.Vehicles = VehicleService.all(); 
								$scope.loaded = true; 
							}
						);

				return;
			}


		}

		init();

	}
	AtlasApp.controller( 'ListingsController',[ '$scope', '$q', '$state', '$stateParams', 'VehicleFactory', 'VehicleService', 'Formatter', 'listingsSearchFilter', 'VehicleOptionsService', 'VehicleMetaOptionsService', 'Upload', '$timeout', 'UserService', 'localStorageService', ListingsController ] );

	function EventsController( $scope, $state, $timeout, localStorageService, UserService, EventService, CalendarService ){

		$scope.Event = EventService.get();

		$scope.CalendarService = CalendarService;

		// Store users for auto-complete
		$scope.Users = [];

		// remember which pop-up displays are open.
	    $scope.open = {
	        date: false,
	        time: false,
	    };

		// open the selected view, date or time.
	    $scope.openCalendar = function(e, view) {
	        $scope.open[view] = true;
	    };

		// Set the Vehicle model's user_id when the 
		// auto complete option is selected.
		$scope.userSelected = function( user ){
			$scope.Event.attribs.user_id = user.description.id();
		}

		$scope.userSearch = function(str) {
			var matches = [];
			$scope.Users.forEach(function(user) {
				var fullName = user.attribs.first + ' ' + user.attribs.last;
				if ((user.attribs.first.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.attribs.last.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(fullName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
						matches.push(user);
				}
			});
			return matches;
		};

		var respond = function( msg,status ){

			if( typeof $scope.response !== 'object' ){
				$scope.response = {};
			}

			$scope.response.status = !!status;
			$scope.response.message = msg;
			$scope.response.show = true;

			$timeout(function(){ $scope.response.show = false; }, 3000 );

			return status;
		}

		$scope.save = function(){

			var evt = $scope.CalendarService.Event;
			var is_new = true;

			if( evt === null ){
				is_new = true;
			}else if( typeof evt === 'object' ){
				is_new = evt.hasOwnProperty( 'isNew' ) ? ( parseInt(evt.isNew()) === 1 ? true : false ) : false ;
			}

			if( is_new ){
				if( $scope.create_event.$invalid ){
					$scope.$broadcast('validate-form',{ name: 'create_event' } );
					return respond( 'Please check the form for errors before attempting to save.',false );
				}else{

					if( isNaN( $scope.Event.attribs.user_id ) || $scope.Event.attribs.user_id === null ){
						$scope.Event.attribs.user_id = localStorageService.get('Profile').id;
					}

					$scope.Event
						.save()
						.then(function(){
							$scope.create_event.$setUntouched();
							$scope.Event = null;
							respond( "The new event record has been saved.",true );
						},function(msg){
							respond( msg.join(" "),false );
						});

					return;

				}
			}

		}

		var init = function(){

			$scope.Event = EventService.get();

			// get all users for user auto complete.
			UserService.search().then(function(){ $scope.Users = UserService.all(); });

		}

		var watcher = $scope.$watch( function( scope ){ return $state.current.name; },init );
		$scope.$on( "$destroy",watcher );

	}
	AtlasApp.controller( 'EventsController',[ '$scope', '$state', '$timeout', 'localStorageService', 'UserService', 'EventService', 'CalendarService', EventsController ] );

	function WishlistsController( $scope, $state, $stateParams, VehicleService, UserService, Formatter, localStorageService ){

		$scope.Formatter = Formatter;

		// Store Vehicle Model search results here.
		// Unfiltered & Filtered result sets.
		$scope.Vehicle = null;
		$scope.User = null;
		$scope.Vehicles = [];
		$scope.FilteredVehicles = [];

		// Store boolean flag indicating if
		// Vehicle models have been loaded.
		$scope.loaded = false;

		// edit the active wishlist item.
		// change state to update listing.
		$scope.edit = function(){
			$state.go( 'listings.detail',{ vehicle_id: $state.params.vehicle_id } );
		}

		// Init Controller ------------------------------------------
		var init = function(){

			if( typeof $state.current.name !== 'string' ){ return; }

			$scope.Vehicle = null;
			$scope.Vehicles = [];
			$scope.FilteredVehicles = [];

			// View 'My' Wishlist Listings..detail
			if( $state.current.name === 'wishlist' )
			{

				$scope.title = "MY WISHLIST";

				VehicleService
					.search({status:'wishlist',user_id:localStorageService.get('Profile').id})
					.then(function(){
						$scope.Vehicles = VehicleService.all();
						$scope.loaded = true;
					});

				return;
			}

			// View all Wishlist Listings.
			if( $state.current.name === 'wishlists' )
			{

				$scope.title = "WISHLISTS";

				VehicleService
					.search({status:'wishlist'})
					.then(function(){
						$scope.Vehicles = VehicleService.all();
						$scope.loaded = true;
					});

				return;
			}


			// View an existing Wishlist Listing.
			if( $state.current.name === 'wishlists.detail' || $state.current.name === 'wishlist.detail' )
			{

				$scope.title = "WISHLIST";

				var vehicle_id = $state.params.vehicle_id || false ;

				if( isNaN( vehicle_id ) ){
					vehicle_id = false;
				}

				if( !vehicle_id ){ return; }

				$scope.Vehicle = VehicleService.get();

				$scope.Vehicle.load( vehicle_id ).then(function(){
					$scope.User = UserService.get();
					$scope.User.load( $scope.Vehicle.attribs.user_id )
								.then(function(){ $scope.loaded = true; });
				});

				return;
			}

		}

		var watcher = $scope.$watch(function(scope){return $state.current.name;},init);
		$scope.$on("$destroy",watcher);

	}
	AtlasApp.controller( 'WishlistsController',[ '$scope', '$state', '$stateParams', 'VehicleService', 'UserService', 'Formatter', 'localStorageService', WishlistsController ] );

	function SearchController( $scope, $state, $timeout, SearchService ){

		$scope.results = SearchService.results;

	}
	AtlasApp.controller( 'SearchController',[ '$scope', '$state', '$timeout', 'SearchService', SearchController ] );

	function DashboardController( $scope, $state, $http, localStorageService, ProfileFactory ){

		$scope.Profile = ProfileFactory.create( localStorageService.get('Profile') );

		$scope.stats = {};

		$http.get( "/api/dashboard" )
			.then(function(payload){

				for( var d in payload.data.data ){

					switch( d ){
						case "my_wishlist":case "vehicles":
							
							for( var v in payload.data.data[ d ] ){
								
								switch( payload.data.data[ d ][ v ].type ){
									case "onroad":		payload.data.data[ d ][ v ].type = "On-Road";				break;
									case "offroad":		payload.data.data[ d ][ v ].type = "Off-Road";				break;
									case "performance":	payload.data.data[ d ][ v ].type = "Performance";			break;
									case "recreation":	payload.data.data[ d ][ v ].type = "Recreation & Leisure";	break;
								}

								switch( payload.data.data[ d ][ v ].status ){
									case "for_sale":	payload.data.data[ d ][ v ].status = "For Sale";			break;
									case "sold":		payload.data.data[ d ][ v ].status = "Sold";				break;
									case "draft":		payload.data.data[ d ][ v ].status = "Draft";				break;
									case "wishlist":	payload.data.data[ d ][ v ].status = "Wishist";				break;
									case "list_my_vehicle":	payload.data.data[ d ][ v ].status = "List My Vehicle";	break;
								}

							}
							
							break;
						case "users":
							
							for( var u in payload.data.data[ d ] ){

								switch( payload.data.data[ d ][ u ].role ){
									case "admin":		payload.data.data[ d ][ u ].role = "Administrator";			break;
									case "staff":		payload.data.data[ d ][ u ].role = "Staff";					break;
									case "user":		payload.data.data[ d ][ u ].role = "Customers";				break;
								}
							}

							break;
					}

				}

				$scope.stats = payload.data.data;

			});

	}
	AtlasApp.controller( 'DashboardController',[ '$scope', '$state', '$http', 'localStorageService', 'ProfileFactory', DashboardController ] );

	function UsersController( $scope, $state, UserService, localStorageService, ProfileFactory, FormEntryService, Formatter, $timeout ){

		// Make profile available so we can change
		// options based on user role.
		$scope.Profile = ProfileFactory.create( localStorageService.get('Profile') );

		// make FormEntryService available to the scope.
		// allows us to easy apply ng-pattern rules to inputs.
		$scope.FormEntryService = FormEntryService;

		// make Formatter service available to the scope.
		// (formats phone number for display)
		$scope.Formatter = Formatter;

		// store active user model for editing
		$scope.User = null;

		// store all user models for datatable
		$scope.Users = [];

		// store original versions of form models
		// to revert changes when needed.
		var shadow = {}

		// display results of User model save.
		var respond = function( msg,status ){

			if( typeof $scope.response !== 'object' ){
				$scope.response = {};
			}

			$scope.response.status = !!status;
			$scope.response.message = msg;
			$scope.response.show = true;

			$timeout(function(){ $scope.response.show = false; }, 3000 );

			return status;
		}

		// edit selected user
		// sets `User` model & displays form.
		$scope.edit = function( index ){
			if( $scope.User !== null && typeof $scope.User !== 'undefined' ){ return; }
			$scope.User = $scope.Users[ index ];
			shadow = JSON.parse(JSON.stringify( $scope.User.attribs ));
		}

		// cancels user editing.
		// reverts changes.
		$scope.cancel = function( index ){
			for( var s in shadow ){
				$scope.User.attribs[ s ] = shadow[ s ];
			}
			$scope.User = null;
		}

		// save updates to user model.
		$scope.save = function(){

			var is_new = $scope.User.isNew();
			var form_name = is_new ? 'create_user' : 'edit_user' ;

			// does the form model exist?
			if( !$scope.hasOwnProperty( form_name ) ){ 
				return respond( 'There was an error attempting to save the user account',false );
			}else if( !$scope[ form_name ].hasOwnProperty( '$invalid' ) ){
				return respond( 'There was an error attempting to save the user account',false );
			}

			// is the form model valid?
			$scope.$broadcast('validate-form',{ 

				callback:function( $formControl ){ 

					if( $formControl.$valid ){ 

						$scope.saving = true;

						$scope.User.save().then(
							function(){
								respond( "The user account has been successfully saved.",true );
								$scope.saving = false;
								$scope.User = null;
								$formControl.$setUntouched();
							},
							function(){ 
								respond( "The user account could not be saved.",false );
								$scope.saving = false;
								$scope.cancel() 
							});
	
					}else{

						return respond( 'Please check the form for errors before attempting to save.',false );

					}

				 }, 

				 name: form_name 

			});

		}

		if( $state.current.name.indexOf( 'create' ) > -1 ){
			$scope.User = UserService.get();
		}

		// load all user models.
		UserService.search().then(function(){ $scope.Users = UserService.all(); });

	}
	AtlasApp.controller( 'UsersController',[ '$scope', '$state', 'UserService', 'localStorageService', 'ProfileFactory', 'FormEntryService', 'Formatter', '$timeout', UsersController ] );

	function NewsletterController( $scope, $timeout, SubscriberService,Formatter ){

		$scope.Subscribers = [];

		$scope.Subscriber = null;

		$scope.Formatter = Formatter;

		// store response status & message 
		// for HTTP calls (Conducted through injected Service)
		$scope.response = { show: false, status: false, message: "" };

		var shadow = {};

		// display results of Subscriber model save.
		var respond = function( msg,status ){

			$scope.response.status = status;
			$scope.response.message = msg;
			$scope.response.show = true;

			$timeout(function(){ $scope.response.show = false; }, 3000 );

			return status;
		}

		// edit selected subscriber
		// sets `Subscriber` model & displays form.
		$scope.edit = function( index ){
			if( $scope.Subscriber !== null && typeof $scope.Subscriber !== 'undefined' ){ return; }
			$scope.Subscriber = $scope.Subscribers[ index ];
			shadow = JSON.parse(JSON.stringify( $scope.Subscriber.attribs ));
		}

		// cancels Subscriber editing.
		// reverts changes.
		$scope.cancel = function( index ){
			for( var s in shadow ){
				$scope.Subscriber.attribs[ s ] = shadow[ s ];
			}
			$scope.Subscriber = null;
		}

		// save updates to Subscriber model.
		$scope.save = function( index ){

			// make sure form model is valid.
			if( $scope.edit_subscriber.$invalid ){
				return respond( "Please complete all required form fields.",false );
			}

			// start `saving` spinner image
			$scope.saving = true;

			// save model.
			$scope.Subscriber.save().then(
				function( payload ){

					$scope.Subscriber.attribs.updated_at = new Date();
					$scope.Subscriber = null;

					// shut up `saving spinner`!
					$scope.saving = false;

					// respond to user.
					respond( "Newsletter updates saved.",true );

				},
				function(msg){ 

					// shut up `saving spinner`!
					$scope.saving = false;

					// close form, revert changes.
					$scope.cancel();

					// respond to user.
					respond( msg,false );

				});

		}

		// Fetch all subscriber records.
		SubscriberService.search().then(function(){
			$scope.Subscribers = SubscriberService.all();
			$scope.update_list();
		})

		// updates display of mailing list for export
		$scope.update_list = function(){

			$scope.mailing_list = [];

			if( !$scope.hasOwnProperty( 'deliminator' ) ){
				$scope.deliminator = ";";
			}

			for( var s in $scope.Subscribers ){
				switch( $scope.filter ){
					case "user":
						if( $scope.Subscribers[s].attribs.user_id !== null && !isNaN( $scope.Subscribers[s].attribs.user_id ) ){
							$scope.mailing_list.push( $scope.Subscribers[s].attribs.email );
						}
						break;
					case "non":
						if( $scope.Subscribers[s].attribs.user_id === null || isNaN( $scope.Subscribers[s].attribs.user_id ) ){
							$scope.mailing_list.push( $scope.Subscribers[s].attribs.email );
						}
						break;
					default:
						$scope.mailing_list.push( $scope.Subscribers[s].attribs.email );
						break;
				}
			}

			$scope.mailing_list = $scope.mailing_list.join($scope.deliminator + ' ');

		}


	}
	AtlasApp.controller( 'NewsletterController',[ '$scope', '$timeout', 'SubscriberService', 'Formatter', NewsletterController ] );


})();

(function() {

    'use strict';

    var AtlasApp = angular.module('AtlasApp');


	/* ==================================================================================== */
	// -   FILTERS   ----------------------------------------------------------------------
	/* ==================================================================================== */

	function listingsSearch(){
		return function( vehicle, filters ) {

			var children,c;

			// Filter listing vehicle search results.
			for( var f in filters )
			{


				// if filter is empty, or null, skip iteration.
				if( filters[ f ] === null ){ continue; }
				if( !filters[ f ].length && typeof filters[ f ] === 'object' ){ continue; }
				if( !String( filters[ f ] ).length && typeof filters[ f ] === 'string' ){ continue; }

				// different filter fields are checked differently.
				// ie. some filters are present in the VehicleMeta model, 
				// which is a child obj to the Vehicle model.
				// we'll use a switch here to manage.
				switch( f.toLowerCase() )
				{

					/* Vehicle Attributes ------------------------------------ */

					// vehicle ID is an integer returned by function.
					case "id":
						if( vehicle.id() !== filters[ f ] ){ return null; }
						break;
					// active is an integer returned by function.
					case "active":
						if( 
								( parseInt( filters[ f ] ) === 1 && parseInt( vehicle.isActive() ) === 0 ) || 
								( parseInt( filters[ f ] ) === 0 && parseInt( vehicle.isActive() ) === 1 )
							){ return null; }
						break;

					// status is a vehicle attribute.
					case "status":
						if( filters[ f ].indexOf( vehicle.attribs.status ) < 0 ){ return null; }
						break;



					/* Vehicle Meta Attributes ------------------------------- */
					// if it's type we're searching, type is an array.
					// other Meta Attribs are either string or numbers.
					case "type":

						children = vehicle.getChildren( 'meta' );

						// if vehicle meta children are missing,
						// this vehicle will not pass filtering.
						if( !children ){ return null; }

						for( c in children ){

							// if meta_key value doesnt match the filter key, skip iteration.
							if( children[ c ].attribs.meta_key !== f.toLowerCase() ){ continue; }

							// if the vehicle type isn't in the `type` array, do not return vehicle.
							if( filters[ f ].indexOf( children[ c ].attribs.meta_value ) < 0 ){ return null; }

						}

						break;

					case "year":
					case "make":
					case "model":

						children = vehicle.getChildren( 'meta' );

						// if vehicle meta children are missing,
						// this vehicle will not pass filtering.
						if( !children ){ return null; }

						for( c in children ){

							// if meta_key value doesnt match the filter key, skip iteration.
							if( children[ c ].attribs.meta_key !== f.toLowerCase() ){ continue; }

							// are we filtering a number?
							if( !isNaN( filters[ f ] ) ){
								if( filters[ f ] !== children[ c ].attribs.meta_value ){ return null; }
							}

							// check if filter string exists in meta value.
							if( isNaN( filters[ f ] ) ){
								//console.log( filters[ f ] );
								if( children[ c ].attribs.meta_value.toLowerCase().indexOf( filters[ f ].toLowerCase() ) < 0 ){ return null; }
							}

						}

						break;
				}
			}

			return vehicle;
		}
	}
	AtlasApp.filter( 'listingsSearch',[ listingsSearch ] );


})();